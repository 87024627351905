import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-submitted-questionnaire',
  templateUrl: './submitted-questionnaire.component.html',
  styleUrls: ['./submitted-questionnaire.component.scss']
})
export class SubmittedQuestionnaireComponent implements OnInit {

  constructor(private route: ActivatedRoute, private translate: TranslateService) {}


  ngOnInit(): void {
    this.translate.use(this.route.snapshot.params['lang']);
  }

}

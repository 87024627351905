import {Component, Input} from '@angular/core';
import {Article, BlockContent} from '../../article/article.component';
import {notEmpty} from '../../../shared/Utils';

interface MenuItem {
  name: string;
  link: string;
}

@Component({
  selector: 'app-text-only',
  templateUrl: './text-only.component.html',
  styleUrls: ['./text-only.component.scss']
})
export class TextOnlyComponent {
  @Input() content!: BlockContent;
  @Input() article: Article;
  @Input() index: number;

  constructor() { }

  getMenuItems(): MenuItem[] {
    return this.article.content.slice(1).map(b => {
      if (!b.title) {
        return null;
      }
      return {
        name: b.title,
        link: 'section-title-' + b.id
      };
    }).filter(notEmpty);
  }

  scrollTo(id: string, event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const scrollTarget = document.getElementById(id);
    if (scrollTarget) {
      scrollTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

}

/// <reference types="@types/google.maps" />
import {Injectable} from '@angular/core';
import {notNull} from '../Utils';
import MapTypeId = google.maps.MapTypeId;
import {ZipcodeService} from './zipcode.service';
import { LocationService } from './location.service';
import { FavouriteService } from './favourite.service';
import GeocoderStatus = google.maps.GeocoderStatus;

export enum LocationType {
    VO = 'VO',
    HBO = 'HBO',
    MBO = 'MBO',
    WO = 'WO',
    PO = 'PO',
    KO = 'KO',
    SO = 'SO',
}

export interface Location {
    education_field?: string;
    id: number;
    name: string;
    zipcode: string;
    street: string;
    house_number: number;
    house_number_addition: string;
    place: string;
    city_area?: number;
    latitude: number;
    longitude: number;
    image: string | null;
    website: string;
    private_school: boolean;
    location_type: LocationType; // TODO: Use this to generate urls
    pupil_count?: number;
    domains?: number[];
}

@Injectable({
    providedIn: 'root'
})
export class MapService {

    private geocoder = new google.maps.Geocoder();

    constructor(private zipcodeService: ZipcodeService,
        private locationService: LocationService,
        private favoriteService: FavouriteService) {
    }

    initMap(latitude: number | null, longitude: number | null) {
        const mapcolors = [{'featureType': 'landscape', 'elementType': 'all', 'stylers': [{'visibility': 'on'}]}, {
            'featureType': 'landscape',
            'elementType': 'geometry.fill',
            'stylers': [{'color': '#f2f4f2'}]
        }, {'featureType': 'poi.attraction', 'elementType': 'all', 'stylers': [{'visibility': 'off'}]}, {
            'featureType': 'poi.attraction',
            'elementType': 'geometry',
            'stylers': [{'visibility': 'off'}]
        }, {'featureType': 'poi.business', 'elementType': 'all', 'stylers': [{'visibility': 'off'}]}, {
            'featureType': 'poi.government',
            'elementType': 'all',
            'stylers': [{'visibility': 'off'}]
        }, {'featureType': 'poi.medical', 'elementType': 'all', 'stylers': [{'visibility': 'off'}]}, {
            'featureType': 'poi.park',
            'elementType': 'geometry.fill',
            'stylers': [{'color': '#98e2a1'}]
        }, {
            'featureType': 'poi.place_of_worship',
            'elementType': 'all',
            'stylers': [{'visibility': 'off'}]
        }, {
            'featureType': 'poi.place_of_worship',
            'elementType': 'labels.icon',
            'stylers': [{'color': '#7d2e80'}, {'visibility': 'on'}]
        }, {'featureType': 'poi.school', 'elementType': 'all', 'stylers': [{'visibility': 'off'}]}, {
            'featureType': 'poi.school',
            'elementType': 'labels.icon',
            'stylers': [{'color': '#f28700'}, {'visibility': 'off'}]
        }, {
            'featureType': 'poi.sports_complex',
            'elementType': 'all',
            'stylers': [{'visibility': 'on'}]
        }, {
            'featureType': 'poi.sports_complex',
            'elementType': 'geometry.fill',
            'stylers': [{'color': '#98e2a1'}]
        }, {'featureType': 'poi.sports_complex', 'elementType': 'labels', 'stylers': [{'visibility': 'off'}]}, {
            'featureType': 'transit',
            'elementType': 'all',
            'stylers': [{'visibility': 'on'}]
        }, {'featureType': 'transit', 'elementType': 'labels.icon', 'stylers': [{'color': '#69a8de'}]}, {
            'featureType': 'water',
            'elementType': 'all',
            'stylers': [{'color': '#69a8de'}]
        }];


        let myLatLng;
        if (latitude && longitude) {
            myLatLng = new google.maps.LatLng(latitude, longitude);
        } else {
            myLatLng = new google.maps.LatLng(52.078663, 4.288788);
        }

        const map = new google.maps.Map(notNull(document.getElementById('school_gmap')), {
            zoom: 14,
            center: myLatLng,
            mapTypeId: MapTypeId.TERRAIN,
            scrollwheel: false,
            mapTypeControl: false,
            panControl: false,
            fullscreenControl: false
        });

        map.setOptions({
            styles: mapcolors
        });

        google.maps.event.addDomListener(window, 'resize', function() {
            const center = map.getCenter();
            google.maps.event.trigger(map, 'resize');
            map.setCenter(center!);
        });

        const icon = {
            url: '/assets/img/map/marker.png', // url
            scaledSize: new google.maps.Size(29, 43)
        };

        if (latitude && longitude) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                icon: icon,
                clickable: false,
            });
        }

        const zipcode = this.zipcodeService.zipcode$.getValue();
        if (zipcode) {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                componentRestrictions: {
                    country: 'NL',
                    postalCode: zipcode
                }
            }, (results, status) => {
                const directionsService = new google.maps.DirectionsService();
                const directionsRenderer = new google.maps.DirectionsRenderer({
                    suppressMarkers: true,
                    polylineOptions: {
                        strokeColor: '#000000',
                        // strokeWeight: 5
                    }
                });
                directionsRenderer.setMap(map);

                directionsService
                    .route({
                        origin: myLatLng,
                        destination: results !== null ? results[0].geometry.location : '',
                        travelMode: google.maps.TravelMode.WALKING,
                    }, (r) => {
                        directionsRenderer.setDirections(r);
                    });
            });
        }
    }

    getInfoDialogHtml(l: Location, subtitle: string, pulse = false): string {
        let html = ``;

        const link = this.locationService.getLocationLink(l);

        let imageHtml = `<img src="${l.image ? l.image : '/assets/img/map/tijdelijke-image-kaart-wide.svg'}" class="location-image" />`;
        if (link) {
            imageHtml = `<a href="${link}" class="go-to-link">${imageHtml}</a>`;
        }

        let nameHtml = l.name;
        if (link) {
            nameHtml = `<a class="go-to-link" href="${link}">${nameHtml}</a>`;
        }

        let meta = '';
        if (l.education_field) {
            meta = `<span class="meta-text">${subtitle}</span>`
            if(subtitle){
                meta += `<span class="divider"> | </span>`;   
            }   
        }
        meta += `<span class="meta-text">${this.locationService.getDescriptionForType(l.location_type)}</span>`;

        // TODO: Render with angular, since this has no XSS protection
        html = `<div class="popup-box">
                <a href="#" class="popup-close"><i style="pointer-events: none" class="icon-cancel"></i></a>
                ${imageHtml}
                <div class="location-info">
                    <div class="location-header">
                        <a href="#" class="btn-favorite btn-favorite-manual ${pulse ? 'do-pulse' : ''}"
                           data-schoolid="${l.id}"><i style="pointer-events: none"
                                                      class="icon-heart${this.favoriteService.isFavorite(l.id) ? '' : '-empty'}"></i></a>
                        <h2 class="title">
                            ${nameHtml}
                        </h2>
                        <div class="meta">
                            ${meta}
                        </div>
                    </div>
                    <div class="location-address">
                        ${l.street} ${l.house_number}${l.house_number_addition ?? ''}<br/>
                        ${l.zipcode} ${l.place}
                    </div>
                </div>
            </div>`;
        return html;
    }

    findLatLang(address): Promise<[number, number]> {
        return new Promise((resolve, reject) => {
            this.geocoder.geocode({
                componentRestrictions: {
                    country: 'NL',
                    postalCode: address
                }
            }, (results, status) => {
                if (status === GeocoderStatus.OK && results !== null) {
                    resolve([results[0].geometry.location.lat(), results[0].geometry.location.lng()]);
                } else {
                    reject(new Error('Couldnt\'t find the location ' + address));
                }
            });
        });
    }
}

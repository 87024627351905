import {HttpClient} from '@angular/common/http';
import {Component, ElementRef, ErrorHandler, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, EMPTY, fromEvent, Subscription} from 'rxjs';
import {catchError, filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

type Location = {
    id: number;
    name: string;
    values: (number | null)[];
    classCapacity?: { [classId: string]: number | null };
};

type DisplayLocation = {
    id: number;
    name: string;
    classCapacity: { [classId: string]: number | null };
};

type Brugklas = {
    id: string;
    name: string;
};

type HoverInfo = {
    location: DisplayLocation;
    capacity: number | null;
    brugklas: Brugklas
};

interface VoCapacity {
    locations: Location[];
    brugklassen: Brugklas[];
    available: boolean;
}

class MyErrorHandler implements ErrorHandler {
    handleError(error: string): void {
        alert('De data kan niet worden opgehaald van de server. Probeer het later opnieuw.');
    }
}

type EducationType = { name: string, matching: string[] };
const educationTypeOptions: { [period: number]: EducationType[] } = {
    2: [
        {name: 'Praktijkonderwijs', matching: ['praktijkonderwijs']},
        {name: 'VMBO', matching: ['vmbo', 'mavo']},
        {name: 'HAVO', matching: ['havo']},
        {name: 'VWO', matching: ['vwo']},
        {name: 'ISK', matching: ['isk']}
    ],
    3: [
        {name: 'Kopklas', matching: ['kopklas']}
    ]
};

@Component({
    selector: 'app-vo-capacity',
    templateUrl: './vo-capacity.component.html',
    styleUrls: ['./vo-capacity.component.scss'],
    providers: [{provide: ErrorHandler, useClass: MyErrorHandler}]
})
export class VoCapacityComponent implements OnInit, OnDestroy {
    @ViewChild('tooltip') tooltip: ElementRef;
    subscriptions: Subscription[] = [];
    voCapacity: VoCapacity | null = null;
    period: number | null = null;

    hoverInfo: HoverInfo | null = null;

    filterableLocationTypes: EducationType[] = [];
    activeLocationTypeFilters$ = new BehaviorSubject<EducationType[]>([]);

    filteredBrugklassen: Brugklas[] = [];
    filteredLocations: DisplayLocation[] = [];

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private errorHandler: ErrorHandler) {
    }

    ngOnInit(): void {
        const tdHoverEvents$ = fromEvent(document, 'mouseover')
            .pipe(filter(event => (event.target as HTMLTableCellElement).tagName === 'TD'));

        const period$ = this.route.params.pipe(
            map((params: Params) => +params.period)
        );

        const periodCapacity$ = this.route.params.pipe(
            switchMap(params => {
                const period = +params.period;
                return this.http.get<VoCapacity>(environment.api + '/vo/first-year-capacity/' + (params.year || 2024 ) + '/' + period).pipe(
                    catchError(err => {
                        this.errorHandler.handleError(err);
                        return EMPTY;
                    }),
                    map(voCapacityResponse => [period, voCapacityResponse] as [number, VoCapacity])
                );
            }),
            // This mapping method will make a {brugklasId: capacity} dictionary for each location
            map(([period, voCapacityResponse]) => {
                voCapacityResponse.locations.map(location => {
                    location.classCapacity = {};

                    voCapacityResponse.brugklassen.forEach((classType, index) => {
                        (location.classCapacity as Exclude<Location['classCapacity'], undefined>)[classType.id] = location.values[index];
                    });
                });

                return [period, voCapacityResponse] as [number, VoCapacity];
            }),
            shareReplay({bufferSize: 1, refCount: true})
        );

        const filterableLocationTypes$ = periodCapacity$.pipe(
            map(([period]) => {
                const types: EducationType[] = [];

                for (let i = 2; i <= period; i++) {
                    types.push(...educationTypeOptions[i]);
                }

                return types;
            })
        );

        this.subscriptions.push(
            period$.subscribe(period => this.period = period),

            combineLatest([periodCapacity$, this.activeLocationTypeFilters$])
                .subscribe(([[period, capacityData], activeTypeFilter]) => {
                    this.period = period;
                    this.voCapacity = capacityData;

                    let filteredClasses = capacityData.brugklassen;
                    const locationsWithCapacityInfo = capacityData.locations.filter(l => l.values.filter(f => f !== null).length > 0);
                    const filteredLocations = locationsWithCapacityInfo.map(location => {
                        return {
                            id: location.id,
                            name: location.name,
                            classCapacity: location.classCapacity ?? {}
                        } as DisplayLocation;
                    });

                    if (activeTypeFilter.length > 0) {
                        filteredClasses = filteredClasses.filter(classItem => {
                            return activeTypeFilter.some(typeFilter => {
                                return typeFilter.matching.some(matcher => {
                                    return classItem.name.search(new RegExp(matcher, 'i')) !== -1;
                                });
                            });
                        });

                        // OPTION:
                        // Enabling code below will also filter locations, locations that do not have any of the filtered availability will
                        // not show in the list.
                        //
                        // filteredLocations = filteredLocations.map(location => {
                        //     const filteredCapacities: DisplayLocation['classCapacity'] = {};
                        //
                        //     filteredClasses.forEach(filteredClass => {
                        //         filteredCapacities[filteredClass.id] = location.classCapacity[filteredClass.id] ?? null;
                        //     });
                        //
                        //     return {
                        //         id: location.id,
                        //         name: location.name,
                        //         classCapacity: filteredCapacities
                        //     } as DisplayLocation;
                        // }).filter(location => {
                        //     return Object.keys(location.classCapacity).some(capacityId => location.classCapacity[capacityId] !== null);
                        // });
                    }

                    this.filteredLocations = filteredLocations;
                    this.filteredBrugklassen = filteredClasses;
                }),

            filterableLocationTypes$.subscribe(filterableLocationTypes => this.filterableLocationTypes = filterableLocationTypes),

            tdHoverEvents$.subscribe((event: MouseEvent) => {
                const target = event.target as HTMLTableCellElement;
                const rowIndex = (target.parentElement as HTMLTableRowElement).rowIndex;
                const columnIndex = target.cellIndex;

                const selectedLocation = this.filteredLocations[rowIndex - 1];

                if (!selectedLocation || columnIndex <= 0) {
                    return;
                }

                const selectedBrugklas = this.filteredBrugklassen[columnIndex - 1];
                const brugklasCapacity = selectedLocation.classCapacity[selectedBrugklas.id];

                this.hoverInfo = {
                    brugklas: selectedBrugklas,
                    capacity: brugklasCapacity,
                    location: selectedLocation
                };

                // Screen is split into 4 sections, each section has a different way of positioning the tooltip.
                this.tooltip.nativeElement.style.right = `auto`;
                this.tooltip.nativeElement.style.left = `auto`;
                this.tooltip.nativeElement.style.bottom = 'auto';
                this.tooltip.nativeElement.style.top = 'auto';

                if (event.clientX < window.innerWidth / 2) {
                    this.tooltip.nativeElement.style.left = `${target.getBoundingClientRect().right + 15}px`;
                } else {
                    this.tooltip.nativeElement.style.right = `${window.innerWidth - target.getBoundingClientRect().x}px`;
                }

                if (event.clientY < window.innerHeight / 2) {
                    this.tooltip.nativeElement.style.top = `${target.getBoundingClientRect().top + 15}px`;
                } else {
                    this.tooltip.nativeElement.style.bottom = `${window.innerHeight - target.getBoundingClientRect().bottom + 15}px`;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    onCheckBoxChange($event: Event, option: EducationType): void {
        if (($event.target as HTMLInputElement).checked) {
            this.activeLocationTypeFilters$.next([...this.activeLocationTypeFilters$.value, option]);
        } else {
            this.activeLocationTypeFilters$.next(this.activeLocationTypeFilters$.value.filter(v => v !== option));
        }
    }

    totalClassCapacity(brugklas: Brugklas): number {
        return this.voCapacity?.locations.map(location => location.classCapacity?.[brugklas.id] ?? 0).reduce((a, b) => a + b) ?? 0;
    }
}

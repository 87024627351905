import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {KoCategoryComponent} from './categories/ko-category/ko-category.component';
import {KoLocationComponent} from './location/ko-location/ko-location.component';
import {PoCategoryComponent} from './categories/po-category/po-category.component';
import {VoCategoryComponent} from './categories/vo-category/vo-category.component';
import {MboCategoryComponent} from './categories/mbo-category/mbo-category.component';
import {HboWoCategoryComponent} from './categories/hbo-wo-category/hbo-wo-category.component';
import {PoLocationComponent} from './location/po-location/po-location.component';
import {VoLocationComponent} from './location/vo-location/vo-location.component';
import {MboLocationComponent} from './location/mbo-location/mbo-location.component';
import {HboWoLocationComponent} from './location/hbo-wo-location/hbo-wo-location.component';
import {ArticleComponent} from './articles/article/article.component';
import {ArticleListComponent} from './articles/article-list/article-list.component';
import {SoCategoryComponent} from './categories/so-category/so-category.component';
import {IntCategoryComponent} from './categories/int-category/int-category.component';
import {MapComponent} from './map/map.component';
import {OpenDaysComponent} from './open-days/open-days.component';
import {ColophonComponent} from './colophon/colophon.component';
import {FavouriteSchoolsComponent} from './favourite-schools/favourite-schools.component';
import {
    FavouriteSchoolsDownloadComponent
} from './favourite-schools/favourite-schools-download/favourite-schools-download.component';
import {ApplicationContainerComponent} from './application-container/application-container.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {LaunchComponent} from './launch/launch.component';
import {LinkToLocationComponent} from './link-to-location/link-to-location.component';
import {NewcomersComponent} from './newcomers/newcomers.component';
import {SchoolChoiceComponent} from './school-choice/school-choice.component';
import {RefugeeQuestionnaireComponent} from './refugee-questionnaire/refugee-questionnaire.component';
import {
    SubmittedQuestionnaireComponent
} from './refugee-questionnaire/submitted-questionnaire/submitted-questionnaire.component';
import {VoCapacityComponent} from './vo-capacity/vo-capacity.component';
import {VavoLocationComponent} from './location/vavo-location/vavo-location.component';

const routes: Routes = [
    {path: 'favoriete-scholen/download/:category/:schools', component: FavouriteSchoolsDownloadComponent},
    {
        path: '', component: ApplicationContainerComponent, children: [
            {path: 'lancering', component: LaunchComponent},
            {path: '', component: HomeComponent},

            {path: 'categorie/ko', component: KoCategoryComponent},
            {path: 'categorie/po', component: PoCategoryComponent},
            {path: 'categorie/vo', component: VoCategoryComponent},
            {path: 'categorie/mbo', component: MboCategoryComponent},
            {path: 'categorie/hbo-wo', component: HboWoCategoryComponent},
            {path: 'categorie/so', component: SoCategoryComponent},
            {path: 'categorie/int', component: IntCategoryComponent},
            {path: 'categorie/:category/map', component: MapComponent},

            {path: 'locatie/ko/:id', component: KoLocationComponent},
            {path: 'locatie/po/:id', component: PoLocationComponent},
            {path: 'locatie/so/:id', component: PoLocationComponent},
            {path: 'locatie/vo/:id', component: VoLocationComponent},
            {path: 'locatie/vavo/:id', component: VavoLocationComponent},
            {path: 'locatie/mbo/:id', component: MboLocationComponent},
            {path: 'locatie/hbo/:id', component: HboWoLocationComponent},
            {path: 'locatie/wo/:id', component: HboWoLocationComponent},

            {path: 'categorie/basisschooladvies-en-eindtoets', redirectTo: '/categorie/basisschooladvies-en-doorstroomtoets'},
            {path: 'artikel/:slug', component: ArticleComponent},
            {path: 'categorie/:slug', component: ArticleListComponent},

            {path: 'signup-newcomers/:lang', component: RefugeeQuestionnaireComponent},
            {path: 'signup-newcomers/submitted/:lang', component: SubmittedQuestionnaireComponent},
            {path: 'signup-newcomers', redirectTo: 'signup-newcomers/en'},

            {path: 'favoriete-scholen', component: FavouriteSchoolsComponent},
            {path: 'gedeelde-scholen', component: FavouriteSchoolsComponent},
            {path: 'vo/open-dagen', component: OpenDaysComponent},
            {path: 'vo/capaciteit/:period/:year', component: VoCapacityComponent},
            {path: 'vo/capaciteit/:period', component: VoCapacityComponent},
            {path: 'bovo-aanmeldperiode', redirectTo: 'vo/capaciteit/2'},

            {path: 'nieuwkomers', component: NewcomersComponent},

            {path: 'schoolkeuze-2-jarige/:lang', component: SchoolChoiceComponent},
            {path: 'schoolkeuze-3-jarige/:lang', component: SchoolChoiceComponent},
            {path: 'schoolkeuze-2-jarige', redirectTo: 'schoolkeuze-2-jarige/en'},
            {path: 'schoolkeuze-3-jarige', redirectTo: 'schoolkeuze-3-jarige/en'},

            {path: 'juisteschoolkiezen', redirectTo: '/categorie/een-basisschool-kiezen'},
            {path: 'aanmeldenbasisschool', redirectTo: '/categorie/aanmelden-bij-de-basisschool'},
            {path: 'aanmeldformulier', redirectTo: '/artikel/aanmeldformulier'},
            {path: 'aanmeldperioden', redirectTo: '/artikel/aanmelden-bij-een-school-met-leerlingplafond'},

            {path: 'colofon', component: ColophonComponent},

            {path: 'schooldetail/:slug', component: LinkToLocationComponent},

            // Wild Card Route for 404 request
            {
                path: '**', pathMatch: 'full',
                component: NotFoundComponent
            },
        ],
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

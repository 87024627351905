<section class="section section-article-b" [class.no-bff]="content.style===false">
  <div class="st-inner">
    <span class="before"></span>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-9 col-lg-7 col-left">
          <div class="inner indent">
            <div class="article-header">
              <h2 class="h2" id="section-title-{{content.id}}">{{content.title}}</h2>
            </div>
            <div class="article-output">
              <div [innerHTML]="content.text_content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

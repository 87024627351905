import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {MapService} from '../../shared/services/map.service';
import {Subscription} from 'rxjs';
import {updateLinks} from '../../shared/Utils';
import {SeoService} from '../../shared/services/seo.service';
import lottie, {AnimationItem} from 'lottie-web';
import {AnimationOptions} from "ngx-lottie";

interface MboLocation {
    id: number;
    location_type: 'MBO';
    name: string;
    image: string;
    website: string | null;
    phone: string | null;
    email: string | null;
    street: string;
    house_number: number;
    house_number_addition: string;
    zipcode: string;
    place: string;

    school: {
        teaching: string,
        educations: string,
        special: string
    };

    latitude: number | null;
    longitude: number | null;

    mbo_domains: {domain: string, link: string | null}[];
}

@Component({
    selector: 'app-mbo-location',
    templateUrl: './mbo-location.component.html',
    styleUrls: ['./mbo-location.component.scss']
})
export class MboLocationComponent implements OnInit, OnDestroy {
    location: MboLocation | undefined;
    randInt = Math.floor(Math.random() * 10) + 1;
    subs = new Subscription();

    lottieOptions: AnimationOptions = {};
    private animation: AnimationItem;


    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private mapService: MapService,
        private router: Router,
        private seo: SeoService
    ) {
    }

    ngOnInit(): void {
        const paramsSub = this.route.params.pipe(
            tap(() => window.scrollTo({top: 0})),
            switchMap(params => this.http.get<MboLocation>(environment.api + `/mbo/${params.id}`)),
            map(location => {
                let website = location.website;

                if (website !== null) {
                    website = location.website?.startsWith('http') ? location.website : 'http://' + location.website;
                }

                return {
                    ...location,
                    website,
                    mbo_domains: location.mbo_domains.sort((a, b) => +(b.link !== null) - +(a.link !== null))
                };
            })
        ).subscribe(location => {
            this.location = location;

            this.seo.updateTags(
                location.name,
                location.school.teaching,
                location.image
            );

            setTimeout(() => {
                // @ts-expect-error jerome stuff
                $('.js-mh').matchHeight();
                this.mapService.initMap(location.latitude, location.longitude);
                updateLinks();

                this.animation = lottie.loadAnimation({
                    container: document.querySelector('ng-lottie') as Element,
                    renderer: 'svg',
                    loop: false,
                    autoplay: false,
                    path: '/assets/img/svg/school/mbo/scroll.json'
                });
            }, 0);
        });

        this.subs.add(paramsSub);
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollPercent = scrollTop / docHeight;

        const frame = Math.min(Math.max(scrollPercent * 60 * 15, 0), 60 - 1);
        if (this.animation) {
            this.animation.goToAndStop(frame, true);
        }
    }

    scroll(el: HTMLElement, e: Event): void {
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
        e.preventDefault();
        e.stopPropagation();
    }

    goBack(event): void {
        event.preventDefault();
        event.stopPropagation();

        const {navigationId} = window.history.state;
        if (navigationId > 1) {
            window.history.back();
        } else {
            this.router.navigateByUrl('/categorie/mbo/map');
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}

<main class="main-content theme-ko">

  <section class="section section-school-header">
    <div class="st-inner py-0">

      <a href="finder.php" class="page-btn-back">
        <span class="label">Terug</span>
        <i class="arrow icon-right-small"></i>
      </a>

      <div class="container" *ngIf="location">

        <div class="col-left">
          <div class="slick-wrapper">
            <span class="overlay"></span>
            <div class="slick-images">
              <div class="item bgimg js-mh" [style.background-image]="'url(' + location.image + ')'">
                <img src="/assets/img/photos/school/KO.jpg" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-right js-mh">
          <div class="inner">
            <header class="school-header">
              <app-favorite-school [id]="location.id" [locationType]="'KO'"></app-favorite-school>
              <h1 class="h1 title">{{location.name}}</h1>
            </header>
            <div class="school-details">

              <div class="school-info">
                <p>{{location.street}} {{location.house_number}} {{location.house_number_addition}}<br>
                  {{location.zipcode}} {{location.place}}</p>
              </div>

              <ul class="school-actions">
                <li><a [href]="location.website" target="_blank" class="button outline btn-a">Website</a></li>
                <li><a [href]="'tel:' + location.phone" class="button outline btn-b">{{location.phone}}</a></li>
                <li><a [href]="'mailto:' + location.email" class="button outline btn-c">Contact per mail</a></li>
                <li><a (click)="scroll(map, $event)" class="button outline btn-d">Weergeven op kaart</a></li>1
              </ul>

            </div>
          </div>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-school-article type-a">
    <div class="st-inner">
      <div class="container">

        <div class="col-left indent">
          <h2 class="h2">Donec rhoncus accumsan quam vitae sagittis</h2>
          <div class="article-output">
            <p> Curabitur dictum efficitur auctor. Nullam volutpat vestibulum luctus. Quisque id tempor lacus. Nam nisi ante, blandit quis risus sed, eleifend ornare massa. Donec commodo gravida nisl eu vulputate. Sed condimentum felis eu diam varius sodales. Cras tempus cursus lacus, feugiat dapibus sem volutpat sit amet. Donec rhoncus accumsan quam vitae sagittis. Mauris rutrum vulputate purus. Proin mollis lorem maximus, tempor est a, accumsan ipsum. Pellentesque nisi felis, imperdiet nec nibh ut, sollicitudin auctor lorem.</p>
          </div>
        </div>

        <div class="col-right">
          <figure class="st-image bgimg has-before" data-bgimg="/assets/img/photos/kinderopvang.jpg">
            <img src="/assets/img/photos/kinderopvang.jpg" />
          </figure>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-school-text">
    <div class="st-inner">
      <div class="container">
        <div class="row">

          <div class="col-md-4 col-lg-5"></div>

          <div class="col-md-7 col-lg-6">
            <h2 class="h2">Donec rhoncus accumsan quam vitae sagittis</h2>
            <div class="article-output">
              <p> Curabitur dictum efficitur auctor. Nullam volutpat vestibulum luctus. Quisque id tempor lacus. Nam nisi ante, blandit quis risus sed, eleifend ornare massa. Donec commodo gravida nisl eu vulputate. Sed condimentum felis eu diam varius sodales. Cras tempus cursus lacus, feugiat dapibus sem volutpat sit amet.</p>
              <p>Donec commodo gravida nisl eu vulputate. Sed condimentum felis eu diam varius sodales. Cras tempus cursus lacus, feugiat dapibus sem volutpat sit amet. Curabitur dictum efficitur auctor. Nullam volutpat vestibulum luctus. Quisque id tempor lacus. Nam nisi ante, blandit quis risus sed, eleifend ornare massa. </p>
              <p>Sed condimentum felis eu diam varius sodales. Cras tempus cursus lacus, feugiat dapibus sem volutpat sit amet.</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>


  <section class="section section-school-cta">
    <div class="st-inner py-lg">
      <div class="container">

        <div class="blocks">
          <div class="block">
            <h2 class="h2 split-title">
              <span class="line-1 f1 mb-0">Bekijk hier alle </span>
              <span class="line-2 h1 t-lower">open dagen</span>
            </h2>
            <a href="opendagen.php" class="button">Lorem ipsum <i class="icon-right-small"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="section section-school-article type-b">
    <div class="st-inner">
      <div class="container">

        <div class="col-left indent">
          <h2 class="h2">Donec rhoncus accumsan quam vitae sagittis</h2>
          <div class="article-output">
            <p> Curabitur dictum efficitur auctor. Nullam volutpat vestibulum luctus. Quisque id tempor lacus. Nam nisi ante, blandit quis risus sed, eleifend ornare massa. Donec commodo gravida nisl eu vulputate. Sed condimentum felis eu diam varius sodales. Cras tempus cursus lacus, feugiat dapibus sem volutpat sit amet. Donec rhoncus accumsan quam vitae sagittis. Mauris rutrum vulputate purus. Proin mollis lorem maximus, tempor est a, accumsan ipsum. Pellentesque nisi felis, imperdiet nec nibh ut, sollicitudin auctor lorem.</p>
          </div>
        </div>

        <div class="col-right">
          <figure class="st-image bgimg has-after" data-bgimg="/assets/img/photos/kinderopvang.jpg">
            <img src="/assets/img/photos/kinderopvang.jpg" />
          </figure>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-school-map" #map>
    <div class="gmap" id="school_gmap"></div>
  </section>


  <section class="section section-school-articles">
    <div class="st-inner py-lg">
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Alles over </span>
            <span class="line-2 h1">kinderopvang in Den Haag</span>
          </h2>
        </div>

        <app-sub-category-list subCategorySlug="KO"></app-sub-category-list>

      </div>
    </div>
  </section>

</main>

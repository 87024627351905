import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class ZipcodeService {
    zipcode$ = new BehaviorSubject<string|null>(null);

    constructor() {

    }
}

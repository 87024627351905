import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {getUrlParts} from '../Utils';


@Injectable({
    providedIn: 'root'
})
export class RouterHelperService {

    constructor(private router: Router) {
    }

    open(href: string, openNewWindowIfExternal = true): void {
        const linkParts = getUrlParts(href);
        if (linkParts.host === location.host) {
            const baseHref = document.getElementsByTagName('base')[0].href;
            const baseUrlPath = getUrlParts(baseHref).pathname;

            this.router.navigateByUrl(linkParts.pathname.replace(baseUrlPath, '/') + linkParts.search);
        } else {
            if (openNewWindowIfExternal) {
                window.open(href);
            } else {
                window.location.href = href;
            }
        }
    }
}

import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {FaqItem, FaqService} from '../../shared/services/faq.service';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ZipcodeService} from '../../shared/services/zipcode.service';
import {AnimationOptions} from "ngx-lottie";
import lottie, {AnimationItem} from 'lottie-web';

@Component({
    selector: 'app-ko-category',
    templateUrl: './ko-category.component.html',
    styleUrls: ['./ko-category.component.scss']
})
export class KoCategoryComponent implements OnInit, AfterViewInit, OnDestroy {
    faqs: FaqItem[] | undefined;
    subscriptions: Subscription[] = [];
    zipcode = '';

    lottieOptions: AnimationOptions = {};
    private animation: AnimationItem;

    constructor(
        private faqService: FaqService,
        private zipcodeService: ZipcodeService
    ) {
    }

    ngOnInit(): void {
        this.faqService.getFaqItemsForCategory('KO').pipe(take(1)).subscribe(faqs => {
            this.faqs = faqs;

            setTimeout(() => {
                // @ts-expect-error jerome stuff
                initJeromeAccordeon();
                // @ts-expect-error jerome stuff
                $('.js-mh').matchHeight();
            }, 0);
        });

        this.subscriptions.push(
            this.zipcodeService.zipcode$.subscribe(value => {
                this.zipcode = value ? value : '';
            })
        );
    }

    ngAfterViewInit() {
        this.animation = lottie.loadAnimation({
            container: document.querySelector('ng-lottie') as Element,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: '/assets/img/svg/category/ko/ko-icoon.json'
        });
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollPercent = scrollTop / docHeight;

        const frame = Math.min(Math.max(scrollPercent * 60 * 15, 0), 60 - 1);
        if (this.animation) {
            this.animation.goToAndStop(frame, true);
        }
    }


    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}

import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

export interface FaqItem {
    id: number;
    question: string;
    answer: string;
    link: string;
}

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    constructor(
        private meta: Meta,
        private titleService: Title
    ) {
    }

    updateTags(title: string, description: string, image?: string | null): void {
        if (!image) {
            image = '/assets/img/homepage/scholenwijzer01.jpg';
        }
        if (image.startsWith('/')) {
            image = location.origin + image;
        }

        this.meta.addTags([
            {name: 'image', property: 'og:image', itemprop: 'image', content: image},
            {name: 'image', property: 'og:image:secure_url', itemprop: 'image', content: image},
            {name: 'description', property: 'og:description', content: description}
        ]);

        this.titleService.setTitle(title);
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {SignupInfo} from '../po-location.component';

export interface PoYearCapacity {
    year: number;
    period_1_max_students: number;
    period_1_capacity: number;
    period_1_enrollments: number;

    period_2_max_students: number;
    period_2_capacity: number;
    period_2_enrollments: number;

    period_3_max_students: number;
    period_3_capacity: number;
    period_3_enrollments: number;

    period_4_max_students: number;
    period_4_capacity: number;
    period_4_enrollments: number;
    total_placements: number;
}

@Component({
    selector: 'app-po-year-capacity-table',
    templateUrl: './po-year-capacity-table.component.html',
    styleUrls: ['./po-year-capacity-table.component.scss']
})
export class PoYearCapacityTableComponent implements OnInit {
    @Input() yearCapacity: PoYearCapacity;
    @Input() periods: string[] = []; // TODO: this should not be statically given, this should be coming from the API.
    @Input() additionalComment = ''; // TODO: same as above
    @Input() signupInfo: SignupInfo | null = null;
    noYearCapacity = false;

    constructor() {
    }

    ngOnInit(): void {
        if (!this.yearCapacity.year && this.signupInfo) {
            this.noYearCapacity = true;
            this.yearCapacity.year = this.signupInfo.year;
            this.yearCapacity.period_1_max_students = this.signupInfo.max_seats;
        }
    }

    countCapacity(year: PoYearCapacity): number {
        let total = 0;
        for (let i = 1; i <= 4; i++) {
            total += year[`period_${i}_max_students`];
        }
        return total;
    }

}

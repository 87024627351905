<div class="site-footer">
  <div class="primary">
    <div class="container">
      <a href="https://www.denhaag.nl/" target="_blank" class="denhaag-logo"></a>
      <ul class="footer-nav">
        <li><a href="https://www.denhaag.nl/home/algemeen/proclaimer.htm" target="_blank">Proclaimer</a></li>
        <li><a href="https://www.denhaag.nl/nl/verklaring-inzake-gegevensbescherming.htm" target="_blank">Gegevensbescherming</a></li>
        <li><a href="https://www.denhaag.nl/nl/gebruiksvoorwaarden.htm" target="_blank">Gebruikersvoorwaarden</a></li>
        <li><a [routerLink]="['/', 'colofon']" >Colofon</a></li>
        <li><a href="https://www.denhaag.nl/nl/in-de-stad/nieuws/pers.htm" target="_blank">Pers</a></li>
      </ul>
    </div>
  </div>
</div>

<a href="#" class="back-to-top"><i class="icon-right-small"></i></a>

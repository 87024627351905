<main class="main-content theme-io">
  <section class="section section-category-header">
    <div class="st-inner">

        <lottie-player
                src="/assets/img/svg/category/io/2.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>

      <div class="container">

        <div class="st-content">

            <div class="scroll-container" (window:scroll)="onScroll($event)">
                <ng-lottie [options]="lottieOptions" width="100%" height="100%"></ng-lottie>
            </div>
          <h1 class="page-title h2 split-title">
            <span class="line-1 f1 mb-0">Ik zoek </span>
            <span class="line-2 h1 t-lower">internationaal onderwijs</span>
          </h1>
          <div class="school-filter type-a clearfix">
            <div class="c-search clearfix">
              <input type="text" class="input" name="postcode" [(ngModel)]="zipcode" placeholder="Zoek op postcode"> <a [routerLink]="'/categorie/int/map'" [queryParams]="zipcode ? {'zipcode': zipcode} : {}">zoeken</a>
            </div>
            <a [routerLink]="['/', 'categorie', 'int', 'map']" class="match-c-search">
              <span>Zoeken zonder postcode <i class="icon-right-small"></i></span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-category-article">
    <div class="st-inner">

        <lottie-player
                src="/assets/img/svg/category/io/4.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>

      <div class="container">

        <div class="col-right">
          <figure class="st-image bgimg" style="background-image: url('/assets/img/photos/IO/IO_01.jpg');">
            <span class="overlay"></span>
            <img src="/assets/img/photos/IO/IO_01.jpg" alt="Foto internationaal onderwijs" />
          </figure>
        </div>

        <div class="col-left indent">
          <h2 class="h2">Internationaal onderwijs in Den Haag</h2>
          <div class="article-output">
            <p>Komt u uit het buitenland en bent u van plan om samen met uw kinderen naar Den Haag te verhuizen? De Haagse regio heeft de meeste internationale scholen van Nederland, met een goede reputatie. Ook bieden sommige Nederlandse scholen tweetalig onderwijs. In de Scholenwijzer vindt u alle informatie die u nodig heeft om een goede school te vinden voor uw kind.</p>
          </div>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-category-articles">
    <div class="st-inner">
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Alles over </span>
            <span class="line-2 h1">internationaal onderwijs</span>
          </h2>
        </div>

        <app-sub-category-list subCategorySlug="int"></app-sub-category-list>

      </div>
    </div>
  </section>


  <section class="section section-category-faq" *ngIf="faqs && faqs.length">
    <div class="st-inner">
      <span class="before"></span>
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Veelgestelde vragen over </span>
            <span class="line-2 h1">internationaal onderwijs</span>
          </h2>
        </div>

        <div class="col-left indent">
          <div class="c-faq-links">
            <a href="{{faq.link}}" class="question" *ngFor="let faq of faqs">{{faq.question}} <span class="arrow"><i class="icon-right-small"></i></span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>

</main>

<header class="site-header">
    <div class="container">
        <a [routerLink]="['/']" (click)="logoClicked()" class="comp-logo"></a>

        <a [routerLink]="['/', 'favoriete-scholen']" class="whishlist" [class.off-screen]="searchActive"><i class="heart icon-heart"></i> <span class="text">opgeslagen locaties</span> <i class="arrow icon-right-small"></i></a>

        <div class="c-search-box" [class.show]="searchActive">
            <form method="post" action="#" class="c-search-form">
                <input type="text" class="input" name="keyword" id="dosearch" placeholder="Zoek op de website" [formControl]="searchControl">
                <a><i id="search-icon" class="icon-search"></i></a>
            </form>
            <span class="toggle-search toggle-search-close" (click)="searchActive = !searchActive"><i class="icon-cancel"></i></span>
        </div>

        <span class="toggle-search toggle-search-open" [class.off-screen]="searchActive" (click)="searchActive = !searchActive"><i class="icon-search"></i></span>

        <div class="nav-toggle">
            <div class="nav-icon">
                <div></div>
            </div>
        </div>
    </div>
</header>

<div class="search-layer" [class.show]="searchActive" id="search_layer">
    <div class="inner">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-left">
                    <h3 class="h2">Scholen</h3>

                    <div class="c-ajax-loader" *ngIf="searching"></div>
                    <div *ngIf="!searching && searchResult === null">Type een zoekwoord in de zoekbalk</div>
                    <div *ngIf="searchResult && searchResult.locations.length === 0">Geen resultaten</div>


                    <div class="c-list-view" *ngIf="searchResult && searchResult.locations.length > 0">
                        <div class="min-height-wrapper">
                            <div class="flex-wrapper" *ngFor="let location of locations">
                                <div class="list-row has-goto location-link">
                                    <a [href]="locationService.getLocationLink(location)" appPossiblyExternal (click)="searchActive = false">
                                        <div class="cell cell-1 cell-title" [appHighlight]="highlightedSearch">{{location.name}}</div>
                                        <a class="row-action goto" [class.has-open-days]="location.has_open_days">
                                            <i class="icon-right-small"></i>
                                        </a>
                                    </a>
                                </div>

                                <a class="button" [href]="locationService.getLocationLink(location) + '?scroll-action=open-days'" [class.hide]="!location.has_open_days">Open dag</a>
                            </div>
                        </div>

                        <p-paginator *ngIf="searchResult.location_pages > 1" (onPageChange)="onLocationPageChange($event)" [first]="locationFirst" [rows]="10" [totalRecords]="searchResult.location_pages * 10" class="location-paginator"></p-paginator>
                    </div>

                </div>
                <div class="col-sm-6 col-right">
                    <h3 class="h2">Artikelen</h3>

                    <div class="c-ajax-loader" *ngIf="searching"></div>
                    <div *ngIf="!searching && searchResult === null">Type een zoekwoord in de zoekbalk</div>
                    <div *ngIf="searchResult && searchResult.articles.length === 0">Geen resultaten</div>

                    <div class="c-list-view" *ngIf="searchResult && searchResult.articles.length > 0">
                        <div class="min-height-wrapper">
                            <div class="flex-wrapper" *ngFor="let article of articles">
                                <div class="list-row has-goto article-link">
                                    <a [routerLink]="['/', 'artikel', article.slug]" (click)="searchActive = false">
                                        <div class="cell cell-1 cell-title" [appHighlight]="highlightedSearch">{{article.title}}</div>
                                        <span class="row-action goto"><i class="icon-right-small"></i></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <p-paginator *ngIf="searchResult.article_pages > 1" (onPageChange)="onArticlePageChange($event)" [first]="articleFirst" [rows]="10" [totalRecords]="searchResult.article_pages * 10"></p-paginator>
                    </div>
                </div>
            </div>
        </div>
        <span class="before"></span><span class="after"></span>
    </div>
</div>

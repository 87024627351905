<main class="main-content">

  <div class="mobile-filters-container" [class.open]="mobileFiltersOpen">
    <div class="mobile-filters-wrapper">
      <button class="toggle-close" (click)="mobileFiltersOpen = false"><i class="icon-cancel"></i></button>

      <div class="mobile-filters">
        <div class="sorting-container">
          <div class="c-search clearfix">
            <input type="text" [formControl]="searchControl" placeholder="Zoek school" class="input">
            <i class="icon-search"></i>
          </div>
        </div>

        <div class="sorting-container clearfix">
          <div class="select-box">
            <select class="order-select"
                    (change)="setSingleArrayValue($event, listMutationForm.controls['educationType'])"
                    [value]="firstArrayValue(listMutationForm.controls['educationType'].value)">
              <option [ngValue]="null" [disabled]="true">Kies onderwijsniveau</option>
              <option *ngFor="let option of educationTypeOptions" [ngValue]="option">{{option}}</option>
            </select>
          </div>
        </div>

        <div class="sorting-container clearfix">
          <div class="select-box">
            <select class="order-select" [formControl]="listMutationForm.controls['openDayType']">
              <option [ngValue]="null" [disabled]="true">Kies open dag soort</option>
              <option *ngFor="let option of openDayTypeOptions" [ngValue]="option.value">{{option.name}}</option>
            </select>
          </div>
        </div>

        <div class="sorting-container clearfix">
          <div class="select-box">
            <select class="order-select" [formControl]="listMutationForm.controls['sorting']">
              <option *ngFor="let option of sortingMethods | keyvalue" [ngValue]="option.key">{{option.value.name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="section section-opendagen">
    <div class="st-inner">
      <a href="#" (click)="goBack($event)" class="page-btn-back">
        <span class="label">Terug</span>
        <i class="arrow icon-right-small"></i>
      </a>

      <span class="before-2"></span>
      <span class="before"></span>
      <span class="after"></span>
      <div class="container">
        <div class="row">

          <div class="col-lg-10 col-lg-offset-1">

            <header class="page-header">
              <h1 class="page-title h2">Alle open dagen</h1>
            </header>

            <ng-container *ngIf="responsiveState === 'desktop'">

              <section class="section-finder">
                <div class="finder-filters divided">
                  <div class="filter-box" *ngFor="let option of educationTypeOptions">
                    <div class="filter-title">
                      <label class="filter-toggle" [class.active]="listMutationForm.controls['educationType'].value.includes(option)">
                        <input type="checkbox"
                               [checked]="listMutationForm.controls['educationType'].value.includes(option)"
                               (change)="onCheckBoxChange($event, listMutationForm.controls['educationType'], option)"
                               [value]="option">
                        <span>{{option}}</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="finder-filters">
                  <div class="filter-box">
                    <div class="c-search clearfix">
                      <input type="text" [formControl]="searchControl" placeholder="Zoek school" class="input">
                      <i class="icon-search"></i>
                    </div>
                  </div>
                </div>
              </section>

              <section class="section-finder">
                <div class="finder-filters">
                  <div class="filter-box" *ngFor="let option of openDayTypeOptions">
                    <div class="filter-title">
                      <label
                        class="filter-toggle"
                        [ngClass]="openDayTypeMapping[option.value].color"
                        [class.active]="listMutationForm.controls['openDayType'].value === option.value">

                        <input type="radio" [formControl]="listMutationForm.controls['openDayType']" (click)="deselectOnSecondClick(listMutationForm.controls['openDayType'], option.value)" [value]="option.value">
                        <span>{{option.name}}</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="sorting-container clearfix">
                  <div class="select-box">
                    <select class="order-select" [formControl]="listMutationForm.controls['sorting']">
                      <option *ngFor="let option of sortingMethods | keyvalue" [ngValue]="option.key">{{option.value.name}}</option>
                    </select>
                  </div>
                </div>
              </section>

              <p *ngIf="openDays !== null && openDays.length === 0">
                Er zijn geen open dagen gevonden.
              </p>

              <table style="width: 100%" *ngIf="openDays !== null && openDays.length > 0">
                <colgroup>
                  <col style="">
                  <col style="min-width: 225px;">
                  <col style="min-width: 150px;">
                  <col style="min-width: 150px;">
                </colgroup>

                <tbody>
                <ng-container *ngFor="let openDay of openDays; trackBy: trackByOpenDay">
                  <tr [class.open]="openOpenDays.includes(openDay.id)">
                    <td class="location-name">
                      <a [routerLink]="['/', 'locatie', openDay.location.location_type.toLowerCase(), openDay.location.id]">
                        {{openDay.location.name}}
                      </a>
                      <app-favorite-school [id]="openDay.location.id" [locationType]="openDay.location.location_type"></app-favorite-school>
                    </td>
                    <td class="day-date open-day-toggle" (click)="toggleOpen(openDay)">{{openDay.start | date:'d MMMM YYYY'}}</td>
                    <td class="day-times open-day-toggle" (click)="toggleOpen(openDay)">
                      <span>{{openDay.start | date: 'HH'}}<sup>{{openDay.start | date: 'mm'}}</sup></span>
                      <span> - </span>
                      <span>{{openDay.end | date: 'HH'}}<sup>{{openDay.end | date: 'mm'}}</sup></span>
                    </td>
                    <td class="day-type open-day-toggle" (click)="toggleOpen(openDay)">
                      {{openDayTypeMapping[openDay.open_day_type].name}}
                    </td>
                    <td (click)="toggleOpen(openDay)" class="open-day-toggle">
                      <svg class="nav-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.23 15.55"
                           [ngClass]="openDayTypeMapping[openDay.open_day_type].color">
                        <path d="M17.67,6.72L11.52,.56C10.93-.02,9.98-.02,9.4,.56s-.59,1.54,0,2.12l3.41,3.41H1.67c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5H13.17l-3.77,3.77c-.59,.59-.59,1.54,0,2.12,.29,.29,.68,.44,1.06,.44s.77-.15,1.06-.44l6.15-6.15c.28-.28,.44-.66,.44-1.06s-.16-.78-.44-1.06Z"/>
                      </svg>
                    </td>
                  </tr>

                  <tr *ngIf="openOpenDays.includes(openDay.id)" class="open-day-details">
                    <td colspan="3">
                      <span [innerHTML]="openDay.description"></span>
                    </td>
                    <td colspan="2">
                      <a *ngIf="openDay.info_url" [href]="openDay.info_url" target="_blank" title="Meer informatie" class="button">Meer informatie</a>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>

            </ng-container>

            <div class="mobile-container" *ngIf="responsiveState === 'mobile'">

              <div class="location-list">
                <div class="location" *ngFor="let openDay of openDays" [class.open]="openOpenDays.includes(openDay.id)">
                  <div class="location-name">
                    <a  [routerLink]="['/', 'locatie', openDay.location.location_type.toLowerCase(), openDay.location.id]">
                      {{openDay.location.name}}
                    </a>
                    <app-favorite-school [id]="openDay.location.id" [locationType]="openDay.location.location_type"></app-favorite-school>
                  </div>
                  <div class="open-day-type" (click)="toggleOpen(openDay)">{{openDayTypeMapping[openDay.open_day_type].name}}</div>
                  <div class="open-day-date-time" (click)="toggleOpen(openDay)">
                    <div>
                      <div class="open-day-date">{{openDay.start | date:'d MMMM YYYY'}}</div>
                      <div class="open-day-time">
                        <span>{{openDay.start | date: 'HH'}}<sup>{{openDay.start | date: 'mm'}}</sup></span>
                        <span> - </span>
                        <span>{{openDay.end | date: 'HH'}}<sup>{{openDay.end | date: 'mm'}}</sup></span>
                      </div>
                    </div>

                    <div>
                      <svg class="nav-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.23 15.55"
                           [class.green]="openDay.open_day_type === openDayTypeMapping.OPEN_DAY.key"
                           [class.orange]="openDay.open_day_type === openDayTypeMapping.OPEN_EVENING.key"
                           [class.pink]="openDay.open_day_type === openDayTypeMapping.FOLLOW_ALONG_DAY.key">
                        <path d="M17.67,6.72L11.52,.56C10.93-.02,9.98-.02,9.4,.56s-.59,1.54,0,2.12l3.41,3.41H1.67c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5H13.17l-3.77,3.77c-.59,.59-.59,1.54,0,2.12,.29,.29,.68,.44,1.06,.44s.77-.15,1.06-.44l6.15-6.15c.28-.28,.44-.66,.44-1.06s-.16-.78-.44-1.06Z"/>
                      </svg>
                    </div>
                  </div>

                  <div *ngIf="openOpenDays.includes(openDay.id)">
                    <p class="description" [innerHTML]="openDay.description"></p>
                    <a *ngIf="openDay.info_url" [href]="openDay.info_url" target="_blank" title="Meer informatie" class="button">Meer informatie</a>
                  </div>
                </div>
              </div>


              <button class="filters-open-button button" (click)="mobileFiltersOpen = !mobileFiltersOpen" [class.disappear]="mobileFiltersOpen">
                <i class="icon-sliders"></i>
                Toon filters
              </button>

            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

</main>

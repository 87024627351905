import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Subject, Subscription} from 'rxjs';
import {Filter, LocationInfo, LocationsResponse} from '../../map/map.component';
import {filter, shareReplay, switchMap, take} from 'rxjs/operators';
import {LocationService} from '../../shared/services/location.service';
import {groupBy} from "../../shared/Utils";
import {OpenDay, OpenDayRequest, OpenDayTypeMapping} from "../../open-days/open-days.component";
import {Location} from "../../shared/services/map.service";

@Component({
    selector: 'app-favourite-schools-download',
    templateUrl: './favourite-schools-download.component.html',
    styleUrls: ['./favourite-schools-download.component.scss']
})
export class FavouriteSchoolsDownloadComponent implements OnInit, OnDestroy {

    category = '';
    locations: LocationInfo[] = [];
    schoolIds: number[] = [];
    subscriptions: Subscription[] = [];
    meta: Filter[];
    categorySelection$ = new Subject<string>();
    voOpenDays: OpenDay[] = [];
    openDaysPerLocation: Record<number, (OpenDay | null)[]>;
    OpenDayTypeMapping = OpenDayTypeMapping;

    constructor(private route: ActivatedRoute,
                public locationService: LocationService,
                private http: HttpClient) { }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.params.subscribe(params => {
                this.category = params.category;
                this.schoolIds = params.schools.split(',').map(s => parseInt(s, 10));
            }),
            this.route.params.pipe(
                switchMap(params => {
                    if (params !== null) {
                        return  this.http.get(environment.api + '/locations/' + this.category);
                    }
                    return [];
                })
            ).subscribe((output: LocationsResponse) => {
                if (output) {
                    this.locations = output.locations.filter(location => this.schoolIds.includes(location.id));
                }
                this.meta = output.meta;
                this.openDaysPerLocation = this.padOpenDayArrays();
            })
        );

        const voOpenDays$ = this.categorySelection$.pipe(
            filter((category) => category === 'vo'),
            take(1),
            switchMap(() => this.http.get<OpenDayRequest>(environment.api + '/vo/open-days')),
            shareReplay(1)
        );

        this.subscriptions.push(
            voOpenDays$.subscribe((output) => {
                this.voOpenDays = output.open_days;
                this.openDaysPerLocation = this.padOpenDayArrays();
            })
        );

        this.categorySelection$.next(this.category);


        setTimeout(() => {
            // @ts-expect-error jerome stuff
            $('.site-wrapper').addClass('no-pt');
        }, 10);

    }

    // this is duplicate logic, and in the future may be moved to the backend
    padOpenDayArrays(): Record<number, (OpenDay | null)[]> {
        const lookup: Record<number, OpenDay[]> = groupBy(this.voOpenDays, (day) => day.location.id);
        const allValues = this.locations
            .filter(location => location.id in lookup)
            .map(location => lookup[location.id]);

        const maxCount = Math.max(...allValues.map(value => value.length), 0);

        const returnObj: Record<number, (OpenDay | null)[]> = {};

        this.locations.forEach(location => {
            const originalValues = location.id in lookup ? lookup[location.id] : [];
            returnObj[location.id] = originalValues.concat(Array(maxCount - originalValues.length).fill(null));
            // only take the first open day
            returnObj[location.id] = returnObj[location.id].slice(0, 1);
        });

        return returnObj;
    }

    getLocationOpenDays(location: Location): (OpenDay | null)[] {
        if (this.openDaysPerLocation) {
            if (location.id in this.openDaysPerLocation) {
                return this.openDaysPerLocation[location.id];
            }
        }

        return [];
    }

    hasOpenDays(location: Location): boolean {
        return this.getLocationOpenDays(location).some(day => day !== null);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

if (environment.production) {
    Sentry.init({
        dsn: 'https://63935959b69a559d3f98176a554ed51a@o268405.ingest.sentry.io/4506593103970304',
        integrations: [],
    });

    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

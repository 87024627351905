import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colophon',
  templateUrl: './colophon.component.html',
  styleUrls: ['./colophon.component.scss']
})
export class ColophonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll({
      top: 0
    });
  }

}

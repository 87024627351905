import {Component, OnDestroy, OnInit} from '@angular/core';
import {ZipcodeService} from '../shared/services/zipcode.service';
import {NavigationStart, Router} from '@angular/router';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {EMPTY, Observable, Subject, Subscription} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {LocationService} from '../shared/services/location.service';
import { LocationType } from '../shared/services/map.service';

interface Location {
    id: number;
    name: string;
    image: string;
    location_type: LocationType;
    website: string;
    private_school: boolean;
    type: string;
    has_open_days: boolean;
}

interface Article {
    slug: string;
    title: string;
    image: string;
    type: string;
}

interface SearchResult {
    locations: Location[];
    articles: Article[];
    location_pages: number;
    article_pages: number;
}

interface PageEvent {
    first: number;
    rows: number;
    page: number;
    pageCount: number;
}

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
    zipcode: string;

    searchControl = new UntypedFormControl();
    searching = false;
    searchResult: SearchResult | null = null;
    locations: Location[] = [];
    articles: Article[] = [];

    searchActive = false;
    highlightedSearch = '';
    locationFirst = 0;
    articleFirst = 0;
    subscriptions: Subscription[] = [];
    private locationPageChangeSubject = new Subject<number>();
    private articlePageChangeSubject = new Subject<number>();

    constructor(
        private zipcodeService: ZipcodeService,
        private router: Router,
        private http: HttpClient,
        public locationService: LocationService
    ) {
    }

    ngOnInit(): void {
        this.zipcodeService.zipcode$.subscribe(value => {
            this.zipcode = value ? value : '';
        });

        this.searchControl.valueChanges.pipe(
            debounceTime(500),
            tap((searchTerm) => {
                this.searching = true;
                this.searchResult = null;
                this.locations = [];
                this.articles = [];
                this.highlightedSearch = searchTerm;
            }),
            switchMap(searchTerm => {
                if (!searchTerm) {
                    this.searching = false;
                    return EMPTY;
                }

                return this.fetchSearchResults(searchTerm);
            }),
            tap(() => this.searching = false),
        ).subscribe(searchResult => {
            this.searchResult = searchResult;
            this.locations = searchResult.locations;
            this.articles = searchResult.articles;
        });

        this.locationPageChangeSubject.pipe(
            switchMap(page => this.fetchSearchResults(this.searchControl.value, page, 'location_page')),
        ).subscribe(searchResult => {
            this.locations = searchResult.locations;
        });

        this.articlePageChangeSubject.pipe(
            switchMap(page => this.fetchSearchResults(this.searchControl.value, page, 'article_page')),
        ).subscribe(searchResult => {
            this.articles = searchResult.articles;
        });

        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => this.searchActive = false);
    }

    fetchSearchResults(searchTerm: string, page: number = 0, queryParam: string = 'location_page'): Observable<SearchResult> {
        const queryParams = new HttpParams().set(queryParam, page.toString());
        return this.http.get<SearchResult>(`${environment.api}/search/${searchTerm}`, { params: queryParams });
    }

    search(event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.zipcode) {
            this.zipcodeService.zipcode$.next(this.zipcode);
        }
    }

    onLocationPageChange(event: PageEvent): void {
        this.locationPageChangeSubject.next(event.page);
    }

    onArticlePageChange(event: PageEvent): void {
        this.articlePageChangeSubject.next(event.page);
    }

    logoClicked(): void {
        this.searchActive = false;
        if (this.router.isActive('/', {paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored'})) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}

<div class="pdf-wrapper" [class.vo-export]="category === 'vo'">

    <div class="pdf-header">
        <img src="assets/img/pdf/opgeslagen-locaties.jpg" />
    </div>

    <div class="school-grid clearfix">

        <ng-container *ngFor="let location of locations">
            <div class="item">
                <div class="inner-panel">
                    <div class="school-header js-mh-2">
                        <figure class="school-image  bgimgxxx" data-bgimg="{{location.image}}" [ngStyle]="{backgroundImage: 'url(' + location.image + ')'}">
                            <img [src]="location.image" />
                        </figure>

                        <div class="school-name">
                            <h2 class="title h3" title="{{location.name}}">{{location.name}}</h2>
                            <div class="meta">
                                <ng-container *ngIf="location.education_field">
                                    <span class="meta-text">{{locationService.getDisplayValue(location.education_field, locationService.getOptionsForField(meta, 'education_field'))}}</span>
                                    <span class="divider"> | </span>
                                </ng-container>
                                <span class="meta-text">{{locationService.getDescriptionForType(location.location_type)}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="school-attributes">
                        <div class="attribute" *ngFor="let propertyInfo of meta">
                            <span class="key">{{ propertyInfo.name }}</span>
                            {{locationService.getDisplayValue(location[propertyInfo.field], propertyInfo.options)}}
                        </div>
                    </div>
                </div>
                <div class="open-days" *ngIf="hasOpenDays(location)">
                    <h3 class="open-day-title"><strong>Eerst volgende opendag</strong></h3>
                    <div class="school-attributes">
                        <div class="attribute" *ngFor="let openDay of getLocationOpenDays(location)">
                            <span class="key"><strong>{{ OpenDayTypeMapping[openDay.open_day_type].name }}</strong></span>
                            <span>
                                <span class="day-month-attribute">{{ openDay.start | date: 'dd MMMM' }}</span>
                                <span>{{openDay.start | date: 'HH'}}<sup>{{openDay.start | date: 'mm'}}</sup></span>
                                <span> - </span>
                                <span>{{openDay.end | date: 'HH'}}<sup>{{openDay.end | date: 'mm'}}</sup></span>
                            </span>

                        </div>
                    </div>
                </div>
            </div>

            <section class="page-breaker"></section>

        </ng-container>

    </div>

    <div class="pdf-footer">
<!--        <img src="assets/img/pdf/pdf-locations-bottom.jpg" />-->
    </div>

</div>

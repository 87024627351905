import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {FaqItem, FaqService} from '../../shared/services/faq.service';
import {take} from 'rxjs/operators';
import {SeoService} from '../../shared/services/seo.service';
import {AnimationOptions} from "ngx-lottie";
import lottie, {AnimationItem} from 'lottie-web';

@Component({
  selector: 'app-hbo-wo-category',
  templateUrl: './hbo-wo-category.component.html',
  styleUrls: ['./hbo-wo-category.component.scss']
})
export class HboWoCategoryComponent implements OnInit, AfterViewInit {
  faqs: FaqItem[] | undefined;

    lottieOptions: AnimationOptions = {};
    private animation: AnimationItem;

  constructor(
    private faqService: FaqService,
    private seo: SeoService
  ) {
  }

  ngOnInit(): void {
    this.seo.updateTags(
        'Scholenwijzer Den Haag',
        'De Scholenwijzer geeft ouders en kinderen informatie over alle verschillende scholen in Den Haag en omstreken. Dat maakt het kiezen van een school een stuk makkelijker! En er is nog veel meer uit de Scholenwijzer te halen. Hoe weet u welke school het beste past bij u en uw kind? Waar moet u op letten? Hoe werkt het aanmelden? De Scholenwijzer geeft antwoord op al deze vragen en meer.'
    );

    this.faqService.getFaqItemsForCategory('HBO-WO').pipe(take(1)).subscribe(faqs => {
      this.faqs = faqs;

      setTimeout(() => {
        // @ts-expect-error jerome stuff
        initJeromeJs();
      }, 0);
    });
  }

    ngAfterViewInit() {
        this.animation = lottie.loadAnimation({
            container: document.querySelector('ng-lottie') as Element,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: '/assets/img/svg/category/hbo/hbo-icoon.json'
        });
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollPercent = scrollTop / docHeight;

        const frame = Math.min(Math.max(scrollPercent * 60 * 5, 0), 60 - 1);
        if (this.animation) {
            this.animation.goToAndStop(frame, true);
        }
    }
}

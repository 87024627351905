import {Directive, ElementRef, HostListener} from '@angular/core';
import {RouterHelperService} from './shared/services/routerHelper.service';

@Directive({
    selector: '[appPossiblyExternal]'
})
export class PossiblyExternalDirective {

    constructor(private el: ElementRef, private routerHelper: RouterHelperService) {
    }

    @HostListener('click', ['$event'])
    onClick(event: Event): boolean {
        event.preventDefault();
        event.stopPropagation();

        this.routerHelper.open(this.el.nativeElement.href);
        return true;
    }
}

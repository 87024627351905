import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {LocationService} from '../shared/services/location.service';
import {RouterHelperService} from '../shared/services/routerHelper.service';
import {LocationType} from '../shared/services/map.service';

export interface LocationInfo {
    id: number;
    name: string;
    image: string;
    website: string;
    phone: string;
    email: string;
    street: string;
    house_number: number;
    house_number_addition: string;
    zipcode: string;
    place: string;
    private_school: boolean;
    location_type: LocationType; // Returned in lowercase
}

@Component({
    selector: 'app-link-to-location',
    templateUrl: './link-to-location.component.html',
    styleUrls: ['./link-to-location.component.scss']
})
export class LinkToLocationComponent implements OnInit {

    constructor(private activatedRoute: ActivatedRoute, private location: LocationService, private routerHelper: RouterHelperService, private http: HttpClient) {

    }

    ngOnInit(): void {
        const slug = this.activatedRoute.snapshot.params.slug;
        const locationId = slug.split('-')[0];

        this.http.get(environment.api + '/location/' + locationId).pipe(
            catchError(result => {
                window.location.href = '/404';
                return EMPTY;
            })
        ).subscribe((result: LocationInfo) => {
            const link = this.location.getLocationLink(result);
            if (!link) {
                window.location.href = '/404';
                return;
            }
            this.routerHelper.open(link, false);
        });
    }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Filter} from '../../map/map.component';
import {groupBy} from '../../shared/Utils';
import {LocationService} from '../../shared/services/location.service';
import {OpenDay, OpenDayTypeMapping} from '../../open-days/open-days.component';
import {CategoryTypeValues} from '../favourite-schools.component';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ResponsiveService} from '../../shared/services/responsive.service';
import {Location} from '../../shared/services/map.service';

@Component({
  selector: 'app-my-schools-list',
  templateUrl: './favourite-schools-list.component.html',
  styleUrls: ['./favourite-schools-list.component.scss']
})
export class FavouriteSchoolsListComponent implements OnInit, OnDestroy {
  @Input() locations: Location[] = [];
  @Input() meta: Filter[] = [];
  @Input() category: CategoryTypeValues;

  responsiveState: 'mobile' | 'desktop' = 'desktop';

  private voOpenDays$ = new BehaviorSubject<OpenDay[]>([]);

  openDaysPerLocation: Record<number, (OpenDay | null)[]>;
  openDayTypeMapping = OpenDayTypeMapping;

  @Input() set voOpenDays(value: OpenDay[]) {
    this.voOpenDays$.next(value);
  }

  subs = new Subscription();

  constructor(public locationService: LocationService, private responsiveService: ResponsiveService) {
  }

  padOpenDayArrays(lookup: Record<number, OpenDay[]>): Record<number, (OpenDay | null)[]> {
    const allValues = this.locations
      .filter(location => location.id in lookup)
      .map(location => lookup[location.id]);

    const maxCount = Math.max(...allValues.map(value => value.length), 0);

    const returnObj: Record<number, (OpenDay | null)[]> = {};

    this.locations.forEach(location => {
      const originalValues = location.id in lookup ? lookup[location.id] : [];
      returnObj[location.id] = originalValues.concat(Array(maxCount - originalValues.length).fill(null));
    });

    return returnObj;
  }

  ngOnInit(): void {
    const openDaysSub = this.voOpenDays$.subscribe(days => {
      const groupedOpenDays = groupBy(days, (day) => day.location.id);
      this.openDaysPerLocation = this.padOpenDayArrays(groupedOpenDays);
    });

    const responseSub = this.responsiveService.responseChange$.subscribe(state => this.responsiveState = state);

    this.subs.add(openDaysSub);
    this.subs.add(responseSub);

    this.initSlick();
  }


  private initSlick(): void {
    setTimeout(() => {
      // @ts-expect-error jerome stuff
      $('.slick-scholen').slick({
        autoplay: false,
        infinite: false,
        arrows: false,
        dots: true,
        fade: false,
        autoplaySpeed: 5000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        pauseOnHover: false,
        pauseOnFocus: false,
        prevArrow: '<a href="#" data-role="none" class="slick-prev" aria-label="Vorige" tabindex="0" role="button"><i class="icon-right-small"></i></a>',
        nextArrow: '<a href="#" data-role="none" class="slick-next" aria-label="Volgende" tabindex="0" role="button"><i class="icon-right-small"></i></a>',
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              speed: 300,
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              speed: 300,
              arrows: false,
              dots: true
            }
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              speed: 300,
              arrows: false,
              dots: true
            }
          }
        ]
      });
    }, 0);

    setTimeout(() => {
      // @ts-expect-error jerome stuff
      $('.js-mh').matchHeight();
    }, 0);
  }

  private destroySlick(): void {
    // @ts-expect-error jerome stuff
    $('.slick-scholen').slick('unslick');
  }

  ngOnDestroy(): void {
    this.destroySlick();
    this.subs.unsubscribe();
  }

  getLocationOpenDays(location: Location): (OpenDay | null)[] {
    if (this.openDaysPerLocation) {
      if (location.id in this.openDaysPerLocation) {
        return this.openDaysPerLocation[location.id];
      }
    }

    return [];
  }

  hasOpenDays(location: Location): boolean {
    return this.getLocationOpenDays(location).some(day => day !== null);
  }
}

<main class="main-content theme-vo">

  <section class="section section-category-header">
    <div class="st-inner">

        <lottie-player
                src="/assets/img/svg/category/vo/2.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>

      <div class="container">

        <div class="st-content">

            <div class="scroll-container" (window:scroll)="onScroll($event)">
                <ng-lottie [options]="lottieOptions" width="100%" height="100%"></ng-lottie>
            </div>

          <h1 class="page-title h2 split-title">
            <span class="line-1 f1 mb-0">Ik zoek een </span>
            <span class="line-2 h1 t-lower">middelbare school</span>
          </h1>
          <div class="school-filter type-b">
            <label class="frm-checkbox"><input type="checkbox" name="soort[]" value="74" (change)="change($event)" /> Praktijkonderwijs</label>
            <label class="frm-checkbox"><input type="checkbox" name="soort[]" value="64,75" (change)="change($event)" /> Vmbo BL (+ lwoo)</label>
            <label class="frm-checkbox"><input type="checkbox" name="soort[]" value="66,77" (change)="change($event)" /> Vmbo KL (+ lwoo)</label>
            <label class="frm-checkbox"><input type="checkbox" name="soort[]" value="65,76,79,81,73" (change)="change($event)" /> Vmbo GL-TL*</label>
            <label class="frm-checkbox"><input type="checkbox" name="soort[]" value="70" (change)="change($event)" /> Havo</label>
            <label class="frm-checkbox"><input type="checkbox" name="soort[]" value="72,71" (change)="change($event)" /> Vwo</label>
            <a routerLink="/categorie/vo/map" [queryParams]="getQueryParams()" class="button">Zoeken <i class="icon-right-small"></i></a>

            <span class="help">U kunt meerdere niveaus selecteren (bijv. bij een dubbeladvies).<br><br>*Vanaf schooljaar 2024-2025 bestaan de enkelvoudige Gl én Tl adviezen niet meer. Er is alleen nog één samengesteld Gl-Tl advies.</span>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-category-cta capacity-headline">
    <div class="st-inner">
      <div class="container">
        <div class="blocks has-single-block">
          <div class="single-block" *ngIf="voFirstGradeCapacityPeriod === 1">
            <h2 class="h2 split-title">
              <span class="line-1 f1 mb-0">Bekijk hier alle</span>
              <span class="line-2 h1 t-lower">Open dagen</span>
            </h2>
            <a routerLink="/vo/open-dagen" class="button" title="Bekijk open dagen">bekijken <i class="icon-right-small"></i></a>
          </div>

          <div class="single-block" *ngIf="voFirstGradeCapacityPeriod === 2 || voFirstGradeCapacityPeriod === 3">
            <h2 class="h2 split-title">
              <span class="line-1 f1 mb-0">Bekijk hier</span>
              <span class="line-2 h1 t-lower">Capaciteit periode {{voFirstGradeCapacityPeriod}}</span>
            </h2>
            <a routerLink="/vo/capaciteit/{{voFirstGradeCapacityPeriod}}" class="button" title="Bekijk capaciteit periode {{voFirstGradeCapacityPeriod}}">Capaciteit <i class="icon-right-small"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-category-article">
    <div class="st-inner">
        <lottie-player
                src="/assets/img/svg/category/vo/4.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>
      <div class="container">

        <div class="col-right">
          <figure class="st-image bgimg" style="background-image: url('/assets/img/photos/VO/VO_01.jpg')">
            <span class="overlay"></span>
            <img src="/assets/img/photos/VO/VO_01.jpg" alt="Alternatieve beschrijving" />
          </figure>
        </div>

        <div class="col-left indent">
          <h2 class="h2">Naar de middelbare school</h2>
          <div class="article-output">
            <p>Uw kind gaat volgend jaar naar de middelbare school. Dat is een grote stap. Natuurlijk wilt u samen met uw kind een school uitzoeken die goed past bij uw kind. De basisschool helpt u daarbij. U kunt zelf ook alvast kijken op de Scholenwijzer.</p>
            <p>De Scholenwijzer geeft informatie over alle middelbare scholen in Den Haag, Leidschendam-Voorburg en Rijswijk. Ook vindt u informatie over het aantal beschikbare plaatsen, de open dagen en hoe u uw kind kunt aanmelden bij de middelbare school van uw keuze.</p>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-category-cta capacity-headline">
    <div class="st-inner">
      <div class="container">
        <div class="blocks has-single-block">
          <div class="single-block">
            <h2 class="h2 split-title">
              <span class="line-1 f1 mb-0">Wist je dat</span>
              <span class="line-2 h1 t-lower">je digitaal kan aanmelden</span>
            </h2>
            <a href="https://www.bovohaaglanden.nl/aanmelden/digitaal-aanmelden/" target="_blank" class="button" title="Bekijk open dagen">Meer informatie <i class="icon-right-small"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section>


<!--  <section class="section section-category-cta">-->
<!--    <div class="st-inner">-->
<!--      <div class="container">-->
<!--        <div class="blocks has-single-block">-->
<!--          <div class="single-block">-->
<!--            <h2 class="h2 split-title">-->
<!--              <span class="line-1 f1 mb-0">Bekijk hier alle</span>-->
<!--              <span class="line-2 h1 t-lower">Open dagen</span>-->
<!--            </h2>-->
<!--            <a routerLink="/vo/open-dagen" class="button" title="Bekijk alle open dagen">Open dagen <i class="icon-right-small"></i></a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->


  <section class="section section-category-articles">
    <div class="st-inner">
      <div class="container">
        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Alles over </span>
            <span class="line-2 h1">middelbaar onderwijs in Den Haag</span>
          </h2>
        </div>
        <app-sub-category-list subCategorySlug="VO"></app-sub-category-list>
      </div>
    </div>
  </section>


  <section class="section section-category-faq" *ngIf="faqs && faqs.length">
    <div class="st-inner">
      <span class="before"></span>
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Veelgestelde vragen over </span>
            <span class="line-2 h1">middelbaar onderwijs</span>
          </h2>
        </div>

        <div class="col-left indent">
          <div class="c-faq-links">
            <a href="{{faq.link}}" [target]="getTarget(faq.link)" class="question" *ngFor="let faq of faqs">{{faq.question}} <span class="arrow"><i class="icon-right-small"></i></span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>

</main>

import {Injectable} from '@angular/core';

export enum CategoryType {
    KO = 'ko',
    PO = 'po',
    VO = 'vo',
    MBO = 'mbo',
    HBOWO = 'hbo-wo',
    SO = 'so',
    INT = 'int'
}

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    constructor() {

    }

    getCategoryName(c: CategoryType): string {
        switch (c) {
            case CategoryType.KO:
                return 'kinderopvang';
            case CategoryType.PO:
                return 'basisonderwijs';
            case CategoryType.VO:
                return 'middelbare school';
            case CategoryType.MBO:
                return 'mbo';
            case CategoryType.HBOWO:
                return 'hbo & universiteit';
            case CategoryType.SO:
                return 'speciaal onderwijs';
            case CategoryType.INT:
                return 'internationaal onderwijs';
        }
    }
}


<main class="main-content" *ngIf="article">

  <ng-container *ngFor="let articleBlock of article.content; let i = index">
    <ng-container [ngSwitch]="articleBlock.content_type">

      <ng-container *ngSwitchCase="'MEDIA_LEFT_TEXT_RIGHT'">
        <app-media-left-text-right [content]="articleBlock" [article]="article" [index]="i"></app-media-left-text-right>
      </ng-container>

      <ng-container *ngSwitchCase="'TEXT_ONLY'">
        <app-text-only [content]="articleBlock" [article]="article" [index]="i"></app-text-only>
      </ng-container>

      <ng-container *ngSwitchCase="'MEDIA_RIGHT_TEXT_LEFT'">
        <app-media-right-text-left [content]="articleBlock"></app-media-right-text-left>
      </ng-container>

      <ng-container *ngSwitchCase="'TEXT_RIGHT'">
        <app-text-right [content]="articleBlock"></app-text-right>
      </ng-container>

      <ng-container *ngSwitchCase="'TEXT_LEFT'">
        <app-text-left [content]="articleBlock"></app-text-left>
      </ng-container>

      <ng-container *ngSwitchCase="'BANNER'">
        <app-banner [content]="articleBlock"></app-banner>
      </ng-container>

    </ng-container>
  </ng-container>
</main>

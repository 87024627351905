import {Component, Input, OnInit} from '@angular/core';
import {Article, BlockContent} from '../../article/article.component';
import {notEmpty, stripHtml} from '../../../shared/Utils';
import {SeoService} from '../../../shared/services/seo.service';

interface MenuItem {
    name: string;
    link: string;
}

@Component({
    selector: 'app-media-left-text-right',
    templateUrl: './media-left-text-right.component.html',
    styleUrls: ['./media-left-text-right.component.scss']
})
export class MediaLeftTextRightComponent implements OnInit {
    @Input() content!: BlockContent;
    @Input() article: Article;
    @Input() index: number;
    menuItems: MenuItem[];
    url: string;

    constructor(private seo: SeoService) {
    }

    ngOnInit(): void {
        this.url = location.pathname;

        if (this.index === 0 && this.content.text_content) {
            this.seo.updateTags(
                this.article.title,
                stripHtml(this.content.text_content),
                this.article.image ?? '/assets/img/unavailable.jpg'
            );
        }

        // Skip first element because this is the first article
        this.menuItems = this.article.content.slice(1).map(b => {
            if (!b.title) {
                return null;
            }
            return {
                name: b.title,
                link: 'section-title-' + b.id
            };
        }).filter(notEmpty);
    }

    scrollTo(id: string, event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        const scrollTarget = document.getElementById(id);
        if (scrollTarget) {
            scrollTarget.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

export interface FaqItem {
  id: number;
  question: string;
  answer: string;
  link: string;
}

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(
    private http: HttpClient,
  ) { }

  getFaqItemsForCategory(categorySlug: string): Observable<FaqItem[]> {
    return this.http.get<{faqs: FaqItem[]}>(environment.api + `/faqs/${categorySlug}`).pipe(
      map(httpResult => httpResult.faqs)
    );
  }
}

<div class="col-lg-6 col-data-left">

  <div class="table-intro-text mb-1">
    {{yearCapacity.year - 1}}/<strong>{{yearCapacity.year}}</strong>
    <span> {{countCapacity(yearCapacity)}}</span> plaatsen
  </div>

  <table cellpadding="0" cellspacing="0" class="c-data-table">
    <tbody>
    <tr>
      <td>{{yearCapacity.year - 1}}/<strong>{{yearCapacity.year}}</strong></td>
      <td class="center">Periode plafond</td>
      <td class="center">Beschikbare plaatsen</td>
      <td class="center">Aanmeldingen</td>
    </tr>
    <tr>
      <td class="key">
        <span class="title">Periode 1</span>
        <span class="subtitle">{{periods[0]}}</span>
      </td>
      <ng-container *ngIf="!noYearCapacity">
        <td class="center">{{yearCapacity.period_1_max_students}}</td>
        <td class="center">{{yearCapacity.period_1_capacity}}</td>
        <td class="center">{{yearCapacity.period_1_enrollments}}</td>
      </ng-container>
      <ng-container *ngIf="noYearCapacity">
        <td class="center">-</td>
        <td class="center">-</td>
        <td class="center">-</td>
      </ng-container>
    </tr>
    <tr>
      <td class="key">
        <span class="title">Periode 2</span>
        <span class="subtitle">{{periods[1]}}</span>
      </td>
      <ng-container *ngIf="!noYearCapacity">
        <td class="center">{{yearCapacity.period_2_max_students}}</td>
        <td class="center">{{yearCapacity.period_2_capacity}}</td>
        <td class="center">{{yearCapacity.period_2_enrollments}}</td>
      </ng-container>
      <ng-container *ngIf="noYearCapacity">
        <td class="center">-</td>
        <td class="center">-</td>
        <td class="center">-</td>
      </ng-container>
    </tr>
    <tr>
      <td class="key">
        <span class="title">Periode 3</span>
        <span class="subtitle">{{periods[2]}}</span>
      </td>
      <ng-container *ngIf="!noYearCapacity">
        <td class="center">{{yearCapacity.period_3_max_students}}</td>
        <td class="center">{{yearCapacity.period_3_capacity}}</td>
        <td class="center">{{yearCapacity.period_3_enrollments}}</td>
      </ng-container>
      <ng-container *ngIf="noYearCapacity">
        <td class="center">-</td>
        <td class="center">-</td>
        <td class="center">-</td>
      </ng-container>
    </tr>
    <tr>
      <td class="key">
        <span class="title">Periode 4</span>
        <span class="subtitle">{{periods[3]}}</span>
      </td>
      <ng-container *ngIf="!noYearCapacity">
        <td class="center">{{yearCapacity.period_4_max_students}}</td>
        <td class="center">{{yearCapacity.period_4_capacity}}</td>
        <td class="center">{{yearCapacity.period_4_enrollments}}</td>
      </ng-container>
      <ng-container *ngIf="noYearCapacity">
        <td class="center">-</td>
        <td class="center">-</td>
        <td class="center">-</td>
      </ng-container>
    </tr>
    </tbody>
  </table>

  <div class="data-footer-text" [style.visibility]="noYearCapacity ? 'hidden' : 'visible'">
      <span class="key">Totaal aantal toegelaten kinderen voor aanmeldjaar {{yearCapacity.year - 1}}
        -{{yearCapacity.year}}</span>
    <span class="value">{{yearCapacity.total_placements}}</span>
  </div>

  <div *ngIf="additionalComment" id="additional-comment">
    <strong>{{additionalComment}}</strong>
  </div>

</div>

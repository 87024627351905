import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {notNull} from '../shared/Utils';
import {Router} from '@angular/router';
import {SeoService} from '../shared/services/seo.service';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {FavouriteService} from '../shared/services/favourite.service';
import {ResponsiveService} from '../shared/services/responsive.service';

export type OpenDay = {
  id: number;
  location: {
    id: number;
    name: string;
    image: string;
    website: string;
    phone: string;
    email: string;
    street: string;
    house_number: number;
    house_number_addition: string;
    zipcode: string;
    place: string;
    location_type: string;
    vo_education_types?: string[];
  };
  start: Date;
  end: Date;
  description: string;
  isLiked: boolean | null;
  open_day_type: 'OPEN_DAY' | 'OPEN_EVENING' | 'FOLLOW_ALONG_DAY' | 'PARENTS_DAY' | 'EDUCATION_EVENING_FOR_PARENTS_AND_CHILDREN' | 'GUIDED_TOURS' | 'WORKSHOPS'
  info_url: string
};

export type OpenDayRequest = {
  meta: {
    education_types: { id: number, name: string }[]
  };
  open_days: OpenDay[];
};

enum SortOptions {
  ALPHABETICAL = 'ALPHABETICAL',
  DATE = 'DATE',
  LIKED_FIRST = 'LIKED_FIRST'
}

type ListMutators = {
  educationType: string[];
  openDayType: string | null;
  sorting: SortOptions;
};

type OpenDaySortFn = (a: OpenDay, b: OpenDay) => number;

export const OpenDayTypeMapping: { [key in OpenDay['open_day_type']]: {name: string, key: key, color: string} } = {
  OPEN_DAY: {name: 'Open dag', key: 'OPEN_DAY', color: 'green'},
  OPEN_EVENING: {name: 'Open avond', key: 'OPEN_EVENING', color: 'green'},
  FOLLOW_ALONG_DAY: {name: 'Meeloopdag', key: 'FOLLOW_ALONG_DAY', color: 'pink'},
  GUIDED_TOURS: {name: 'Rondleidingen', key: 'GUIDED_TOURS', color: 'pink'},
  PARENTS_DAY: {name: 'Informatieavond voor ouders', key: 'PARENTS_DAY', color: 'blue'},
  EDUCATION_EVENING_FOR_PARENTS_AND_CHILDREN: {name: 'Voorlichtingsavond voor ouders en leerlingen', key: 'EDUCATION_EVENING_FOR_PARENTS_AND_CHILDREN', color: 'blue'},
  WORKSHOPS: {name: 'Proef- en minilesjes, workshops', key: 'WORKSHOPS', color: 'orange'}
};

@Component({
  selector: 'app-open-days',
  templateUrl: './open-days.component.html',
  styleUrls: ['./open-days.component.scss']
})
export class OpenDaysComponent implements OnInit, OnDestroy {
  openDays: OpenDay[] | null = null;
  openOpenDays: number[] = [];

  educationTypeOptions: string[] = [];
  openDayTypeOptions: { value: string, name: string }[];

  responsiveState: 'mobile' | 'desktop' = 'desktop';

  openDayTypeMapping = OpenDayTypeMapping;

  mobileFiltersOpen = false;

  subs = new Subscription();


  searchControl = new UntypedFormControl();
  listMutationForm = new UntypedFormGroup({
    educationType: new UntypedFormControl([]),
    openDayType: new UntypedFormControl(),
    sorting: new UntypedFormControl(SortOptions.LIKED_FIRST),
  } as { [key in keyof ListMutators]: UntypedFormControl });

  sortingMethods: { [key in keyof typeof SortOptions]: { fn: OpenDaySortFn, name: string } } = {
    DATE: {fn: (a, b) => a.start.getTime() - b.start.getTime(), name: 'Datum'},
    LIKED_FIRST: {fn: (a, b) => Number(b.isLiked) - Number(a.isLiked), name: 'Opgeslagen locaties eerst'},
    ALPHABETICAL: {fn: (a, b) => a.location.name.localeCompare(b.location.name), name: 'Alfabet'}
  };

  trackByOpenDay = (index: number, item: OpenDay) => item.id;

  constructor(
      private http: HttpClient,
      private router: Router,
      private seo: SeoService,
      private favoriteService: FavouriteService,
      private responsiveService: ResponsiveService
  ) {
    this.openDayTypeOptions = (Object.keys(OpenDayTypeMapping) as (keyof typeof OpenDayTypeMapping)[]).map(key => {
      return {
        name: OpenDayTypeMapping[key].name,
        value: key
      };
    });
  }

  ngOnInit(): void {
    this.seo.updateTags(
        'Scholenwijzer Den Haag',
        'De Scholenwijzer geeft ouders en kinderen informatie over alle verschillende scholen in Den Haag en omstreken. Dat maakt het kiezen van een school een stuk makkelijker! En er is nog veel meer uit de Scholenwijzer te halen. Hoe weet u welke school het beste past bij u en uw kind? Waar moet u op letten? Hoe werkt het aanmelden? De Scholenwijzer geeft antwoord op al deze vragen en meer.'
    );

    const responsivenessSub = this.responsiveService.responseChange$
        .subscribe(responsiveState => {
          this.responsiveState = responsiveState;
          this.mobileFiltersOpen = false;
        });
    this.subs.add(responsivenessSub);

    const openDaysRequest$ = this.http.get<OpenDayRequest>(environment.api + '/vo/open-days')
        .pipe(
            map(apiLocations => {

              // sadly the http service keeps dates as string, below patches this
              apiLocations.open_days.forEach(openDay => {
                openDay.start = new Date(openDay.start);
                openDay.end = new Date(openDay.end);
              });

              return apiLocations;
            })
        );

    const search$ = this.searchControl.valueChanges.pipe(startWith(this.searchControl.value as string | null), debounceTime(300));
    const openDayMutators$: Observable<ListMutators> = this.listMutationForm.valueChanges.pipe(startWith(this.listMutationForm.value as ListMutators));
    const favoritesChanged$ = this.favoriteService.favoritesChanged$.pipe(startWith(null));

    const filterSubscription = combineLatest([openDaysRequest$, openDayMutators$, search$, favoritesChanged$])
        .pipe(
            map(([openDaysRequest, mutators, search, _]) => {
              // Update locations favorite status
              const favoriteLocations = this.favoriteService.getFavorites();
              openDaysRequest.open_days.forEach(openDay => {
                openDay.isLiked = favoriteLocations.find(favoriteLocation => favoriteLocation.id === openDay.location.id) !== undefined;
              });

              let result = openDaysRequest.open_days
                  .sort((a, b) => {
                    const methodA = this.sortingMethods[mutators.sorting].fn;
                    const methodB = this.sortingMethods.DATE.fn;
                    const methodC = this.sortingMethods.ALPHABETICAL.fn;

                    // Logical OR (||) will use next method when method returns 0 (equal)
                    return methodA(a, b) || methodB(a, b) || methodC(a, b);
                  });

              if (search !== null) { // Case-insensitive search
                result = result.filter(openDay => openDay.location.name.search(new RegExp(notNull(search), 'i')) !== -1);
              }

              if (mutators.educationType.length > 0) {
                result = result.filter(openDay => openDay.location.vo_education_types?.some(educationType => mutators.educationType.indexOf(educationType) !== -1));
              }

              if (mutators.openDayType !== null) {
                result = result.filter(openDay => openDay.open_day_type === mutators.openDayType);
              }

              return [result, openDaysRequest.meta] as [OpenDayRequest['open_days'], OpenDayRequest['meta']];
            }),
        )
        .subscribe(([openDays, meta]) => {
          this.openDays = openDays;

          this.educationTypeOptions = meta.education_types.map(type => type.name);
        });

    this.subs.add(filterSubscription);
  }

  goBack(event): void {
    event.preventDefault();
    event.stopPropagation();

    const {navigationId} = window.history.state;
    if (navigationId > 1) {
      window.history.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  deselectOnSecondClick(control: AbstractControl, value: string): void {
    if (control.value === value) {
      control.setValue(null);
    }
  }

  toggleOpen(openDay: OpenDay): void {
    if (this.openOpenDays.includes(openDay.id)) {
      this.openOpenDays = [];
      return;
    }

    this.openOpenDays = [openDay.id];
  }


  onCheckBoxChange($event: Event, control: AbstractControl, option: string): void {
    let options = [option];

    if (option === 'vmbo tl' || option === 'mavo') {
      options = ['vmbo tl', 'mavo'];
    }

    if (($event.target as HTMLInputElement).checked) {
      control.setValue([...control.value, ...options]);
    } else {
      control.setValue((control.value as string[]).filter(v => !options.includes(v)));
    }
  }

  firstArrayValue(value: string[]): string | null {
    if (value.length > 0) {
      return value[0];
    }

    return null;
  }

  setSingleArrayValue($event: Event, control: AbstractControl): void {
    control.setValue([($event.target as HTMLSelectElement).value]);
  }
}

<main class="main-content">
    <div class="tooltip" #tooltip>
        {{hoverInfo?.location.name}}<br>
        {{hoverInfo?.brugklas?.name}}: {{hoverInfo?.capacity ?? 0}} plekken
    </div>

    <article class="st-inner">
        <header>
            <h1>Capaciteit VO {{period === 3 ? 'restronde' : 'ronde ' + period}}</h1>
            <ng-container *ngIf="voCapacity && voCapacity.available">
                <p>Hieronder vindt u een actueel overzicht van de capaciteit van scholen in het voortgezet onderwijs voor {{period === '3' ? 'restronde' : 'ronde ' + period}}.</p>
                <p class="narrow-screen-warning">Dit overzicht is het best te zien op brede schermen.</p>
            </ng-container>
            <ng-container *ngIf="voCapacity && !voCapacity.available">
                <p class="not-available">Het overzicht voor capaciteit voor deze ronde is nog niet beschikbaar</p>
            </ng-container>
        </header>

        <section class="section-finder">
            <div class="finder-filters">
                <div class="filter-box" *ngFor="let option of filterableLocationTypes">
                    <div class="filter-title">
                        <label class="filter-toggle" [class.active]="activeLocationTypeFilters$.value.includes(option)">
                            <input type="checkbox"
                                   [checked]="activeLocationTypeFilters$.value.includes(option)"
                                   (change)="onCheckBoxChange($event, option)"
                                   [value]="option">
                            <span>{{option.name}}</span>
                        </label>
                    </div>
                </div>
            </div>
        </section>
    </article>

    <section class="table-section" *ngIf="voCapacity && voCapacity.available">
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th
                        class="rotate"
                        *ngFor="let brugklas of filteredBrugklassen">
                        <span [class.hoveredText]="hoverInfo?.brugklas === brugklas">{{brugklas.name}}</span>
                    </th>
                </tr>
            </thead>
            <tbody
                    (mouseleave)="tooltip.style.setProperty('display', 'none')"
                    (mouseenter)="tooltip.style.setProperty('display', 'block')">
                <tr *ngFor="let location of filteredLocations">
                    <td class="align-right"
                        [class.hoveredText]="hoverInfo?.location === location"
                        [routerLink]="['/locatie', 'vo', location.id]">
                        {{location.name}}
                    </td>
                    <td *ngFor="let brugklas of filteredBrugklassen"
                        [class.empty]="(location.classCapacity?.[brugklas.id] ?? null) === null"
                        [class.hoveredBg]="hoverInfo?.location === location || hoverInfo?.brugklas === brugklas"
                        [class.selectedBg]="hoverInfo?.location === location && hoverInfo?.brugklas === brugklas">
                        {{location.classCapacity?.[brugklas.id]}}
                    </td>
                </tr>
                <tr class="total">
                    <td class="align-right"><strong>Totalen</strong></td>
                    <td *ngFor="let category of filteredBrugklassen">
                        {{totalClassCapacity(category)}}
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</main>

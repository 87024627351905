import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {delay, map, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Subscription} from 'rxjs';
import {updateLinks} from '../../shared/Utils';

export interface Article {
  id: number;
  slug: string;
  title: string;
  image: string;
  content: BlockContent[];
  category: {
    article_count: number;
    name: string,
    slug: string,
    listed: boolean,
    parent_category: {
      name: string,
      listed: boolean
    }
  };
}

export interface BlockContent {
  content_type: 'BANNER' | 'MEDIA_LEFT_TEXT_RIGHT' | 'MEDIA_RIGHT_TEXT_LEFT' | 'TEXT_LEFT' | 'TEXT_RIGHT';
  media_type: string | null;
  title: string | null;
  text_content: string | null;
  media_content: string | null;
  order: number;
  style: boolean;
  id: string;
}

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {
  article: Article;

  subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    const fragSub = this.route.fragment.pipe(
        delay(500)
    ).subscribe((id: string) => {
        const scrollTarget = document.getElementById(id);
        if (scrollTarget) {
            scrollTarget.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    });
    this.subs.add(fragSub);

    const paramsSub = this.route.params.pipe(
      switchMap(params => this.http.get<{article: Article}>(environment.api + `/article/${params.slug}`)),
      map(articleContainer => {
        return {
          ...articleContainer.article,
          image: articleContainer.article.image ?? '/assets/img/unavailable.jpg'
        };
      })
    ).subscribe(article => {
      for (const blockContent of article.content) {
        if (blockContent.media_content == null) {
          blockContent.media_content = '/assets/img/unavailable.jpg';
        }
      }

      this.article = article;

      setTimeout(() => {
        // @ts-expect-error jerome stuff
        initJeromeJs();
        updateLinks();

        // @ts-expect-error jerome stuff
        $('.js-mh').matchHeight();

      }, 0);
    });

    this.subs.add(paramsSub);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}

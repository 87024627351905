<main class="main-content theme-mbo">

  <section class="section section-category-header">
    <div class="st-inner">
        <lottie-player
                src="/assets/img/svg/category/mbo/2.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>

      <div class="container">

        <div class="st-content">

            <div class="scroll-container" (window:scroll)="onScroll($event)">
                <ng-lottie [options]="lottieOptions" width="100%" height="100%"></ng-lottie>
            </div>

          <h1 class="page-title h2 split-title">
            <span class="line-1 f1 mb-0">Ik zoek </span>
            <span class="line-2 h1 t-lower">middelbaar beroepsonderwijs</span>
          </h1>
        </div>


        <div class="school-filter type-b">
          <div>
            <div class="checkboxes">
              <label class="frm-checkbox" *ngFor="let domain of domains">
                <input type="checkbox" name="soort[]" [value]="domain.id" (change)="change($event)" />
                {{domain.name}}
              </label>
            </div>

            <div class="search-action" *ngIf="domains">
              <div class="button-help-wrapper">
                <a routerLink="/categorie/mbo/map" [queryParams]="getQueryParams()" class="button">Zoeken <i class="icon-right-small"></i></a>
                <span class="help">U kunt meerdere richtingen selecteren</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-category-article">
    <div class="st-inner">
        <lottie-player
                src="/assets/img/svg/category/mbo/4.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>
      <div class="container">

        <div class="col-right">
          <figure class="st-image bgimg" style="background-image: url('/assets/img/photos/middelbaar-beroepsonderwijs.jpg');">
            <span class="overlay"></span>
            <img src="/assets/img/photos/middelbaar-beroepsonderwijs.jpg" alt="Alternatieve beschrijving" />
          </figure>
        </div>

        <div class="col-left indent">
          <h2 class="h2">Een mbo-school kiezen</h2>
          <div class="article-output">
            <p>Het middelbaar beroepsonderwijs (mbo) is praktijkgericht onderwijs, met een mix van theoretische en praktijkgerichte lessen. Afhankelijk van vooropleiding, leeftijd, ervaring of interesse is voor iedereen wel een passende opleiding te vinden. Er zijn verschillende soorten mbo-scholen; van kleine vakscholen en ‘groene scholen’ tot grote scholen met meerdere locaties. In de Scholenwijzer Den Haag vind je alle mbo-scholen in de regio. Ook vind je informatie over het maken van een studiekeuze en hoe je je kunt aanmelden bij de mbo-school van jouw keuze.</p>
          </div>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-category-articles">
    <div class="st-inner">
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Alles over </span>
            <span class="line-2 h1">middelbaar beroepsonderwijs</span>
          </h2>
        </div>

        <app-sub-category-list subCategorySlug="MBO"></app-sub-category-list>

      </div>
    </div>
  </section>


  <section class="section section-category-faq" *ngIf="faqs && faqs.length">
    <div class="st-inner">
      <span class="before"></span>
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Veelgestelde vragen over </span>
            <span class="line-2 h1">middelbaar beroepsonderwijs</span>
          </h2>
        </div>

        <div class="col-left indent">
          <div class="c-faq-links">
            <a href="{{faq.link}}" class="question" *ngFor="let faq of faqs">{{faq.question}} <span class="arrow"><i class="icon-right-small"></i></span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>

</main>

export interface SchoolChoiceContent {
    twoYear: {
        mainTitle: string;
        subTitle: string;
        choosingSchool: {
            title: string;
            description: string;
        },
        anyQuestions: {
            title: string;
            description: string;
        };
    };

    threeYear: {
        mainTitle: string;
        subTitle: string;
        enrolmentForm: {
            title: string;
            description1: string;
            description2: string;
            description3: string;
        },
        needHelp: {
            title: string;
            description1: string;
            description2: string;
        };
    };
    bottomText: string;
    bottomTextSep: string;
    figCaptionQuote: string;
    figCaptionRef: string;
}

export const maContent: SchoolChoiceContent = {
    twoYear: {
        mainTitle: 'تهانينا – (كاد) طفلك يبلغ عامه الثاني!',
        subTitle: 'هذا وقت مناسب للبدء في التفكير بشأن أفضل مدرسة ابتدائية لطفلك. اختيار مدرسة ابتدائية عملية سهلة، وهي على النحو التالي.',
        choosingSchool: {
            title: 'اختيار مدرسة ابتدائية',
            description: 'هناك أكثر من 150 مدرسة ابتدائية في لاهاي. يمكنك العثور عليهم جميعًا من خلال الرابط التالي',
        },
        anyQuestions: {
            title: 'هل لديك أسئلة؟',
            description: 'يرجى إرسال رسالة إلكترونية إلى مكتب خدمة أولياء الأمور على العنوان التالي',
        },
    },

    threeYear: {
        mainTitle: 'تهانينا – (كاد) طفلك يبلغ عامه الثالث!',
        subTitle: 'هذا يعني أنه يجب عليك إلحاق طفلك بمدرسة ابتدائية. تسجيل طفلك في المدرسة الابتدائية عملية سهلة، وهي على النحو التالي. ',
        enrolmentForm: {
            title: 'نموذج التسجيل',
            description1: 'في هذا المظروف، ستجد نموذج التسجيل. يرجى إكمال هذا النموذج وإرساله إلى المدرسة الابتدائية التي تختارها.',
            description2: 'في لاهاي، تتاح لكل الأطفال فرص متكافئة في الحياة. ولذلك نستخدم إجراءً خاصًا بالتسجيل. ويطلق عليه <em>AanmeldenPO</em> يرجى تسجيل طفلك في أثناء فترة التسجيل الصحيحة. هذا مهم للغاية!',
            description3: 'للحصول على معلومات إضافية بخصوص هذا الإجراء، رجاءً التوجه إلى موقع الويب.'
        },
        needHelp: {
            title: 'هل تحتاج إلى مساعدة؟',
            description1: 'يمكنك الحصول على معلومات إضافية على موقع الويب',
            description2: 'إذا لم تجد الإجابة عن أسئلتك على موقع الويب المذكور، يرجى إرسال رسالة إلكترونية إلى مكتب خدمة أولياء الأمور على العنوان التالي'
        },
    },
    bottomText: 'الإعداد للمدرسة الابتدائية تُشكل خطوة الانتقال إلى المدرسة الابتدائية خطوة كبيرة بالنسبة لفئة كبيرة من الأطفال. ولهذه الشريحة من الأطفال، توجد مرحلة ما قبل المدرسة. وهي عبارة عن مكان يذهب إليه الأطفال الذين تتراوح أعمارهم بين 2.5 و4 سنوات للعب والتعلم. المزيد من المعلومات على موقع',
    bottomTextSep: '',
    figCaptionQuote: 'يستحق طفلك أفضل تعليم. اختر المدرسة الأفضل لطفلك.',
    figCaptionRef: 'هيلبرت بريديميجير، عضو المجلس المحلي للتعليم',
};

export const enContent: SchoolChoiceContent = {
    twoYear: {
        mainTitle: 'Congratulations – your child is (almost) 2!',
        subTitle: 'This is a good time to start thinking about the best primary school for your child. Choosing a primary school is easy. This is how it works.',
        choosingSchool: {
            title: 'Choosing a primary school',
            description: 'There are more than 150 primary schools in The Hague. You can find them all at',
        },
        anyQuestions: {
            title: 'Any questions?',
            description: 'Please send an email to the Parent Service Desk at',
        },
    },

    threeYear: {
        mainTitle: 'Congratulations – your child is (almost) 3!',
        subTitle: 'That means it’s time to enrol your child in primary school. Enrolling your child in a primary school is easy. This is how it works.',
        enrolmentForm: {
            title: 'Enrolment form',
            description1: 'In this envelope, you’ll find an enrolment form. Please complete this form and send it to the primary school of your first choice.',
            description2: 'In The Hague, all children have the same chances in life. That’s why we use an enrolment procedure. It’s called <em>AanmeldenPO</em>. Please enrol your child during the right enrolment period. This is very important!',
            description3: 'For more information about this procedure, please visit the website'
        },
        needHelp: {
            title: 'Need help?',
            description1: 'You can find more information on',
            description2: 'If you can’t find the answer to your question there, please send an email to the Parent Service Desk at'
        },
    },
    bottomText: 'Preparing for primary school. For a large group of children, the step up to primary school is still a big one. To help them, they can attend preschool: a place of additional playing and learning for children aged between 2.5 and 4. Go to',
    bottomTextSep: 'for more information.',
    figCaptionQuote: 'Your child deserves the best education. Choose the school that is best for your child.',
    figCaptionRef: 'Hilbert Bredemeijer, alderman for education',
};

export const poContent: SchoolChoiceContent = {
    twoYear: {
        mainTitle: 'Hurra! Twoje dziecko ma już (prawie) 2 lata!',
        subTitle: 'To dobry moment, aby znaleźć dla niego szkołę podstawową, która będzie dla niego najodpowiedniejsza! Jak wybrać szkołę podstawową dla swojego dziecka?',
        choosingSchool: {
            title: 'Wybór szkoły podstawowej',
            description: 'W Hadze działa ponad 150 szkół podstawowych. Ich listę znajdziesz na stronie:',
        },
        anyQuestions: {
            title: 'Masz dalsze pytania?',
            description: 'Napisz e-mail do Biura Informacji dla Rodziców pod adres',
        },
    },

    threeYear: {
        mainTitle: 'Hurra! Twoje dziecko ma już (prawie) 3 lata!',
        subTitle: 'A więc przyszedł czas, by zapisać je do szkoły podstawowej. Jak zapisać dziecko do szkoły podstawowej?',
        enrolmentForm: {
            title: 'Formularz zgłoszenia',
            description1: 'W kopercie znajdziesz formularz, który służy do zgłoszenia dziecka do szkoły. Wypełnij go i przekaż do szkoły podstawowej pierwszego wyboru.',
            description2: 'Aby wszystkie dzieci w Hadze miały równe szanse na dostanie się do wybranej szkoły, korzystamy z procedury zgłoszeniowej <em>AanmeldenPO</em>. To bardzo ważne, by zgłosić swoje dziecko w odpowiednim okresie zgłoszeniowym.',
            description3: 'Więcej informacji o procedurze zgłoszeniowej znajdziesz na stronie'
        },
        needHelp: {
            title: 'Potrzebujesz pomocy?',
            description1: 'Potrzebne informacje znajdziesz na stronie',
            description2: 'Jeśli nie znajdziesz tam odpowiedzi na swoje pytanie, napisz e-mail do Biura Informacji dla Rodziców pod adres'
        },
    },
    bottomText: 'Przygotowanie do szkoły podstawowej. Dla dużej grupy dzieci przejście do szkoły podstawowej to wciąż duży krok. Dla nich jest przedszkole z programem edukacji wczesnoszkolnej. Miejsce, w którym dzieci w wieku od 2,5 do 4 lat uczą się i bawią. Więcej informacji znajduje się na stronie',
    bottomTextSep: '.',
    figCaptionQuote: 'Twoje dziecko zasługuje na najlepszą edukację. Wybierz szkołę, która będzie dla niego najodpowiedniejsza.',
    figCaptionRef: 'Radny ds. edukacji, Hilbert Bredemeijer',
};

export const roContent: SchoolChoiceContent = {
    twoYear: {
        mainTitle: 'Felicitări – copilul tău are (aproape) 2 ani!',
        subTitle: 'Este momentul potrivit să începi să te gândești la o școală primară pentru copilul tău. E ușor să alegi o școală primară. Iată cum.',
        choosingSchool: {
            title: 'Alegerea unei școli primare',
            description: 'În Haga există peste 150 de școli primare. Le găsești pe toate la adresa',
        },
        anyQuestions: {
            title: 'Ai întrebări?',
            description: 'Trimite un e-mail către Departamentul de servicii pentru părinți, la',
        },
    },

    threeYear: {
        mainTitle: 'Felicitări – copilul tău are (aproape) 3 ani!',
        subTitle: 'Adică e momentul să-ți înscrii copilul într-o școală primară. Înscrierea copilului tău la o școală primară se face ușor. Iată cum.',
        enrolmentForm: {
            title: 'Formular de înscriere',
            description1: 'În acest plic găsești un formular de înscriere. Completează formularul și trimite-l la școala primară pe care ai ales-o.',
            description2: 'Fiecare copil din orașul Haga are aceleași oportunități în viață. De aceea am stabilit o procedură de înscriere. Se cheamă <em>AanmeldenPO</em>. Înscrie copilul în perioada stabilită. Este foarte important!',
            description3: 'Pentru mai multe informații despre această procedură, accesează site-ul web'
        },
        needHelp: {
            title: 'Ai nevoie de ajutor?',
            description1: 'Găsești mai multe informații la',
            description2: 'Dacă nu găsești acolo răspunsul la întrebările tale, trimite un e-mail la Departamentul de servicii pentru părinți, la'
        },
    },
    bottomText: 'Pregătirea pentru școala primară. Pentru un grup mare de copii, pasul către școala primară este încă mare. Pentru ei există grădinița. Un loc în care copiii cu vârste cuprinse între 2,5 și 4 ani au parte de un plus de joacă și învățare. Mai multe informații la',
    bottomTextSep: '.',
    figCaptionQuote: 'Copilul tău merită cea mai bună educație. Alege școala care se potrivește cel mai bine copilului tău.',
    figCaptionRef: 'Hilbert Bredemeijer, consilier municipal pe probleme de educație',
};

export const tuContent: SchoolChoiceContent = {
    twoYear: {
        mainTitle: 'Yaşasın, çocuğunuz (neredeyse) 2 yaşında!',
        subTitle: 'Hangi okulun çocuğunuza uygun olduğunu değerlendirmek için iyi bir zaman! Bir ilköğretim okulu seçmek: bu husus nasıl geçekleşir?',
        choosingSchool: {
            title: 'Bir ilköğretim okulu seçimi',
            description: 'Den Haag\'ta 150\'den fazla ilköğretim okulu vardır. Tüm ilköğretim okullarının listelenmiş olduğu web sitesi adresi şudur',
        },
        anyQuestions: {
            title: 'Sorularınız mı var?',
            description: 'O halde <a href="mailto:ouders@aanmeldenpo.nl">ouders@aanmeldenpo.nl</a> e-postası aracılığıyla Ouder Servicedesk (Ebeveyn Hizmet Masası) ile iletişime geçin.',
        },
    },

    threeYear: {
        mainTitle: 'Yaşasın, çocuğunuz (neredeyse) 3 yaşında!',
        subTitle: 'Bu durum, çocuğunuzu bir ilköğretim okuluna kaydetmeniz gerektiği anlamına gelir. Bir ilköğretim okuluna kaydetmek: işte bu şekilde gerçekleşir!',
        enrolmentForm: {
            title: 'Kayıt formu',
            description1: 'Bu zarfın içinde bir kayıt formu bulacaksınız. Bu formu doldurmalı ve ilk tercihiniz olan ilköğretim okuluna teslim etmelisiniz.',
            description2: 'Den Haag&apos;taki her çocuk eşit fırsatlara sahiptir. Bu nedenle bir kayıt prosedürü (süreci) ile çalışıyoruz: <em>AanmeldenPO</em>. Çocuğunuzu doğru kayıt döneminde kaydedin. Bu çok önemlidir! ',
            description3: 'Bu prosedür hakkında daha fazla bilgi edinmek ister misiniz? Şu adresli web sitesini ziyaret ediniz'
        },
        needHelp: {
            title: 'Yardıma ihtiyacınız mı var?',
            description1: 'Daha fazla bilgiyi <a href="https://scholenwijzer.denhaag.nl/">scholenwijzer.denhaag.nl</a> adresinde bulabilirsiniz.',
            description2: 'Sorunuz cevaplanmadı mı? O halde <a href="mailto:ouders@aanmeldenpo.nl">ouders@aanmeldenpo.nl</a> e-postası aracılığıyla Ouder Servicedesk (Ebeveyn Hizmet Masası) ile iletişime geçin.'
        },
    },
    bottomText: 'İlkokula hazırlık. Çocukların büyük bir kısmı için ilkokula adım hala büyük bir adımdır. Bu çocuklar için anaokulu var. 2,5 - 4 yaş arası çocukların oynayabileceği ve daha fazlasını öğrenebileceği bir yer. Daha fazla bilgi ',
    bottomTextSep: ' adresinde.',
    figCaptionQuote: 'Çocuğunuz en iyi eğitimi hak ediyor. Çocuğunuza uygun olan okulu seçin.',
    figCaptionRef: 'Eğitimle ilgili belediye meclis üyesi, Hilbert Bredemeijer',
};

<main class="main-content theme-mbo" *ngIf="location">

  <section class="section section-school-header">
    <div class="st-inner py-0">

        <lottie-player
                src="/assets/img/svg/school/mbo/1.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>

        <div class="scroll-container" (window:scroll)="onScroll($event)">
            <ng-lottie [options]="lottieOptions" width="100%" height="100%"></ng-lottie>
        </div>

      <a href="#" (click)="goBack($event)" class="page-btn-back">
        <span class="label">Terug</span>
        <i class="arrow icon-right-small"></i>
      </a>

      <div class="container">

        <div class="col-left">
          <div class="slick-wrapper">
            <span class="overlay"></span>
            <div class="slick-images">
              <div class="item bgimg js-mh" [style.background-image]="'url(' + location.image + ')'">
                <img [src]="location.image"/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-right js-mh">
          <div class="inner">

            <header class="school-header">
              <app-favorite-school [id]="location.id" [locationType]="location.location_type"></app-favorite-school>
              <h1 class="h1 title">{{location.name}}</h1>
              <div class="meta">
                <span class="meta-text">Middelbaar beroepsonderwijs</span>
              </div>
            </header>

            <div class="school-details">

              <div class="school-info">
                <div class="school-info-address">
                  <p>
                    {{location.street}} {{location.house_number}} {{location.house_number_addition}}<br>
                    {{location.zipcode}} {{location.place}}
                  </p>
                </div>
              </div>

              <ul class="school-actions">
                <li *ngIf="location.website"><a [href]="location.website" target="_blank" class="button outline btn-a">Website</a></li>
                <li *ngIf="location.phone"><a [href]="'tel:' + location.phone" class="button outline btn-b">{{location.phone}}</a></li>
                <li *ngIf="location.email"><a [href]="'mailto:' + location.email" class="button outline btn-c">Contact per mail</a></li>
                <li><a (click)="scroll(map, $event)" class="button outline btn-d">Weergeven op kaart</a></li>
              </ul>

            </div>

          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-school-article type-a" *ngIf="location.mbo_domains.length > 0">
    <div class="st-inner">
      <div class="container">

        <div class="indent c-article-list">

          <article class="article-item">
            <h2 class="article-title h2">Deze richtingen bieden wij</h2>
            <div class="article-output">
              <ng-container *ngFor="let domain of location.mbo_domains">
                <ng-container *ngIf="domain.link; else domainWithoutLink">
                  <a class="domain" [href]="domain.link" target="_blank">
                    {{domain.domain}}
                  </a>
                </ng-container>

                <ng-template #domainWithoutLink>
                  <span class="domain">{{domain.domain}}</span>
                </ng-template>
              </ng-container>


            </div>
          </article>

        </div>

<!--        <div class="col-right">-->
<!--          <figure class="st-image bgimg has-before">-->
<!--            <img src="https://scholenwijzer.blob.core.windows.net/cdn/images/mbo/%20({{randInt}}).jpg" />-->
<!--          </figure>-->
<!--        </div>-->

      </div>
    </div>
  </section>


  <section class="section section-school-cta type-a">
    <div class="st-inner">
      <div class="container">

        <div class="c-blocks">
          <div class="c-block">
            <h2 class="h2 split-title">
              <span class="line-1 f1 mb-0">Bekijk hier alle </span>
              <span class="line-2 h1 t-lower">open dagen</span>
            </h2>
            <a href="https://www.bekijkjetoekomstnu.nl/artikel/Open%20dagen%20mbo%202021-2022" target="_blank" class="button v-middle">bekijk <i class="icon-right-small"></i></a>
          </div>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-school-article type-a">
    <div class="st-inner">
      <div class="container">

        <div class="col-left indent c-article-list">

          <article class="article-item">
            <h2 class="article-title h2">Over onze school</h2>
            <div class="article-output" [innerHTML]="location.school.teaching"></div>
          </article>

        </div>

        <div class="col-right">
          <figure class="st-image bgimg has-before">
            <img src="https://scholenwijzer.blob.core.windows.net/cdn/images/mbo/%20({{randInt}}).jpg" />
          </figure>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-school-text">
    <div class="st-inner">
        <lottie-player
                src="/assets/img/svg/school/mbo/5.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>
      <div class="container">
        <div class="row">

          <div class="col-md-4 col-lg-5"></div>

          <div class="col-md-7 col-lg-6 c-article-list">

            <article class="article-item">
              <h2 class="article-title h2">Opleidingen</h2>
              <div class="article-output" [innerHTML]="location.school.educations"></div>
            </article>

            <article class="article-item">
              <h2 class="article-title h2">Bijzonderheden</h2>
              <div class="article-output" [innerHTML]="location.school.special"></div>
            </article>

          </div>

        </div>
      </div>
    </div>
  </section>


  <section class="section section-school-cta type-b cta-bekijkjetoekomst">
    <div class="st-inner">
      <div class="container">

        <div class="c-blocks">
          <div class="c-block">
            <h2 class="h2 split-title">
              <span class="line-1 f1 mb-0">Meer weten?</span>
              <span class="line-2 h1 t-lower">Bekijkjetoekomstnu.nl</span>
            </h2>
            <a href="http://www.bekijkjetoekomstnu.nl/location/{{location.id}}" target="_blank" class="button v-middle">naar <i class="icon-right-small"></i></a>
          </div>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-school-map" #map>
    <div class="gmap" id="school_gmap"></div>
  </section>


  <section class="section section-school-articles">
    <div class="st-inner">
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Alles over </span>
            <span class="line-2 h1">middelbaar beroepsonderwijs in Den Haag</span>
          </h2>
        </div>

        <app-sub-category-list subCategorySlug="MBO"></app-sub-category-list>
      </div>
    </div>
  </section>

</main>

import {Component, OnInit} from '@angular/core';
import {ActivationStart, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'website-scholenwijzer';
    activePath: string;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);

            gtag('config', 'G-8CY9V7JDG8',
                {
                    page_path: evt.urlAfterRedirects
                }
            );
        });

        this.router.events.pipe(
            filter(e => e instanceof ActivationStart)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ).subscribe((e: any) => {
            this.activePath = e.snapshot.routeConfig.path;
        });
    }
}

import {Component, Input} from '@angular/core';
import {BlockContent} from '../../article/article.component';

@Component({
  selector: 'app-text-left',
  templateUrl: './text-left.component.html',
  styleUrls: ['./text-left.component.scss']
})
export class TextLeftComponent {
  @Input() content!: BlockContent;

  constructor() { }

}

import { Component } from '@angular/core';
import {VoLocationComponent} from '../vo-location/vo-location.component';

@Component({
  selector: 'app-vavo-location',
  templateUrl: './vavo-location.component.html',
  styleUrls: ['./vavo-location.component.scss']
})
export class VavoLocationComponent extends VoLocationComponent {

}

<main class="main-content tpl-not-found">
    <section class="section section-404">
        <div class="st-inner">
            <span class="before"></span>
            <span class="after"></span>
            <div class="container">
                <div class="content">
                    <h1 class="h1">{{ "SUBMITTED.TITLE" | translate }}</h1>
                    <p>{{ "SUBMITTED.TEXT" | translate }}</p>
                    
                    <a [routerLink]="['/signup-newcomers']" class="button"><i class="arrow icon-right-small"></i>{{ "SUBMITTED.REGISTER_ANOTHER_CHILD" | translate }}</a>
                </div>
            </div>
        </div>
    </section>
</main>
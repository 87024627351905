<main class="main-content theme-so">

  <section class="section section-category-header">
    <div class="st-inner">
        <lottie-player
                src="/assets/img/svg/category/so/2.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>
      <div class="container">

        <div class="st-content">

            <div class="scroll-container" (window:scroll)="onScroll($event)">
                <ng-lottie [options]="lottieOptions" width="100%" height="100%"></ng-lottie>
            </div>

          <h1 class="page-title h2 split-title">
            <span class="line-1 f1 mb-0">Ik zoek </span>
            <span class="line-2 h1 t-lower">speciaal onderwijs</span>
          </h1>
          <div class="school-filter type-a clearfix">
            <div class="c-search clearfix">
              <input type="text" class="input" name="postcode" [(ngModel)]="zipcode" placeholder="Zoek op postcode"> <a [routerLink]="'/categorie/so/map'" [queryParams]="zipcode ? {'zipcode': zipcode} : {}">zoeken</a>
            </div>
            <a [routerLink]="['/', 'categorie', 'so', 'map']" class="match-c-search">
              <span>Zoeken zonder postcode <i class="icon-right-small"></i></span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-category-article">
    <div class="st-inner">
        <lottie-player
                src="/assets/img/svg/category/so/4.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>
      <div class="container">

        <div class="col-right">
          <figure class="st-image bgimg" style="background-image: url('/assets/img/photos/SO/SO_01.jpg');">
            <span class="overlay"></span>
            <img src="/assets/img/photos/SO/SO_01.jpg" alt="Foto speciaal onderwijs" />
          </figure>
        </div>

        <div class="col-left indent">
          <h2 class="h2">Speciaal onderwijs</h2>
          <div class="article-output">
            <p>Als een reguliere basisschool uw kind niet de ondersteuning kan bieden die het nodig heeft, dan kan uw kind worden doorverwezen naar een school die dit wel kan. Er zijn in Den Haag scholen voor speciaal basisonderwijs (sbo), voor speciaal onderwijs (so) en voortgezet speciaal onderwijs (vso).</p>

            <h2>Speciaal basisonderwijs</h2>
            <p>Het speciaal basisonderwijs (sbo) biedt onderwijs aan kinderen die zich op een reguliere basisschool niet optimaal ontwikkelen. Op een sbo-school zijn de klassen kleiner. Daardoor krijgen de kinderen meer aandacht. In Den Haag zijn er meerdere sbo-scholen.</p>
            <a [routerLink]="['/', 'categorie', 'Speciaal (basis)onderwijs']">Meer over speciaal basisonderwijs.</a>

          </div>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-category-text">
    <div class="st-inner">
      <div class="container">
        <div class="row">

          <div class="col-md-4 col-lg-5"></div>

          <div class="col-md-7 col-lg-6 c-article-list">

            <article class="article-item">

              <h2 class="article-title h2">Ondersteuning en begeleiding</h2>
              <div class="article-output">
                <p>
                  Een klein aantal kinderen heeft meer en vooral andere ondersteuning en begeleiding nodig dan de basisschool of de school voor speciaal basisonderwijs kan bieden. Voor deze kinderen zijn er scholen voor speciaal onderwijs (so). Scholen voor speciaal onderwijs (so) zijn gespecialiseerd in het geven van onderwijs en ondersteuning aan een specifieke doelgroep. Elke so-school heeft zijn eigen specialisatie.
                </p>

                <h2>Voortgezet speciaal onderwijs</h2>
                <p>Als uw kind meer ondersteuning nodig heeft dan het voortgezet onderwijs kan bieden, dan kan uw kind worden doorverwezen naar het voortgezet speciaal onderwijs (vso). Het voortgezet speciaal onderwijs is net als het speciaal basisonderwijs georganiseerd in vier clusters. Voor het vso is een toelaatbaarheidsverklaring (tlv) nodig.</p>
                <a [routerLink]="['/', 'categorie', 'Voortgezet speciaal onderwijs']">Meer over voortgezet speciaal onderwijs.</a>
              </div>
            </article>

          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="section section-category-articles">
    <div class="st-inner">
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Alles over </span>
            <span class="line-2 h1">speciaal onderwijs</span>
          </h2>
        </div>

        <app-sub-category-list subCategorySlug="SO"></app-sub-category-list>

      </div>
    </div>
  </section>


  <section class="section section-category-faq" *ngIf="faqs && faqs.length">
    <div class="st-inner">
      <span class="before"></span>
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Veelgestelde vragen over </span>
            <span class="line-2 h1">speciaal onderwijs</span>
          </h2>
        </div>

        <div class="col-left indent">
          <div class="c-faq-links">
            <a href="{{faq.link}}" class="question" *ngFor="let faq of faqs">{{faq.question}} <span class="arrow"><i class="icon-right-small"></i></span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>

</main>

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {FavouriteSchool} from '../components/favourite-school-button/favourite-school-button.component';


@Injectable({
    providedIn: 'root'
})
export class FavouriteService {
    favoriteSchools: FavouriteSchool[] = [];
    favoritesChanged$ = new Subject<FavouriteSchool[]>();

    constructor() {
        this.favoriteSchools = JSON.parse(localStorage.getItem('my-schools') || '[]');
    }

    toggleFavorite(id: number, type: string): void {
        const targetSchool: FavouriteSchool = {id, type};
        const favouriteSchools = this.getFavorites();

        const makeFavorite = favouriteSchools.find(el => el.id === targetSchool.id) === undefined;

        if (makeFavorite) {
          favouriteSchools.push(targetSchool);
        } else {
          favouriteSchools.splice(favouriteSchools.findIndex(favorite => favorite.id === targetSchool.id), 1);
        }

        // write edits to localStorage
        localStorage.setItem('my-schools', JSON.stringify(favouriteSchools));
        this.favoriteSchools = favouriteSchools;
        this.favoritesChanged$.next(this.favoriteSchools);
    }

    isFavorite(id: number): boolean {
        return !!this.favoriteSchools.find(el => el.id === id);
    }

    getFavorites(): FavouriteSchool[] {
      return JSON.parse(localStorage.getItem('my-schools') || '[]') as FavouriteSchool[];
    }
}

import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {FaqItem, FaqService} from '../../shared/services/faq.service';
import {take} from 'rxjs/operators';
import {SeoService} from '../../shared/services/seo.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Subscription} from 'rxjs';
import {AnimationOptions} from "ngx-lottie";
import lottie, {AnimationItem} from 'lottie-web';

export interface VoSettings {
    settings: VoSettingProperties
}

export interface VoSettingProperties {
    vo_first_grade_capacity_year: number
    vo_first_grade_capacity_period: number
}


@Component({
    selector: 'app-vo-category',
    templateUrl: './vo-category.component.html',
    styleUrls: ['./vo-category.component.scss']
})
export class VoCategoryComponent implements OnInit, OnDestroy, AfterViewInit {
    faqs: FaqItem[] | undefined;
    selectedValues = new Set();
    voFirstGradeCapacityPeriod: number|null = null;
    subscriptions: Subscription[] = [];

    lottieOptions: AnimationOptions = {};
    private animation: AnimationItem;

    constructor(
        private faqService: FaqService,
        private seo: SeoService,
        private http: HttpClient
    ) {
    }

    ngOnInit(): void {
        this.seo.updateTags(
            'Scholenwijzer Den Haag',
            'De Scholenwijzer geeft ouders en kinderen informatie over alle verschillende scholen in Den Haag en omstreken. Dat maakt het kiezen van een school een stuk makkelijker! En er is nog veel meer uit de Scholenwijzer te halen. Hoe weet u welke school het beste past bij u en uw kind? Waar moet u op letten? Hoe werkt het aanmelden? De Scholenwijzer geeft antwoord op al deze vragen en meer.'
        );

        this.subscriptions.push(
            this.http.get<VoSettings>(environment.api + `/app-settings`).subscribe(data => {
                this.voFirstGradeCapacityPeriod = data.settings.vo_first_grade_capacity_period;
            })
        );

        this.faqService.getFaqItemsForCategory('VO').pipe(take(1)).subscribe(faqs => {
            this.faqs = faqs;

            setTimeout(() => {
                // @ts-expect-error jerome stuff
                initJeromeJs();
            }, 0);
        });
    }

    change(event: Event): void {
        // @ts-expect-error checked should be a property of event.target
        const checked = event.target.checked;
        // @ts-expect-error value should be a property of event.target
        const values = event.target.value.split(',');

        if (checked) {
            values.forEach(v => this.selectedValues.add(v));
        } else {
            values.forEach(v => this.selectedValues.delete(v));
        }
    }

    getQueryParams() {
        const params: { education_types?: string } = {};
        if (this.selectedValues.size > 0) {
            params.education_types = [...this.selectedValues].join(',');
        }
        return params;
    }

    getTarget(link: string): string {
        if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
            return '_blank';
        }

        return '_self';
    }

    ngAfterViewInit() {
        this.animation = lottie.loadAnimation({
            container: document.querySelector('ng-lottie') as Element,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: '/assets/img/svg/category/vo/vo-icoon.json'
        });
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollPercent = scrollTop / docHeight;

        const frame = Math.min(Math.max(scrollPercent * 60 * 10, 0), 60 - 1);
        if (this.animation) {
            this.animation.goToAndStop(frame, true);
        }
    }


    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}

<main class="main-content theme-vo" *ngIf="location">

    <section class="section section-school-header">
        <div class="st-inner py-0">
            <lottie-player
                    src="/assets/img/svg/school/vo/1.json"
                    renderer="svg"
                    class="lottie-player"
                    autoplay="true"
                    loop="true">
            </lottie-player>
            <span class="after"></span>

            <a href="#" (click)="goBack($event)" class="page-btn-back">
                <span class="label">Terug</span>
                <i class="arrow icon-right-small"></i>
            </a>

            <div class="container">

                <div class="col-left">
                    <div class="slick-wrapper">
                        <span class="overlay"></span>
                        <div class="slick-images">
                            <div class="item bgimg js-mh" [style.background-image]="'url(' + location.image + ')'">
                                <img [src]="location.image"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-right js-mh">
                    <div class="inner">
                        <header class="school-header">
                            <app-favorite-school [id]="location.id" [locationType]="'VO'"></app-favorite-school>
                            <h1 class="h1 title">{{location.name}}</h1>
                            <div class="meta">
                                <ng-container *ngIf="location.education_field">
                                    <span class="meta-text">{{location.education_field}}</span>
                                    <span class="divider"> | </span>
                                </ng-container>
                                <span class="meta-text">Voortgezet Onderwijs</span>
                            </div>
                        </header>

                        <div class="school-details">
                            <div class="school-info">
                                <div class="school-education-types">
                                    {{joinedEducationTypes()}}
                                </div>
                                <div class="school-info-address">
                                    <p>
                                        {{location.street}} {{location.house_number}} {{location.house_number_addition}}<br>
                                        {{location.zipcode}} {{location.place}}
                                    </p>
                                </div>
                                <div class="school-info-brin">
                                    <strong>Brin </strong><span class="brin-code">{{location.brin_id}}</span>
                                </div>
                            </div>

                            <ul class="school-actions">
                                <li><a [href]="location.website" target="_blank" class="button outline btn-a">Website</a></li>
                                <li><a [href]="'tel:' + location.phone" class="button outline btn-b">{{location.phone}}</a></li>
                                <li><a [href]="'mailto:' + location.email" class="button outline btn-c">Contact per mail</a></li>
                                <li><a (click)="scroll(map, $event)" class="button outline btn-d">Weergeven op kaart</a></li>
                            </ul>

                        </div>

                        <div class="school-attributes">
                            <div class="attribute" *ngIf="location.has_open_days">
                                <div class="key">Open dagen</div>
                                <div class="value"><a href="#" (click)="scroll(openDays, $event)">Ja <i class="icon-right-small"></i></a></div>
                            </div>
                            <div class="attribute" *ngIf="precedenceRulesArray().length && location.period === 1">
                                <div class="key">Voorrangsregels</div>
                                <div class="value"><a href="#" (click)="scroll(precedenceRules, $event)">Ja <i class="icon-right-small"></i></a></div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </section>


    <section class="section section-school-article type-a">
        <div class="st-inner">
            <div class="container">

                <div class="col-left indent c-article-list">

                    <article class="article-item">
                        <h2 class="article-title h2">Wie zijn wij</h2>
                        <div class="article-output" [innerHTML]="location.about_us"></div>
                    </article>

                    <article class="article-item">
                        <h2 class="article-title h2">Over onze school</h2>
                        <div class="article-output" [innerHTML]="location.education_concept"></div>
                    </article>

                </div>

                <div class="col-right">
                    <figure class="st-image bgimg has-before">
                        <img alt="voortgezet onderwijs afbeelding" src="https://scholenwijzer.blob.core.windows.net/cdn/images/vo/({{randInt[0]}}).jpg"/>
                    </figure>
                </div>

            </div>
        </div>
    </section>


    <section class="section section-school-cta">
        <div class="st-inner">
            <div class="container">

                <div class="slick-open-days-outer">
                    <div class="slick-open-days">
                        <div class="item" *ngFor="let openDay of location.open_days">
                            <div class="inner js-mh">
                                <div class="date">{{openDay.start | date:'dd MMMM yyyy'}}</div>
                                <div class="type">Voorlichting</div>
                                <div class="time">{{openDay.start | date:'HH:mm'}} - {{openDay.end | date:'HH:mm'}} </div>
                                <div class="info"><p [innerHTML]="openDay.description"></p></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="c-blocks" #openDays>
                    <div class="c-block single-block">
                        <h2 class="h2 split-title">
                            <span class="line-1 f1 mb-0">Bekijk hier alle </span>
                            <span class="line-2 h1 t-lower">open dagen</span>
                        </h2>
                        <a routerLink="/vo/open-dagen" class="button v-middle">Open dagen <i class="icon-right-small"></i></a>
                    </div>
                </div>

            </div>
        </div>
    </section>


    <section class="section section-school-text">
        <div class="st-inner">
            <div class="container">
                <div class="row">

                    <div class="col-md-4 col-lg-5"></div>

                    <div class="col-md-7 col-lg-6 c-article-list">

                        <article class="article-item">
                            <h2 class="article-title h2">Uw kind aanmelden</h2>
                            <div class="article-output" [innerHTML]="location.signup_description"></div>
                        </article>

                        <div #precedenceRules></div>
                        <article class="article-item" *ngIf="precedenceRulesArray().length && location.period === 1">
                            <h2 class="article-title h2">Voorrangsregels</h2>
                            <div class="article-output">
                                <ol>
                                    <li *ngFor="let rule of precedenceRulesArray()">
                                        {{rule.rule}} <br>
                                        {{rule.explanation}}
                                    </li>
                                </ol>
                                <small>In de tweede ronde van aanmelden en de restronde gelden er geen voorrangsregels meer.</small>
                            </div>
                        </article>

                        <article class="article-item c-kostenleerjaar">
                            <h2 class="article-title h2">Kosten eerste leerjaar</h2>
                            <div class="article-output">
                                <div *ngFor="let expense of expenses" class="cost">
                                    <ng-container *ngIf="expense.category">
                                        <h4>{{categoryInfo[expense.category].name}}</h4>
                                        <p class="extra" *ngIf="categoryInfo[expense.category].extra">
                                            {{categoryInfo[expense.category].extra}}
                                        </p>
                                    </ng-container>

                                    <table class="expense-table">
                                        <tbody>
                                        <tr>
                                            <td>Bedrag</td>
                                            <td><strong>{{expense.costs | currency:'EUR'}}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Beschrijving</td>
                                            <td>
                                                <span [innerHTML]="expense.explanation"></span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </article>

                    </div>

                </div>
            </div>
        </div>
    </section>


    <section class="section section-school-article type-b">
        <div class="st-inner">
            <div class="container">

                <div class="col-left indent c-article-list">

                    <article class="article-item">
                        <h2 class="h2">Meer informatie</h2>
                        <div class="article-output" [innerHTML]="location.more_information"></div>
                    </article>

                    <article class="article-item" *ngIf="location.properties.length > 0">
                        <h2 class="article-title h2">Bijzonderheden</h2>
                        <ul class="c-tiplist">
                            <li *ngFor="let property of location.properties">
                                - {{property.name}}
                            </li>
                        </ul>
                    </article>

                    <article class="article-item" *ngIf="location.scholenopdekaart_link">
                        <h2 class="h3 line-1 f1 mb-0">Meer weten over deze school?</h2>
                        <h3 class="h2 line-2 t-lower">Scholenopdekaart.nl</h3>
                        <a href="{{location.scholenopdekaart_link ? location.scholenopdekaart_link : 'https://scholenopdekaart.nl'}}" target="_blank" class="button v-middle">Naar de website <i class="icon-right-small"></i></a>
                    </article>

                </div>

                <div class="col-right">
                    <figure class="st-image bgimg has-before">
                        <img alt="voortgezet onderwijs afbeelding" src="https://scholenwijzer.blob.core.windows.net/cdn/images/vo/({{randInt[1]}}).jpg"/>
                    </figure>
                </div>

            </div>
        </div>
    </section>


    <section class="section section-school-map" #map>
        <div class="gmap" id="school_gmap"></div>
    </section>


    <section class="section section-school-articles">
        <div class="st-inner">
            <div class="container">

                <div class="st-header indent">
                    <h2 class="h2 split-title">
                        <span class="line-1 f1 mb-0">Alles over </span>
                        <span class="line-2 h1">middelbaar onderwijs in Den Haag</span>
                    </h2>
                </div>

                <app-sub-category-list subCategorySlug="VO"></app-sub-category-list>

            </div>
        </div>
    </section>

</main>

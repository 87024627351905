import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {take} from 'rxjs/operators';

interface SubCategory {
  name: string;
  image: string;
  slug: string;
}

@Component({
  selector: 'app-sub-category-list',
  templateUrl: './sub-category-list.component.html',
  styleUrls: ['./sub-category-list.component.scss']
})
export class SubCategoryListComponent implements OnInit {
  @Input() subCategorySlug: string;

  subCategories: SubCategory[] = [];

  constructor(
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.http.get<{ subCategories: SubCategory[] }>(environment.api + `/categories/${this.subCategorySlug}`)
      .pipe(take(1))
      .subscribe(httpResult => {
        for (const subCategory of httpResult.subCategories) {
          if (!subCategory.image) {
            subCategory.image = '/assets/img/unavailable.jpg';
          }
        }

        this.subCategories = httpResult.subCategories;
        this.initSlick();
      });
  }

  initSlick(): void {
    setTimeout(() => {
      // @ts-expect-error jerome stuff
      $('.slick-articles').slick({
        autoplay: false,
        infinite: true,
        arrows: false,
        dots: true,
        fade: false,
        autoplaySpeed: 5000,
        speed: 800,
        slidesToShow: this.subCategories.length,
        slidesToScroll: 4,
        pauseOnHover: false,
        pauseOnFocus: false,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              speed: 300,
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              speed: 300,
            }
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              speed: 300,
            }
          }
        ]
      });
    }, 0);
  }

}

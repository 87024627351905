<main class="main-content">
    <section class="section">
        <div class="st-inner">
            <span class="before"></span>
            <div class="container">
                <div class="content">

                    <h1>COLOFON</h1>

                    <p>De Scholenwijzer is een samenwerkingsproject van de gemeente Den Haag met alle besturen van kinderopvangorganisaties, primair en voortgezet onderwijs, het mbo, hbo en wo, de verschillende HEA programma’s voor schoolkeuze (AanmeldenPO, BOVO Haaglanden en Spirit4You) en het samenwerkingsverband vo Passend Onderwijs Zuid-Holland West. Samen hebben zij hun best gedaan voor een volledige en juiste inhoud van deze website. Mocht u toch nog iets tegenkomen wat volgens u niet klopt, dan horen wij dat graag:</p>
                    <p>
                        Ouders kunnen een mail sturen naar:  <a href="mailto: onderwijsbeleid@denhaag.nl">onderwijsbeleid@denhaag.nl</a><br>
                        Scholen kunnen een mail sturen naar: <a href="mailto: servicedesk@wegwijzerdenhaag.nl">servicedesk@wegwijzerdenhaag.nl</a>
                    </p>

                </div>
            </div>
        </div>
    </section>
</main>

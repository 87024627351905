<main class="main-content">

    <section class="section section-mijn-scholen">

        <div class="st-inner" *ngIf="!hasFavoriteSchools">
            <a href="#" (click)="goBack($event)" class="page-btn-back">
                <span class="label">Terug</span>
                <i class="arrow icon-right-small"></i>
            </a>

            <div id="no-schools" class="container clearfix">
                <h4>Je hebt nog geen scholen geselecteerd.</h4>
            </div>
        </div>

        <div class="st-inner" [style.display]="hasFavoriteSchools && locations.length > 0 ? 'block' : 'none'">
            <a href="#" (click)="goBack($event)" class="page-btn-back">
                <span class="label">Terug</span>
                <i class="arrow icon-right-small"></i>
            </a>

            <div class="title-top">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <h1>Opgeslagen locaties</h1>
                        </div>
                        <div class="col-md-6">
                            <div class="button-wrapper">
                                <div class="inner">
                                    <a [href]="" class="button outline" (click)="scrollToMap()">Bekijk locaties op de kaart</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="button-bar">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="category-select" *ngIf="selectValues.length > 1">
                                <a href="#" class="button outline" (click)="selectCategory(value.id, $event)" [class.active]="value.id === categorySelect.value" *ngFor="let value of selectValues">{{value.title}}</a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="">
                                <div class="inner right" *ngIf="locations.length > 0">
                                    <a *ngIf="!shareSchools" (click)="share()" class="button outline share-button">Delen <span class="icon-share"></span></a>
                                    <a [href]="getUrl()" class="button outline">Downloaden <span class="icon-download"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <ng-template appSchoolListHost></ng-template>

                        <div class="button-wrapper">
                            <div class="inner centered">
                                <a *ngIf="!shareSchools" (click)="share()" class="button outline share-button">Delen <span class="icon-share"></span></a>
                                <a [href]="getUrl()" class="button outline">Downloaden <span class="icon-download"></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <div class="container" [style.display]="hasFavoriteSchools && locations.length > 0 ? 'block' : 'none'">
        <div class="row">
            <div class="col-md-6">
                <h1>Op de kaart</h1>
                <div class="button-wrapper">
                    <div class="inner">
                        <div class="c-search clearfix">
                            <input [formControl]="zipCodeInput" type="text" class="input" name="postcode" placeholder="Zoek op postcode" (keydown.enter)="postCodeSearchClick$.next()"> <button (click)="postCodeSearchClick$.next()">zoeken</button>
                        </div>
                        <p *ngIf="zipcodeNotFound" class="zipcode-error">Postcode niet gevonden</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="map" [style.display]="hasFavoriteSchools && locations.length > 0 ? 'block' : 'none'">
        <div class="finder-gmap" id="favorite-gmap"></div>
    </div>

</main>

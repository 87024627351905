import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {combineLatest, EMPTY, Observable, Subject, Subscription} from 'rxjs';
import {notNull} from '../shared/Utils';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, filter, map, switchMap} from 'rxjs/operators';

export enum LanguageChoice {
    DUTCH = 'nl',
    ENGLISH = 'en',
    UKRAINIAN = 'uk'
}

export enum AgeCategory {
    PO = '4-11 years old',
    VO = '12-18 years old'
}

export enum BinaryChoice {
    YES = 'yes',
    NO = 'no'
}

export enum Sex {
    BOY = 'boy',
    GIRL = 'girl',
    OTHER = 'other'
}

export enum DisabilitySupport {
    NONE = 'NONE',
    PHYSICAL_LIMITATION = 'PHYSICAL_LIMITATION',
    DEVELOPMENTAL_DELAY = 'DEVELOPMENTAL_DELAY',
    BEHAVIORAL_PROBLEMS = 'BEHAVIORAL_PROBLEMS',
    CHRONIC_DISEASE = 'CHRONIC_DISEASE',
    OTHER = 'OTHER'
}

export interface DisabilitySupportOption {
    label: string;
    value: DisabilitySupport;
}

@Component({
    selector: 'app-refugee-questionnaire',
    templateUrl: './refugee-questionnaire.component.html',
    styleUrls: ['./refugee-questionnaire.component.scss'],
})
export class RefugeeQuestionnaireComponent implements OnInit, OnDestroy {
    questionnaire = new UntypedFormGroup({
        age_category: new UntypedFormControl(null, Validators.required),
        ukrainian_background: new UntypedFormControl(null, Validators.required),

        first_name: new UntypedFormControl('', Validators.required),
        last_name: new UntypedFormControl('', Validators.required),
        date_of_birth: new UntypedFormControl('', Validators.required),
        sex: new UntypedFormControl(null, Validators.required),

        already_in_netherlands: new UntypedFormControl(null, Validators.required),
        arrival_date: new UntypedFormControl(null),

        disability_support: new UntypedFormControl([DisabilitySupport.NONE], Validators.required),
        disability_support_explanation_note: new UntypedFormControl(''),

        parent_first_name: new UntypedFormControl('', Validators.required),
        parent_last_name: new UntypedFormControl('', Validators.required),
        parent_email: new UntypedFormControl('', [Validators.required, Validators.email]),
        parent_phone_number: new UntypedFormControl('', Validators.required),

        contact_first_name: new UntypedFormControl(''),
        contact_last_name: new UntypedFormControl(''),
        contact_email: new UntypedFormControl('', Validators.email),
        contact_phone_number: new UntypedFormControl(''),

        street_name: new UntypedFormControl('', Validators.required),
        house_number: new UntypedFormControl('', [Validators.required]),
        postal_code: new UntypedFormControl('', [Validators.required, Validators.pattern('^\\d{4}\\s?[a-zA-Z]{2}$')]),
        city: new UntypedFormControl('', Validators.required),

        new_street_name: new UntypedFormControl(''),
        new_house_number: new UntypedFormControl(''),
        new_postal_code: new UntypedFormControl('', Validators.pattern('^\\d{4}\\s?[a-zA-Z]{2}$')),
        new_city: new UntypedFormControl(''),

        residence_change: new UntypedFormControl(null, Validators.required),
        residence_change_explanation: new UntypedFormControl(''),

        language: new UntypedFormControl([], Validators.required),

        interpreter_available: new UntypedFormControl(null, Validators.required),
        language_other: new UntypedFormControl(''),

        other_remarks: new UntypedFormControl(''),

        captcha: new UntypedFormControl(null, Validators.required)
    });

    showIskRedirect = false;

    residenceChange = false;
    LanguageChoice = LanguageChoice;
    AgeCategory = AgeCategory;
    BinaryChoice = BinaryChoice;
    Sex = Sex;
    selectedLanguage = 'nl';
    selectedSpokenLanguages = new Set<string>();

    disabilitySupportOptions: { [key in DisabilitySupport]: DisabilitySupportOption } | null = null;
    disabilitySupportOptionsArray: DisabilitySupportOption[] = [];

    submit$ = new Subject<void>();
    private subscriptions: Subscription[] = [];

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.url.pipe(
                map((url: UrlSegment[]) => url[1].path),
                switchMap((language: string) => {
                    this.selectedLanguage = language;
                    return this.translate.use(language);
                }),
                switchMap(() => this.translate.get([
                    'NONE',
                    'PHYSICAL_LIMITATION',
                    'DEVELOPMENTAL_DELAY',
                    'BEHAVIORAL_PROBLEMS',
                    'CHRONIC_DISEASE',
                    'OTHER'
                ]))
            ).subscribe((_) => {
                this.setDisabilitySupportOptions();
            }),

            this.addressAutoFiller(
                this.questionnaire.get('postal_code'),
                this.questionnaire.get('house_number'),
                this.questionnaire.get('street_name'),
                this.questionnaire.get('city')),
            this.addressAutoFiller(
                this.questionnaire.get('new_postal_code'),
                this.questionnaire.get('new_house_number'),
                this.questionnaire.get('new_street_name'),
                this.questionnaire.get('new_city')),

            notNull(
                this.questionnaire.get('residence_change')
            ).valueChanges.subscribe(value => {
                this.residenceChange = value === 'yes';
                if (value === 'yes') {
                    this.questionnaire.get('new_street_name')?.setValidators([Validators.required]);
                    this.questionnaire.get('new_house_number')?.setValidators([Validators.required]);
                    this.questionnaire.get('new_postal_code')?.setValidators([Validators.required, Validators.pattern('^\\d{4}\\s?[a-zA-Z]{2}$')]);
                    this.questionnaire.get('new_city')?.setValidators([Validators.required]);
                } else if (value === 'no') {
                    this.questionnaire.get('new_street_name')?.clearValidators();
                    this.questionnaire.get('new_house_number')?.clearValidators();
                    this.questionnaire.get('new_postal_code')?.clearValidators();
                    this.questionnaire.get('new_city')?.clearValidators();

                    this.questionnaire.get('new_street_name')?.setValue('');
                    this.questionnaire.get('new_house_number')?.setValue('');
                    this.questionnaire.get('new_postal_code')?.setValue('');
                    this.questionnaire.get('new_city')?.setValue('');
                }

                this.questionnaire.get('new_street_name')?.updateValueAndValidity();
                this.questionnaire.get('new_house_number')?.updateValueAndValidity();
                this.questionnaire.get('new_postal_code')?.updateValueAndValidity();
                this.questionnaire.get('new_city')?.updateValueAndValidity();this.questionnaire.updateValueAndValidity();
            }),

            notNull(this.questionnaire.get('already_in_netherlands')).valueChanges.subscribe(value => {
                if (!value) {
                    this.questionnaire.get('arrival_date')?.setValidators([Validators.required]);
                }else {
                    this.questionnaire.get('arrival_date')?.clearValidators();
                    this.questionnaire.get('arrival_date')?.setValue(null);
                }
                this.questionnaire.get('arrival_date')?.updateValueAndValidity();
            }),

            notNull(this.questionnaire.get('language')).valueChanges.subscribe(
                langArray => {
                    if (langArray.includes('other')) {
                        this.questionnaire
                            .get('language_other')
                            ?.setValidators(Validators.required);
                    } else {
                        this.questionnaire.get('language_other')?.clearValidators();
                        this.questionnaire.get('language_other')?.updateValueAndValidity();
                    }
                }
            ),

            notNull(this.questionnaire.get('disability_support')).valueChanges.pipe(
                distinctUntilChanged()
            ).subscribe(
                disabilitySupportArr => {

                    // If NONE is selected and there are other options selected, remove NONE from the array
                    if (disabilitySupportArr.includes(DisabilitySupport.NONE) && disabilitySupportArr.length > 1) {
                        const newDisabilitySupportArr = disabilitySupportArr.filter((item) => item !== DisabilitySupport.NONE);
                        this.questionnaire.get('disability_support')?.setValue(newDisabilitySupportArr, {emitEvent: false});
                    }

                    // If OTHER is selected, require explanation
                    if (disabilitySupportArr.includes(DisabilitySupport.OTHER)) {
                        this.questionnaire.get('disability_support_explanation_note')?.setValidators(Validators.required);
                        this.questionnaire.get('disability_support_explanation_note')?.updateValueAndValidity();
                    } else {
                        this.questionnaire.get('disability_support_explanation_note')?.clearValidators();
                        this.questionnaire.get('disability_support_explanation_note')?.updateValueAndValidity();
                    }
                }
            ),

            notNull(this.questionnaire.get('age_category')).valueChanges
                .subscribe(ageCategory => {
                    this.showIskRedirect = ageCategory === AgeCategory.VO;
                }),

            this.submit$
                .pipe(
                    exhaustMap(() => {
                        const formData = { ...this.questionnaire.value };

                        formData.form_language = this.selectedLanguage;
                        formData.disability_support = formData.disability_support.join(', ');

                        return this.http
                            .post(environment.nieuwkomer_api, formData, { responseType: 'text' })
                            .pipe(
                                catchError(error => {
                                    if (error.error) {
                                        try {
                                            const errorResponse = JSON.parse(error.error);
                                            if (errorResponse.message) {
                                                alert(errorResponse.message);
                                                return EMPTY;
                                            }
                                        } catch (e) {
                                            console.error(e);
                                        }
                                    }

                                    if (!error.status) {
                                        alert('Kon geen verbinding maken met de server. Probeer het later opnieuw.');
                                    }else {
                                        alert('Er is een fout opgetreden. De server gaf error ' + error.status + ' terug.');
                                    }

                                    return EMPTY;
                                })
                            );
                    })
                )
                .subscribe(() => {
                    this.router.navigate([
                        '/signup-newcomers',
                        'submitted',
                        this.selectedLanguage,
                    ], {replaceUrl: true});
                })
        );
    }

    setDisabilitySupportOptions(): void {
        // reset the options for translations
        this.disabilitySupportOptions = null;

        this.disabilitySupportOptions  = {
            [DisabilitySupport.NONE]: {label: this.translate.instant('NONE'), value: DisabilitySupport.NONE},
            [DisabilitySupport.PHYSICAL_LIMITATION]: {label: this.translate.instant('PHYSICAL_LIMITATION'), value: DisabilitySupport.PHYSICAL_LIMITATION},
            [DisabilitySupport.DEVELOPMENTAL_DELAY]: {label: this.translate.instant('DEVELOPMENTAL_DELAY'), value: DisabilitySupport.DEVELOPMENTAL_DELAY},
            [DisabilitySupport.BEHAVIORAL_PROBLEMS]: {label: this.translate.instant('BEHAVIORAL_PROBLEMS'), value: DisabilitySupport.BEHAVIORAL_PROBLEMS},
            [DisabilitySupport.CHRONIC_DISEASE]: {label: this.translate.instant('CHRONIC_DISEASE'), value: DisabilitySupport.CHRONIC_DISEASE},
            [DisabilitySupport.OTHER]: {label: this.translate.instant('OTHER'), value: DisabilitySupport.OTHER}
        };

        if (this.disabilitySupportOptions) {
            const options = this.disabilitySupportOptions as { [key in DisabilitySupport]: DisabilitySupportOption };
            this.disabilitySupportOptionsArray = Object.keys(options).map(key => options[key]);
        }
    }

    geoCodeAddress(zip: string, houseNumber: string): Observable<{street: string, city: string}> {
        return this.http.get<{street: string, city: string}>(environment.api + `/geocode/${zip}/${houseNumber}`);
    }

    addressAutoFiller(zipControl: AbstractControl | null,
                      houseNumberControl: AbstractControl | null,
                      streetControl: AbstractControl | null,
                      cityControl: AbstractControl | null): Subscription {
        if (zipControl === null || houseNumberControl === null || streetControl === null || cityControl === null) {
            throw new Error('Check form controls for addressAutoFiller some where unexpectedly null.');
        }

        return combineLatest([zipControl.valueChanges, houseNumberControl.valueChanges]).pipe(
            debounceTime(1000),
            filter(([zip, housenumber]) => zip !== null && housenumber !== null && zip !== '' && housenumber !== ''),
            filter(() => !('pattern' in (zipControl.errors ?? {}))),
            switchMap(([zip, housenumber]) => this.geoCodeAddress(zip, housenumber).pipe(
                catchError((errorResponse: HttpErrorResponse) => {
                    if (errorResponse.status === 400) {
                        zipControl.setErrors({ invalidAddress: 'zip, number combination does not match' });
                        houseNumberControl.setErrors({ invalidAddress: 'zip, number combination does not match' });
                    }

                    return EMPTY;
                }))
            )
        ).subscribe(response => {
            if (zipControl.errors) {
                zipControl.errors.invalidAddress = undefined;
                zipControl.updateValueAndValidity();
            }
            if (houseNumberControl.errors) {
                houseNumberControl.errors.invalidAddress = undefined;
                zipControl.updateValueAndValidity();
            }

            streetControl.setValue(response.street);
            cityControl.setValue(response.city);
        });
    }

    isFieldInvalid(fieldName: string): boolean {
        return (
            !notNull(this.questionnaire.get(fieldName)).valid &&
            notNull(this.questionnaire.get(fieldName)).touched
        );
    }

    isEmailInvalid(fieldName: string): boolean {
        const emailControl = this.questionnaire.get(fieldName);
        if (emailControl?.touched && emailControl?.errors?.email) {
            return emailControl.errors.email;
        }
        return false;
    }

    getValidatorMessages(fieldName: string): string {
        const control = this.questionnaire.get(fieldName);

        if (control === null || !control.touched || control.errors === undefined || control.errors === null) {
            return '';
        }

        const errorMessages: {[key: string]: () => string} = {
            required: () => this.translate.instant('REQUIRED_FIELD'),
            email: () => this.translate.instant('ENTER_A_VALID_EMAIL_ADDRESS'),
            invalidAddress: () => this.translate.instant('ENTER_A_VALID_ADDRESS'),
            // Currently only used for zip
            pattern: () => this.translate.instant('ENTER_A_VALID_ADDRESS'),
        };

        return Object.keys(control.errors)
            .filter(key => key in errorMessages)
            .map(key => errorMessages[key]())
            .join(', ');
    }

    onSubmit(): void {
        if (this.questionnaire.valid) {
            this.submit$.next();
        } else {
            this.questionnaire.markAsDirty();
            this.questionnaire.markAllAsTouched();
            this.questionnaire.updateValueAndValidity();
        }
    }

    switchLanguage(value: string): void {
        this.selectedLanguage = value;
        this.translate.use(value);
        this.router.navigate(['signup-newcomers', value], {replaceUrl: true});
    }

    onSelectSpokenLanguage(event: Event): void {
        const target = event.target as HTMLInputElement;
        if (target.checked) {
            this.selectedSpokenLanguages.add(target.value);
        } else {
            this.selectedSpokenLanguages.delete(target.value);
        }
        this.questionnaire.get('language')?.setValue(Array.from(this.selectedSpokenLanguages).join(', '));
        this.questionnaire.updateValueAndValidity();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}

import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements OnChanges {
  private _searchText = '';

  @Input('appHighlight')
  set searchText(value: string) {
    this._searchText = value;
    this.highlightText();
  }

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchText'] && this._searchText) {
      setTimeout(() => {
        this.highlightText();
      }, 10);
    }
  }

  private highlightText(): void {
    const regex = new RegExp(this.escapeRegex(this._searchText), 'gi');
    const element = this.el.nativeElement;
    const text = element.textContent;
    if (text) {
      const highlightedText = text.replace(regex, match => `<span class="highlight">${match}</span>`);
      element.innerHTML = highlightedText;
    }
  }

  private escapeRegex(text: string): string {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}

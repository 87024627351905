<main class="main-content tpl-launch">
    <section class="section section-launch">
        <div class="st-inner">
            <span class="before"></span>
            <span class="after"></span>
            <div class="container">
                <div class="content">

                    <h1 class="h2">Op 24 februari werd de nieuwe scholenwijzer geopend door Hilbert Bredemeijer en Ewald van Vliet. Kijk je mee naar dit moment?</h1>

                    <p>De Scholenwijzer, hét online platform voor opvang- en schoolkeuze in (regio) Den Haag is vernieuwd. Op 24 februari hebben wethouder Hilbert Bredemeijer (onderwijs, sport en buitenruimte) en portefeuillehouder BOVO Haaglanden/Scholenwijzer Ewald van Vliet (namens de gezamenlijke Haagse schoolbesturen) samen de nieuwe Scholenwijzer gelanceerd. Kijk je mee naar dit feestelijke moment?</p>

                    <div class="video-container">
                        <iframe src="https://player.vimeo.com/video/680953262?h=c7bb7b756b&color=ffffff" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    </div>

                </div>
            </div>
        </div>
    </section>
</main>




import {Component, Input} from '@angular/core';
import {BlockContent} from '../../article/article.component';

@Component({
  selector: 'app-media-right-text-left',
  templateUrl: './media-right-text-left.component.html',
  styleUrls: ['./media-right-text-left.component.scss']
})
export class MediaRightTextLeftComponent {
  @Input() content!: BlockContent;

  constructor() { }

}

import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FavouriteService} from '../../services/favourite.service';
import {pairwise} from 'rxjs/operators';

export interface FavouriteSchool {
  id: number;
  type: string;
}

@Component({
  selector: 'app-favorite-school',
  templateUrl: './favourite-school-button.component.html',
  styleUrls: ['./favourite-school-button.component.scss']
})
export class FavouriteSchoolButtonComponent implements OnInit {
  @ViewChild('heart', {static: true}) heart?: ElementRef;

  @Input() id: number;
  @Input() locationType: string;

  constructor(public favoriteService: FavouriteService) { }

  ngOnInit(): void {
    this.favoriteService.favoritesChanged$.pipe(pairwise()).subscribe(([last, current]) => {
      const inLast = last.find(fav => fav.id === this.id) !== undefined;
      const inCurrent = current.find(fav => fav.id === this.id) !== undefined;

      if (inCurrent && !inLast) {
        this.heart?.nativeElement.classList.add('do-pulse');
      } else {
        this.heart?.nativeElement.classList.remove('do-pulse');
      }
    });
  }
}

<main class="main-content tpl-not-found">
    <section class="section section-404">
        <div class="st-inner">
            <span class="before"></span>
            <span class="after"></span>
            <div class="container">
                <div class="content">
                    <h1 class="h1">Oeps… De scholenwijzer is de weg kwijt, maar leert ook nog iedere dag!</h1>
                    <p>Zoek de locatie die bij jou past door weer terug te gaan naar home.</p>
                    <a [routerLink]="['/']" class="button"><i class="arrow icon-right-small"></i> terug naar home</a>
                </div>
            </div>
        </div>
    </section>
</main>
export function encodeIdName(id: number, name: string): string {
    return `${id}-${encodeURIComponent(name.replace(/\s/g, '-').toLocaleLowerCase())}`;
}

export function decodeIdName(encoded: string): number | null {
    const found = encoded.match(/^(\d+).*$/);

    if (found) {
        return +(found[1]);
    }

    return null;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function notNull<TValue>(value: TValue | null | undefined): TValue {
    if (value === null || value === undefined) {
        throw new Error('Unexpected null');
    }
    return value;
}

export function updateLinks(): void {
    const links = document.links;

    for (let i = 0, linksLength = links.length; i < linksLength; i++) {
        if (links[i].hostname !== window.location.hostname && links[i].protocol !== 'tel:' && links[i].protocol !== 'mailto:') {
            links[i].target = '_blank';
        }
    }
}

export function stripHtml(html): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
}

// tslint:disable-next-line:typedef
export function getUrlParts(url) {
    const a = document.createElement('a');
    a.href = url;

    return {
        href: a.href,
        host: a.host,
        hostname: a.hostname,
        port: a.port,
        pathname: a.pathname,
        protocol: a.protocol,
        hash: a.hash,
        search: a.search
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy<T, K extends keyof any>(arr: T[], key: (i: T) => K): Record<K, T[]> {
  return arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);
}

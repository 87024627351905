import { Component, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { combineLatest, EMPTY, fromEvent, merge, Observable, of, Subject, Subscription, from } from 'rxjs';
import { delay, filter, shareReplay, switchMap, take, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Filter, LocationsResponse } from '../map/map.component';
import { CategoryService } from '../shared/services/category.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FavouriteSchoolListDirective } from './favourite-school-list.directive';
import { FavouriteSchoolsListComponent } from './favourite-schools-list/favourite-schools-list.component';
import { FavouriteSchool } from '../shared/components/favourite-school-button/favourite-school-button.component';
import { OpenDay, OpenDayRequest } from '../open-days/open-days.component';
import { FavouriteService } from '../shared/services/favourite.service';
import { MapService, LocationType } from '../shared/services/map.service';
import { RouterHelperService } from '../shared/services/routerHelper.service';

import {MatSnackBar} from '@angular/material/snack-bar';
import {Clipboard} from '@angular/cdk/clipboard';

export enum CategoryType {
    KO = 'ko',
    PO = 'po',
    VO = 'vo',
    MBO = 'mbo',
    HBOWO = 'hbo-wo',
}

type ReverseMap<T> = T[keyof T];
export type CategoryTypeValues = ReverseMap<typeof CategoryType>;
// export interface Location {
//   education_field?: string;
//   id: number;
//   name: string;
//   zipcode: string;
//   street: string;
//   house_number: number;
//   house_number_addition: string;
//   place: string;
//   city_area?: number;
//   latitude: number;
//   longitude: number;
//   image: string | null;
//   website: string;
//   private_school: boolean;
//   location_type: LocationType; // TODO: Use this to generate urls
//   pupil_count?: number;
//   domains?: number[];
// }

export interface LocationInfo extends Location {
    distance?: number; // Distance in meters
}

export interface Location {
    education_field?: string;
    id: number;
    name: string;
    zipcode: string;
    street: string;
    house_number: number;
    house_number_addition: string;
    place: string;
    city_area?: number;
    latitude: number;
    longitude: number;
    image: string | null;
    website: string;
    private_school: boolean;
    location_type: LocationType; // TODO: Use this to generate urls
    pupil_count?: number;
    domains?: number[];
}

interface SelectValue {
    id: CategoryType;
    title: string;
}

enum SortOption {
    NAME = 'name',
    DISTANCE = 'distance',
}
@Component({
    selector: 'app-my-schools',
    templateUrl: './favourite-schools.component.html',
    styleUrls: ['./favourite-schools.component.scss'],
})
export class FavouriteSchoolsComponent implements OnInit, OnDestroy {
    @ViewChild(FavouriteSchoolListDirective, { static: true }) schoolListHost!: FavouriteSchoolListDirective;

    favouriteSchools: FavouriteSchool[] = [];
    favouriteSchoolsIds: number[] = [];
    selectValues: SelectValue[] = [];
    categorySelect = new UntypedFormControl();
    zipCodeInput = new UntypedFormControl('', [Validators.pattern(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)]);
    postCodeSearchClick$ = new Subject();
    subscriptions: Subscription[] = [];
    hasFavoriteSchools = false;
    meta: Filter[] = [];
    map: google.maps.Map | null = null;
    currentHover: LocationInfo | null = null;
    activeMarker: google.maps.Marker | null = null;
    private infowindow: google.maps.InfoWindow;
    infoWindowLocation: Location;
    locations: LocationInfo[] = [];
    zipcodeLocation: number[] = [];

    CategoryType = CategoryType;
    activeCategory: CategoryTypeValues;
    activeSchoolListReference: ComponentRef<FavouriteSchoolsListComponent> | null = null;
    markersArray: google.maps.Marker[] = [];
    voOpenDays: OpenDay[] = [];
    hasOpenDays = false;
    categoryTypeFromUrl: string | null = null;
    idsFromUrl: number[] = [];
    shareSchools = false;
    sorting = SortOption.NAME;
    homeMarker: google.maps.Marker | null = null;
    zipcodeNotFound = false;

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private categoryService: CategoryService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private favouriteService: FavouriteService,
        private mapService: MapService,
        private routerHelper: RouterHelperService,
        private snackBar: MatSnackBar,
        private clipboard: Clipboard
    ) {}

    ngOnInit(): void {
        this.route.queryParamMap.subscribe(params => {
            const categoryParam = params.get('type');
            const idsParam = params.get('ids');

            this.categoryTypeFromUrl = categoryParam;
            this.idsFromUrl = idsParam?.split(',').map(id => parseInt(id)) || [];
        });

        if (this.route.snapshot.url[0].path === 'favoriete-scholen') {
            this.favouriteSchools = this.favouriteService.getFavorites();
        } else { // url: /gedeelde-scholen
            this.shareSchools = true;
            this.idsFromUrl.forEach(url => {
                this.favouriteSchools.push({id: url, type: this.categoryTypeFromUrl ? this.categoryTypeFromUrl : ''});
            });
        }

        const position$ = this.postCodeSearchClick$.pipe(
            switchMap(() => {
                if (!this.zipCodeInput.value || !this.zipCodeInput.valid) {
                    this.clearMarkers();
                    this.zipcodeNotFound = true;
                    this.locations.forEach((location) => this.addMarkerForLocation(location));
                    return EMPTY;
                }

                this.zipcodeNotFound = false;
                return from(this.mapService.findLatLang(this.zipCodeInput.value)).pipe(
                    catchError(e => {
                        this.zipcodeNotFound = true;
                        return EMPTY;
                    })
                );

            })
        );

        position$.subscribe((output) => {
            this.zipcodeLocation = output;

            this.drawHomeMarker();

            if (this.map) {
                this.map.setCenter(new google.maps.LatLng(output[0], output[1]));
                this.map.setZoom(14);
            }

        });

        // Subscribe to query params
        this.route.queryParamMap.subscribe((params) => {
            const categoryParam = params.get('type');
            const idsParam = params.get('ids');

            this.categoryTypeFromUrl = categoryParam;
            this.idsFromUrl = idsParam?.split(',').map((id) => parseInt(id)) || [];
        });

        this.selectValues = this.getSelectValues();

        if (this.route.snapshot.url[0].path === 'favoriete-scholen') {
            this.favouriteSchools = this.favouriteService.getFavorites();
        } else {
            // url: /gedeelde-scholen
            this.shareSchools = true;
            this.idsFromUrl.forEach((url) => {
                this.favouriteSchools.push({ id: url, type: this.categoryTypeFromUrl ? this.categoryTypeFromUrl : '' });
            });
        }

        if (this.favouriteSchools.length !== 0) {
            this.hasFavoriteSchools = true;
            const categoryTypes = this.favouriteSchools.map((l) => {
                if (l.type === LocationType.HBO || l.type === LocationType.WO) {
                    return CategoryType.HBOWO;
                } else if (l.type === LocationType.SO) {
                    return CategoryType.PO;
                } else {
                    return l.type.toLowerCase();
                }
            });
            this.selectValues = this.selectValues.filter((value) => categoryTypes.includes(value.id));
            this.categorySelect.setValue(this.selectValues[0].id);
        }

        const categorySelection$: Observable<CategoryTypeValues> = merge(
            this.categorySelect.valueChanges,
            of(this.categorySelect.value)
        );

        const categoryInfo$ = categorySelection$.pipe(
            switchMap((category) => {
                if (category !== null) {
                    return this.http.get<LocationsResponse>(environment.api + '/locations/' + category);
                }
                return EMPTY;
            })
        );

        const categoryInfoSub = categoryInfo$.subscribe((output) => {

            this.meta = output.meta;
            this.locations = output.locations.filter((value) => this.favouriteSchools.find((el) => el.id === value.id));




            this.favouriteSchoolsIds = this.locations.map((s) => s.id);
            this.activeCategory = this.categorySelect.value;

            this.activeSchoolListReference?.instance.ngOnDestroy();

            if (this.locations.length > 0 && this.schoolListHost) {
                this.schoolListHost.viewContainerRef.clear();

                this.activeSchoolListReference =
                    this.schoolListHost.viewContainerRef.createComponent<FavouriteSchoolsListComponent>(
                        this.componentFactoryResolver.resolveComponentFactory(FavouriteSchoolsListComponent)
                    );

                this.activeSchoolListReference.instance.locations = this.locations;
                this.activeSchoolListReference.instance.meta = this.meta;
                this.activeSchoolListReference.instance.category = this.categorySelect.value;
                this.activeSchoolListReference.instance.voOpenDays = this.voOpenDays;
            }

            this.clearMarkers();
            this.locations.forEach((location) => this.addMarkerForLocation(location));

            setTimeout(() => {
                // @ts-expect-error jerome stuff
                $('.js-mh-3').matchHeight();
                // @ts-expect-error jerome stuff
                $('.js-mh-2').matchHeight();
                // @ts-expect-error jerome stuff
                $('.js-mh').matchHeight();
            }, 0);
        });

        const voOpenDays$ = categorySelection$.pipe(
            filter((category) => category === 'vo'),
            take(1),
            switchMap(() => this.http.get<OpenDayRequest>(environment.api + '/vo/open-days')),
            shareReplay(1)
        );

        const voOpenDaysSub = voOpenDays$.subscribe((openDays) => {
            this.voOpenDays = openDays.open_days;

            if (this.activeSchoolListReference !== null) {
                this.activeSchoolListReference.instance.voOpenDays = openDays.open_days;
            }
        });

        const openDaysEnablerSub = combineLatest([voOpenDays$, categorySelection$, categoryInfo$])
            .pipe(
                delay(0) // want this to happen after the other categoryInfo$ subscriber
            )
            .subscribe(([openDays]) => {
                this.hasOpenDays = openDays.open_days.some((openDay) =>
                    this.favouriteSchoolsIds.includes(openDay.location.id)
                );
            });

        this.subscriptions.push(categoryInfoSub, voOpenDaysSub, openDaysEnablerSub);

        this.subscriptions.push(
            fromEvent(document, 'click').subscribe((event) => {
                // @ts-expect-error event.target should have classname
                if (event.target.className.match(/\bgo-to-link\b/)) {
                    event.preventDefault();
                    event.stopPropagation();

                    // @ts-expect-error event target should have href
                    this.routerHelper.open(event.target.href);
                }

                // @ts-expect-error event.target should have classname
                if (event.target.className === 'popup-close') {
                    this.infowindow.close();
                    if (this.activeMarker) {
                        this.activeMarker.setIcon(this.getMarkerIcon(false));
                    }
                    event.preventDefault();
                    event.stopPropagation();
                }
            })
        );

        const centerpoint = '52.070499, 4.300700';
        const center = centerpoint.split(',');

        const mapcolors = [
            { featureType: 'landscape', elementType: 'all', stylers: [{ visibility: 'on' }] },
            {
                featureType: 'landscape',
                elementType: 'geometry.fill',
                stylers: [{ color: '#f2f4f2' }],
            },
            { featureType: 'poi.attraction', elementType: 'all', stylers: [{ visibility: 'off' }] },
            {
                featureType: 'poi.attraction',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
            },
            { featureType: 'poi.business', elementType: 'all', stylers: [{ visibility: 'off' }] },
            {
                featureType: 'poi.government',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
            },
            { featureType: 'poi.medical', elementType: 'all', stylers: [{ visibility: 'off' }] },
            {
                featureType: 'poi.park',
                elementType: 'geometry.fill',
                stylers: [{ color: '#98e2a1' }],
            },
            {
                featureType: 'poi.place_of_worship',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi.place_of_worship',
                elementType: 'labels.icon',
                stylers: [{ color: '#7d2e80' }, { visibility: 'on' }],
            },
            { featureType: 'poi.school', elementType: 'all', stylers: [{ visibility: 'off' }] },
            {
                featureType: 'poi.school',
                elementType: 'labels.icon',
                stylers: [{ color: '#f28700' }, { visibility: 'off' }],
            },
            {
                featureType: 'poi.sports_complex',
                elementType: 'all',
                stylers: [{ visibility: 'on' }],
            },
            {
                featureType: 'poi.sports_complex',
                elementType: 'geometry.fill',
                stylers: [{ color: '#98e2a1' }],
            },
            { featureType: 'poi.sports_complex', elementType: 'labels', stylers: [{ visibility: 'off' }] },
            {
                featureType: 'transit',
                elementType: 'all',
                stylers: [{ visibility: 'on' }],
            },
            { featureType: 'transit', elementType: 'labels.icon', stylers: [{ color: '#69a8de' }] },
            {
                featureType: 'water',
                elementType: 'all',
                stylers: [{ color: '#69a8de' }],
            },
        ];

        const mapElement = document.getElementById('favorite-gmap');
        if (mapElement !== null) {
            this.map = new google.maps.Map(mapElement, {
                zoom: 12,
                // @ts-expect-error center[0] and center[1] should work
                center: new google.maps.LatLng(center[0], center[1]),
                mapTypeId: 'terrain',
                scrollwheel: false,
                mapTypeControl: false,
                panControl: false,
                fullscreenControl: false,
            });
        }


        if (this.map) {
            this.map.setOptions({ styles: mapcolors });
        }

        google.maps.event.addDomListener(window, 'resize', () => {
            if (this.map) {
                google.maps.event.trigger(this.map, 'resize');
                const center = this.map.getCenter();
                if (center) {
                    this.map.setCenter(center);
                }
            }
        });

        this.infowindow = new google.maps.InfoWindow();
    }

    getMarkerIcon(active = true) {
        return {
            url: 'assets/img/map/' + (active ? 'marker-active.png' : 'marker.png'), // url
            scaledSize: new google.maps.Size(29, 43), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(15, 43), // anchor
        };
    }

    addMarkerForLocation(l: Location): void {
        const icon = this.getMarkerIcon(l.id === this.currentHover?.id);

        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(l.latitude, l.longitude),
            map: this.map,
            icon,
            zIndex: l.id === this.currentHover?.id ? 1 : 0,
        });

        // Push the marker to the markers array
        this.markersArray.push(marker);

        google.maps.event.addListener(marker, 'click', () => {
            if (this.activeMarker) {
                this.activeMarker.setIcon(icon);
            }
            this.activeMarker = marker;
            marker.setIcon(this.getMarkerIcon(true));

            this.infowindow.close();
            this.infoWindowLocation = l;

            const subtitle = '';
            this.infowindow.setContent(this.mapService.getInfoDialogHtml(l, subtitle));
            if (this.map) {
                this.infowindow.open(this.map, marker);
            }
        });

    }

    drawHomeMarker(): void {
        if (!this.zipcodeLocation) {
            return;
        }

        // reset map
        if (this.homeMarker) {
            this.homeMarker.setMap(null);
            this.homeMarker = null;
        }

        this.homeMarker = new google.maps.Marker({
            position: new google.maps.LatLng(this.zipcodeLocation[0], this.zipcodeLocation[1]),
            map: this.map,
            icon: {
                url: 'assets/img/map/house.png',
                scaledSize: new google.maps.Size(45, 45),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(15, 43)
            },
            zIndex: 100
        });
    }

    clearMarkers(): void {
        if (this.markersArray) {
            for (let i = 0; i < this.markersArray.length; i++) {
                // Check if the map property is defined before setting it to null
                if (this.map) {
                    this.markersArray[i].setMap(null);
                }
            }
            // Clear the array holding markers
            this.markersArray = [];
        }
    }

    getSelectValues(): SelectValue[] {
        return Object.values(CategoryType).map((c) => {
            return {
                id: c,
                title: this.categoryService.getCategoryName(c),
            };
        });
    }

    selectCategory(id, $event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();

        this.categorySelect.setValue(id);
    }

    goBack(event): void {
        event.preventDefault();
        event.stopPropagation();

        const { navigationId } = window.history.state;
        if (navigationId > 1) {
            window.history.back();
        } else {
            this.router.navigateByUrl('/');
        }
    }

    getUrl(): string {
        return (
            environment.api +
            '/my-schools/download/' +
            this.categorySelect.value +
            '/' +
            this.favouriteSchoolsIds.join(',') +
            '/opgeslagen-locaties.pdf'
        );
    }

    shareUrl(): string {
        const ids = this.locations.map(location => location.id);
        const params = new URLSearchParams();
        ids.forEach(element => {
            params.append('ids', element.toString());
        });
        return location.origin + '/gedeelde-scholen?type=' + this.categorySelect.value + '&ids=' + ids;
    }

    showSnackbar(content: string, action: string): void {
        this.snackBar.open(content, action, {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            panelClass: ['custom-style']
        });
    }

    scrollToMap(): void {
        document.querySelector('.map')?.scrollIntoView({behavior: 'smooth'});
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    share(): void {
        if (navigator.share) {
            navigator.share({
                title: 'Deel scholen',
                url: this.shareUrl()
            });
        } else {
            this.clipboard.copy(this.shareUrl());
            this.showSnackbar('Naar klembord gekopieerd', 'Sluiten');
        }

    }
}

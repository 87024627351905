<div class="container">
  <div class="row card" *ngFor="let location of locations">
    <div class="col-md-3">
      <div class="image">
        <a  [routerLink]="['/', 'locatie', location.location_type.toLowerCase(), location.id]"
            class="bgimg"
            data-bgimg="{{location.image}}"
            [ngStyle]="{backgroundImage: 'url(' + (location.image ?? 'assets/img/map/tijdelijke-image-kaart.svg') + ')'}"
            *ngIf="category !== 'ko'"
          >
          <img [src]="location.image"/>
        </a>
        <div class="name">
          <h2 class="title" title="{{location.name}}">{{location.name}}</h2>
          <div class="meta">
            <div class="heart">
              <app-favorite-school [id]="location.id" [locationType]="location.location_type"></app-favorite-school>
            </div>
            <ng-container *ngIf="location.education_field">
              <div class="meta-text-wrapper">
                <span
                  class="meta-text">{{locationService.getDisplayValue(location.education_field, locationService.getOptionsForField(meta, 'education_field'))}}</span>
                <span class="divider"> | </span>
                <span class="meta-text">{{locationService.getDescriptionForType(location.location_type)}}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-lg-7">
      <div class="info">
        <div class="item" *ngFor="let propertyInfo of meta">
          <div class="wrapper">
            <span><strong>{{ propertyInfo.name }} </strong></span>
            <span>{{locationService.getDisplayValue(location[propertyInfo.field], propertyInfo.options) || ' '}}</span>
          </div>
        </div>
        <div class="item" *ngIf="category === 'po'">
          <div class="wrapper">
            <span><strong>Aantal leerlingen </strong></span>
            <span>{{location.pupil_count}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="events" *ngIf="hasOpenDays(location)" >
        <h3 *ngIf="hasOpenDays(location)">Open dagen</h3>
        <div class="item" [class.mobile]="responsiveState === 'mobile'" *ngFor="let openDay of getLocationOpenDays(location)">
          <a *ngIf="openDay !== null" [routerLink]="['/', 'locatie', openDay.location.location_type.toLowerCase(), openDay.location.id]">
            <div class="top">
              <span class="open-day-name"><strong>{{openDayTypeMapping[openDay.open_day_type].name}}</strong></span>
              <i class="icon-nav-arrow" [ngClass]="openDayTypeMapping[openDay.open_day_type].color"></i>
            </div>
            <div class="bottom">
              <span>
                {{openDay.start | date:'dd MMMM'}}
              </span>
              <span>
                <span>{{openDay.start | date: 'HH'}}<sup>{{openDay.start | date: 'mm'}}</sup></span>
                <span> - </span>
                <span>{{openDay.end | date: 'HH'}}<sup>{{openDay.end | date: 'mm'}}</sup></span>
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="section section-article-a text-only" [class.no-bff]="content.style===false">
    <div class="st-inner py-0">
        <div class="container">
            <div class="row">

                <div class="col-sm-12 col-md-8 col-right">
                    <div class="inner js-mh">
                        <div class="article-header">
                            <h1 class="h2" id="section-title-{{content.id}}">{{content.title}}</h1>
                        </div>
                        <div class="article-output">
                            <div [innerHTML]="content.text_content"></div>
                        </div>
                        <div class="c-anchor-menu" *ngIf="index === 0">
                            <ul>
                                <li *ngFor="let item of getMenuItems()"><a href="#{{item.link}}" (click)="scrollTo(item.link, $event)"><i class="icon-right-small"></i> <span class="text">{{item.name}}</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<main class="main-content theme-ko">
  <section class="section section-category-header">
    <div class="st-inner">

        <lottie-player
                src="/assets/img/svg/category/ko/2.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>

      <div class="container">

        <div class="st-content">

            <div class="scroll-container" (window:scroll)="onScroll($event)">
                <ng-lottie [options]="lottieOptions" width="100%" height="100%"></ng-lottie>
            </div>

            <h1 class="page-title h2 split-title">
            <span class="line-1 f1 mb-0">Ik zoek een </span>
            <span class="line-2 h1 t-lower">kinderopvang</span>
          </h1>
          <div class="school-filter type-a clearfix">
            <div class="c-search clearfix">
              <input type="text" class="input" name="postcode" [(ngModel)]="zipcode" placeholder="Zoek op postcode">
              <a [routerLink]="'/categorie/ko/map'" [queryParams]="zipcode ? {'zipcode': zipcode} : {}">zoeken</a>
            </div>
            <a [routerLink]="['/', 'categorie', 'ko', 'map']" class="match-c-search">
              <span>Zoeken zonder postcode <i class="icon-right-small"></i></span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-category-article">
    <div class="st-inner">

        <lottie-player
                src="/assets/img/svg/category/ko/4.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>

      <div class="container">

        <div class="col-right">
          <figure class="st-image bgimg js-mh" style="background-image: url('/assets/img/photos/KO/KO_01.jpg');">
            <span class="overlay"></span>
            <img src="/assets/img/photos/KO/KO_01.jpg" alt="Alternatieve beschrijving" />
          </figure>
        </div>

        <div class="col-left indent js-mh">
          <h2 class="h2">Verschillende soorten kinderopvang</h2>
          <div class="article-output">
            <p>Opvang is er voor kinderen van 0 tot 13 jaar. Er zijn verschillende soorten kinderopvang zoals dagopvang, peuteropvang, buitenschoolse opvang en tussenschoolse opvang. Bijna alle kinderopvangorganisaties hebben een website waarop het pedagogisch beleid en informatie over de tarieven, groepen en opvangmogelijkheden is terug te vinden.</p>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-category-text">
    <div class="st-inner">
      <div class="container">
        <div class="row">

          <div class="col-md-4 col-lg-5"></div>

          <div class="col-md-7 col-lg-6 c-article-list">

            <article class="article-item">
              <h2 class="article-title h2">Dagopvang (0 - 4 jaar)</h2>
              <div class="article-output">
                <p>
                  Dagopvang is er voor kinderen die nog niet naar de basisschool gaan. De kinderen kunnen het hele jaar door 1 of meer dagdelen per week worden opgevangen. Uw kind kan terecht bij een kinderdagverblijf of een gastouder. Sommige kinderdagverblijven bieden ook voorschoolse educatie. Dit betekent dat kinderen van 2 tot 4 jaar extra begeleiding krijgen om zich voor te bereiden op de basisschool.
                </p>
                <div class="small-bottom-margin"><a [routerLink]="['/', 'categorie', 'Kinderdagopvang (0-4 jaar)']">Meer over de kinderdagopvang</a></div>
                <div><a [routerLink]="['/', 'artikel', 'voorschoolse-educatie']">Meer over voorschoolse educatie</a></div>
              </div>
            </article>

          </div>

        </div>
      </div>
    </div>
  </section>


  <section class="section section-category-article-b">
    <div class="st-inner">
      <div class="container">

        <div class="col-left indent c-article-listm js-mh">

          <article class="article-item">
            <h2 class="article-title h2">Peuteropvang (2,5 - 4 jaar)</h2>
            <div class="article-output">
              <p>
                Is uw kind tussen de 2,5 en 4 jaar oud? Dan mag het naar de peuteropvang. Op de peuteropvang kunnen kinderen al vroeg beginnen met leren en ontdekken. Soms hebben kinderen extra aandacht nodig. Bijvoorbeeld met taal of in hun ontwikkeling. Dan kunnen zij naar een peuteropvang met voorschoolse educatie.
              </p>
              <div class="small-bottom-margin"><a [routerLink]="['/', 'categorie', 'Peuteropvang (2,5-4 jr)']">Meer over de peuteropvang</a></div>
              <div><a [routerLink]="['/', 'artikel', 'voorschoolse-educatie']">Meer over voorschoolse educatie</a></div>
            </div>
          </article>

        </div>

        <div class="col-right js-mh">
          <figure class="st-image bgimg has-after" data-bgimg="/assets/img/photos/KO/KO_02.jpg">
            <img src="/assets/img/photos/KO/KO_02.jpg" alt="Alternatieve beschrijving" />
          </figure>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-category-text">
    <div class="st-inner">
      <div class="container">
        <div class="row">

          <div class="col-md-4 col-lg-5"></div>

          <div class="col-md-7 col-lg-6 c-article-list">

            <article class="article-item">
              <h2 class="article-title h2">Buitenschoolse opvang (4 - 13 jaar)</h2>
              <div class="article-output">
                <p>
                  Buitenschoolse opvang is er voor kinderen die naar de basisschool gaan. De kinderen kunnen voor en/of na schooltijd, tijdens vrije (mid)dagen en schoolvakanties worden opgevangen. Scholen kunnen de opvang zelf organiseren of samenwerken met een kinderopvangorganisatie.
                </p>
                <a [routerLink]="['/', 'categorie', 'Buitenschoolse opvang (4-13 jr)']">Meer over de buitenschoolse opvang</a>
              </div>
            </article>

          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="section section-category-articles">

    <div class="st-inner">
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Alles over </span>
            <span class="line-2 h1">kinderopvang in Den Haag</span>
          </h2>
        </div>

        <app-sub-category-list subCategorySlug="KO"></app-sub-category-list>
      </div>
    </div>
  </section>


  <section class="section section-category-faq" *ngIf="faqs && faqs.length">
    <div class="st-inner">
      <span class="before"></span>
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Veelgestelde vragen over </span>
            <span class="line-2 h1">kinderopvang</span>
          </h2>
        </div>

        <div class="col-left indent">
          <div class="c-faq-links">
            <a href="{{faq.link}}" class="question" *ngFor="let faq of faqs">{{faq.question}} <span class="arrow"><i class="icon-right-small"></i></span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>
</main>


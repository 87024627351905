<section class="section section-article-a" [class.no-bff]="content.style===false">
  <div class="st-inner py-0">
    <div class="container">
      <div class="row">

        <div class="col-sm-12 col-md-6 col-left">
          <div class="inner js-mh">
            <figure class="st-image bgimg has-before" [style.background-image]="'url(' + content.media_content + ')'">
              <img [src]="content.media_content" alt="Alternatieve tekst van de foto">
            </figure>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-right">
          <div class="inner js-mh">
            <div class="breadcrumbs breadcrumbs-inline" *ngIf="index === 0">
              <a [routerLink]="['/']">Home</a>
              <ng-container  *ngIf="article.category.parent_category.listed">
                <span class="spacer"><i class="icon-right-small"></i></span>
                <a [routerLink]="['/', 'categorie', article.category.parent_category.name.toLowerCase()]">
                  {{article.category.parent_category.name}}
                </a>
              </ng-container>
              <ng-container  *ngIf="article.category.slug === 'faq-po'">
                <span class="spacer"><i class="icon-right-small"></i></span>
                <a [routerLink]="['/', 'categorie', 'po']">
                  PO
                </a>
              </ng-container>
              <ng-container  *ngIf="article.category.article_count > 1 && article.category.listed">
                <span class="spacer"><i class="icon-right-small"></i></span>
                <a [routerLink]="['/', 'categorie', article.category.slug ? article.category.slug : article.category.name]">
                  {{article.category.name}}
                </a>
              </ng-container>
              <span class="spacer"><i class="icon-right-small"></i></span>
              <span>{{article.title}}</span>
            </div>

            <div class="article-header">
              <h1 class="h2" id="section-title-{{content.id}}">{{content.title}}</h1>
            </div>
            <div class="article-output">
              <div [innerHTML]="content.text_content"></div>
            </div>
            <div class="c-anchor-menu" *ngIf="index === 0">
              <ul>
                <li *ngFor="let item of menuItems"><a href="{{url}}#{{item.link}}" (click)="scrollTo(item.link, $event)"><i class="icon-right-small"></i> <span class="text">{{item.name}}</span></a></li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<main class="main-content">
    <article class="st-inner">
        <div class="container">
            <header>
                <h1>Actuele lijst nieuwkomers</h1>
                <div class="article-output">
                    <p>Als u op zoek bent naar een plek binnen het nieuwkomersonderwijs, kunt u het <a routerLink="/signup-newcomers/en">kind aanmelden</a> en uw gegevens achterlaten. Er wordt zo spoedig mogelijk met de opgegeven contactpersonen (ouders/verzorgers) contact opgenomen voor een plek binnen het nieuwkomersonderwijs.</p>
                </div>
            </header>
        </div>
        <span class="after"></span>
    </article>
</main>

import {Component, Input} from '@angular/core';
import {BlockContent} from '../../article/article.component';

@Component({
  selector: 'app-text-right',
  templateUrl: './text-right.component.html',
  styleUrls: ['./text-right.component.scss']
})
export class TextRightComponent {
  @Input() content!: BlockContent;

  constructor() { }

}

<section class="section section-article-d" [class.no-bff]="content.style===false">
  <div class="st-inner">
    <span class="before"></span>

    <div class="container">
      <div class="row">

        <div class="col-sm-12 col-md-5 col-left">
          <div class="inner">
            <figure class="st-image bgimg has-before" [style.background-image]="'url(' + content.media_content + ')'">
              <img [src]="content.media_content" alt="Alternatieve tekst van de foto">
            </figure>
          </div>
        </div>

        <div class="col-sm-12 col-md-7 col-right">
          <div class="inner indent">
            <div class="article-header">
              <h2 class="h2" id="section-title-{{content.id}}">{{content.title}}</h2>
            </div>
            <div class="article-output">
              <div [innerHTML]="content.text_content"></div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</section>

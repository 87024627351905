import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appSchoolListHost]',
})
export class FavouriteSchoolListDirective {

    constructor(public viewContainerRef: ViewContainerRef) {

    }
}

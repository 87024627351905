<main class="main-content">

    <section class="section section-finder clearfix">

        <div class="map-toggles" *ngIf="mobile">
            <a class="show-map button" (click)="showMap = !showMap" *ngIf="!showMap">Toon kaart</a>
            <a class="show-list button" (click)="showMap = !showMap" *ngIf="showMap">Toon resultaten</a>
        </div>

        <div class="col-right js-mh" [class.hide-col]="!showMap && mobile">
            <div class="finder-map-wrapper">
                <a href="#" class="toggle-map"><i class="icon-left-small"></i></a>
                <div class="finder-gmap" id="gmap"></div>
            </div>
        </div>

        <div class="col-left clearfix js-mh">

            <div class="box-left filter-layer">

                <div class="filter-layer-actions">
                    <a href="#" class="filter-layer-toggle toggle-open button"><i class="icon-sliders"></i> Toon filters</a>
                    <a href="#" class="filter-layer-toggle toggle-close"><i class="icon-cancel"></i></a>
                </div>

                <div class="finder-filters">
                    <a href="#" class="button reset-filters" (click)="resetFilters($event)">Reset alle filters</a>

                    <div class="filter-box" *ngFor="let filter of filters">
                        <div class="filter-title" [class.open]="filter.active">
                            <a href="#" (click)="toggleOpenFilter(filter, $event)" class="filter-toggle" [class.active]="filter.active">{{filter.info.name}} <span class="number-selected" *ngIf="activeFilters(filter.options).length">{{activeFilters(filter.options).length}}</span></a>
                          <span *ngIf="filter.info.explanation" class="infotip" [title]="filter.info.explanation"><i class="icon-info-circled-alt"></i></span>
                        </div>
                        <div class="filter-options open" *ngIf="filter.active">
                            <label *ngFor="let o of filter.options" [class.checked]="o.active" [class.grey]="getAvailableLocations(filter.info, o) === 0" (click)="toggleFilter(o, $event)">
                                <input type="checkbox" /> {{o.name}}&nbsp;({{getAvailableLocations(filter.info, o)}})
                            </label>
                        </div>
                    </div>

                    <div class="filter-box">
                        <div class="filter-title" (click)="distanceFilterOpen = !distanceFilterOpen; $event.preventDefault(); updateFilterAndLocationHeights();">
                            <a href="#" class="filter-toggle" [class.active]="distanceFilterOpen === true">Afstand {{displayDistance()}}</a>
                        </div>
                        <div class="filter-options" style="padding: 0px" [class.open]="true">
                            <form>
                                <input class="postcode-input" type="text" placeholder="postcode" [formControl]="form.controls['zipcode']">
                                <div class="helptext" *ngIf="form.controls['distance'].value !== 10 && !form.controls['zipcode'].value">Vul een postcode in om te zoeken op afstand</div>
                                <nouislider [config]="sliderConfig" [tooltips]="sliderTooltips" (slide)="distanceSlideEvent$.next()" [formControl]="form.controls['distance']">
                                </nouislider>
                                <div class="noUi-customlabel">in kilometers</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box-right" [class.hide-col]="showMap && mobile">

                <div class="breadcrumbs">
                    <a [routerLink]="['/']">home</a>
                    <span class="spacer"><i class="icon-right-small"></i></span>
                    <a [routerLink]="['/', 'categorie', categoryType]">{{getDescriptionForType(categoryType)}}</a>
                    <span class="spacer"><i class="icon-right-small"></i></span>
                    <span>kaart</span>
                </div>

                <div class="sorting-container clearfix" *ngIf="zipcodeLocation.length > 0">
                    <div class="select-box">
                        <select name="sorting" id="sorting" class="frm-select" [(ngModel)]="sorting" (change)="onSortChange()">
                            <option value="{{SortOption.NAME}}">Sorteer op naam</option>
                            <option value="{{SortOption.DISTANCE}}">Sorteer op afstand</option>
                        </select>
                    </div>
                </div>

                <div class="finder-results category-{{categoryType}}">
                    <div class="result" *ngFor="let loc of visibleLocations; trackBy: trackById" (mouseenter)="hoverEnterLocation(loc)" (mouseleave)="hoverLeaveLocation(loc)">
                        <a [href]="getLocationLink(loc)" appPossiblyExternal *ngIf="getLocationLink(loc)">
                            <img [src]="loc.image ? loc.image : '/assets/img/map/tijdelijke-image-kaart.svg'" loading="lazy" class="result-image" />
                        </a>
                        <img [src]="loc.image ? loc.image : '/assets/img/map/tijdelijke-image-kaart.svg'" loading="lazy" class="result-image" *ngIf="!getLocationLink(loc)" />
                        <div class="result-info">
                            <h2 class="h4 title" *ngIf="getLocationLink(loc)"><a [href]="getLocationLink(loc)" appPossiblyExternal>{{loc.name}}</a></h2>
                            <h2 class="h4 title" *ngIf="!getLocationLink(loc)">{{loc.name}}</h2>
                            <div class="box">
                                <app-favorite-school [id]="loc.id" [locationType]="loc.location_type"></app-favorite-school>
                                <div class="info">
                                    <p>{{loc.street}} {{loc.house_number}}{{loc.house_number_addition}}<br />
                                        {{loc.zipcode}} {{loc.place}}
                                    </p>
                                </div>
                                <div class="actions" *ngIf="getLocationLink(loc)">
                                    <a [href]="getLocationLink(loc)" appPossiblyExternal class="btn-small">Bekijk deze locatie <i class="icon-right-small"></i></a>
                                </div>

                                <div class="distance" *ngIf="zipcodeLocation.length > 0">
                                    <i class="icon-direction"></i> {{getDistanceDisplay(loc)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </section>

    <div class="tooltip_templates">
        <div id="tip_filter_stadsdeel">
            <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam quis convallis libero. Donec sed fermentum enim. Nulla ut malesuada elit, at accumsan nunc. </p>
        </div>
        <div id="tip_filter_schooltype">
            <p>Fusce at ex nisl. Sed dapibus nisl a ante mollis molestie. Maecenas eget convallis ante. Vivamus in ex vitae neque bibendum vulputate.</p>
        </div>
        <div id="tip_filter_methode">
            <p>Ut nulla risus, viverra non dui sed, tincidunt sagittis odio. Curabitur mattis tortor vitae turpis feugiat semper. Nulla at ante egestas, interdum leo vel, pellentesque turpis.</p>
        </div>
        <div id="tip_filter_eigenschappen">
            <p>Suspendisse ut dictum metus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec massa ipsum, tempus id massa in, molestie rhoncus nulla.</p>
        </div>
    </div>

</main>

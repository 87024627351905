<main class="main-content theme-hbo">

  <section class="section section-category-header">
    <div class="st-inner">
        <lottie-player
                src="/assets/img/svg/category/hbo/2.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>
      <div class="container">

        <div class="st-content">

            <div class="scroll-container" (window:scroll)="onScroll($event)">
                <ng-lottie [options]="lottieOptions" width="100%" height="100%"></ng-lottie>
            </div>

          <h1 class="page-title h2 split-title">
            <span class="line-1 f1 mb-0">Ik zoek </span>
            <span class="line-2 h1 t-lower">een hogeschool of een universiteit</span>
          </h1>
          <div class="school-filter type-c">
            <a href="/categorie/hbo-wo/map" class="button" title="Ga naar alle scholen">Alle instellingen <i class="icon-right-small"></i></a>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section section-category-article">
    <div class="st-inner">
        <lottie-player
                src="/assets/img/svg/category/hbo/4.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>
      <div class="container">

        <div class="col-right">
          <figure class="st-image bgimg" style="background-image: url('/assets/img/photos/hbo-wo.jpg');">
            <span class="overlay"></span>
            <img src="/assets/img/photos/hbo-wo.jpg" alt="Alternatieve beschrijving" />
          </figure>
        </div>

        <div class="col-left indent">
          <h2 class="h2">Studeren in Den Haag</h2>
          <div class="article-output">
            <p>In Den Haag zijn er verschillende hogescholen te vinden. Er zijn hogescholen met een breed opleidingsaanbod en er zijn hogescholen met opleidingen in een specifieke richting. In Den Haag kun je ook studeren aan de universiteit. De Technische Universiteit Delft, de Universiteit Leiden en de Erasmus Universiteit Rotterdam hebben een vestiging in Den Haag. In de Scholenwijzer vind je meer informatie over deze hogescholen en universiteiten. Ook vind je informatie over het maken van een passende studiekeuze, open dagen en hoe je je kunt aanmelden bij de hogeschool of universiteit van jouw keuze.</p>
          </div>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-category-articles">
    <div class="st-inner">
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Alles over </span>
            <span class="line-2 h1 t-lower">Hoger beroepsonderwijs of universiteit</span>
          </h2>
        </div>

        <app-sub-category-list subCategorySlug="HBO-WO"></app-sub-category-list>
      </div>
    </div>
  </section>


  <section class="section section-category-faq" *ngIf="faqs && faqs.length">
    <div class="st-inner">
      <span class="before"></span>
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Veelgestelde vragen over </span>
            <span class="line-2 h1 t-lower">Hoger beroepsonderwijs of universiteit</span>
          </h2>
        </div>

        <div class="col-left indent">
          <div class="c-faq-links">
            <a href="{{faq.link}}" class="question" *ngFor="let faq of faqs">{{faq.question}} <span class="arrow"><i class="icon-right-small"></i></span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>

</main>

<div class="slick-articles" *ngIf="subCategories">
  <div class="item article c-article-box" *ngFor="let category of subCategories">
    <div class="inner">
      <div class="article-image" [routerLink]="['/', 'categorie',(category.slug ?? category.name)]" >
        <figure class="bgimg" [style.background-image]="'url(' + category.image + ')'">
          <img [src]="category.image" alt="artikel afbeelding"/>
        </figure>
        <i class="arrow icon-right-small"></i>
      </div>
      <h2 class="h4 title">
        <a [routerLink]="['/', 'categorie', (category.slug ?? category.name)]" class="goto inside">
          {{category.name}}<i class="icon-right-small"></i>
        </a>
      </h2>
    </div>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {Meta} from "@angular/platform-browser";

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

    constructor(private meta: Meta) {
    }

    ngOnInit(): void {
        this.meta.addTag({name: 'prerender-status-code', content: '404'});
    }

}

import { Injectable } from '@angular/core';
import { fromEvent, Observable} from 'rxjs';
import {map, share, startWith} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  responseChange$: Observable<'mobile' | 'desktop'>;

  constructor() {
    const query = `(max-width: 992px)`; // value 992 is from jquery script

    this.responseChange$ = fromEvent(window.matchMedia(query), 'change')
      .pipe(
        startWith(window.matchMedia(query)),
        map(event => ((event as MediaQueryListEvent).matches ? 'mobile' : 'desktop') as 'mobile' | 'desktop'),
        share()
      );
  }
}

import {Injectable} from '@angular/core';
import {Filter, Option} from '../../map/map.component';
import {notNull} from '../Utils';
import {LocationType} from '../../shared/services/map.service';

interface LinkableLocation {
    id: number;
    location_type: LocationType; // Returned in lowercase
    private_school: boolean;
    website: string;
}

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    constructor() {
    }

    getDescriptionForType(type: LocationType): string {
        switch (type) {
            case LocationType.KO:
                return 'Kinderopvang';
            case LocationType.PO:
                return 'Basisonderwijs';
            case LocationType.VO:
                return 'Voortgezet Onderwijs';
            case LocationType.MBO:
                return 'Middelbaar beroepsonderwijs';
            case LocationType.HBO:
                return 'Hoger Beroepsonderwijs';
            case LocationType.WO:
                return 'Universiteit';
            case LocationType.SO:
                return 'Special onderwijs';
        }
    }

    getOptionsForField(meta: Filter[], field: string): Option[] {
        return notNull(meta.find(m => m.field === field)).options;
    }

    getDisplayValue(locationElement, options: Option[]): string {
        if (Array.isArray(locationElement)) {
            return locationElement.map(value => {
                return this.getDisplayValue(value, options);
            }).filter(o => o !== '').join(', ');
        }
        const option = options.find(o => o.id === locationElement);
        if (option) {
            return option.name;
        } else {
            return '';
        }
    }

    getLocationLink(l: LinkableLocation): string | null {
        if (l.location_type.toUpperCase() === LocationType.KO) {
            return null;
        }

        if (l.private_school) {
            if (!l.website) {
                return null;
            }

            const website = l.website;
            if (website.startsWith('www.')) {
                return 'https://' + website;
            }else {
                return website;
            }
        }

        return `/locatie/${l.location_type.toLowerCase()}/${l.id}`;
    }
}
